import { features_schema } from "../../../data/Locale_Data";

export const Features = ({ features }: { features: string[] }) => {
  return (
    <div className="features section">
      <div className="header">
        <h2>SERVIZI</h2>
      </div>
      <div className="body">
        {features.map((f) => {
          const matchedFeature = features_schema.find(
            ({ value }) => value === f
          );
          if (matchedFeature)
            return (
              <img
                src={matchedFeature.img}
                alt={matchedFeature.value}
                key={matchedFeature.key}
              />
            );
          return <></>;
        })}
      </div>
    </div>
  );
};
