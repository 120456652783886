import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useField, useFormikContext } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { BuyTicketState } from "../../interfaces";
import { primaryColor, secondaryColor } from "../colors";

export const CustomSelect = styled(TextField)`
  .MuiInputLabel-root.Mui-focused,
  .MuiSelect-select {
    color: ${secondaryColor};
  }
  .MuiInput-root:after {
    border-bottom: 2px solid ${secondaryColor};
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #ababab;
  }
  .MuiInput-root:before {
    border-bottom: 2px solid #8b8b8b;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .MuiInputLabel-root,
  .MuiSelect-icon {
    color: white !important;
    font-size: 18px;
  }
  &.type .MuiInputLabel-root {
    top: -8px;
    left: 6px;

    &.MuiFormLabel-filled {
      display: none;
    }
  }

  &.country_code {
    border: none !important;
    .MuiInput-root:before,
    .MuiInput-root:after {
      border: none !important;
    }
    .MuiSelect-select {
      padding-right: 0 !important;
      padding-bottom: 4px !important;
    }
  }

  .MuiInputBase-input {
    padding-left: 6px;
    font-size: 18px;
  }
  .MuiPaper-root {
    background-color: ${primaryColor};
  }

  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    background-color: transparent;
  }
`;

export const CustomSelectField = ({
  options,
  setTicketState,
  ...otherProps
}: {
  options: any[];
  setTicketState: Dispatch<SetStateAction<BuyTicketState>>;
  [key: string]: any;
}) => {
  const handleChange = (evt: any) => {
    const { value } = evt.target;
    setTicketState((prev) => ({ ...prev, ticketQuantity: parseInt(value) }));
  };

  const configSelect = {
    // ...field,
    ...otherProps,
    select: true,
    fullWidth: true,
    onChange: handleChange,
    error: !otherProps.value ? true : false,
    helperText: !otherProps.value
      ? "Seleziona almeno un biglietto"
      : "Puoi acquistare un massimo di 4 biglietti alla volta.",
    value: otherProps.value,
  };

  return (
    <CustomSelect
      {...configSelect}
      variant="standard"
      className={`custom-select ${otherProps.name}`}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: primaryColor,
              color: secondaryColor,
            },
          },
        },
      }}
    >
      {options.map(({ key, value, label }) => (
        <MenuItem key={key} value={value}>
          {label}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};
