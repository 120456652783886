import { MouseEventHandler } from "react";
import { buttonColor } from "./colors";

export const Chevron = ({
  id,
  onClick,
}: {
  id: "left" | "right";
  onClick: MouseEventHandler<SVGSVGElement>;
}) => {
  return (
    <svg
      onClick={onClick}
      cursor="pointer"
      height="60px"
      id={id}
      fill={buttonColor}
      version="1.1"
      viewBox="0 0 46.001 85.999"
      width="40px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...(id === "right" && { style: { transform: "rotate(180deg)" } })}
    >
      <path
        id={id}
        d="M44.998,80.094c1.338,1.352,1.338,3.541,0,4.893c-1.336,1.35-3.506,1.352-4.844,0L1.003,45.447  c-1.338-1.352-1.338-3.543,0-4.895l39.15-39.539c1.338-1.352,3.506-1.352,4.844,0S46.335,4.555,45,5.906L9.294,43L44.998,80.094z"
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};
