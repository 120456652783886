import { Switch } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Attendee } from "../../interfaces";
import { Tos } from "../../pages/DynamicContents/components/buy-tickets/Stage3";

export const CustomSwitch = ({
  setAttendeesState,
  ticketQuantity,
  generateAttendees,
}: {
  setAttendeesState: Dispatch<
    SetStateAction<{
      attendees: Attendee[];
      tos: Tos;
    }>
  >;
  generateAttendees: (quantity: number, firstAttendee: Attendee) => Attendee[];
  ticketQuantity: number;
}) => {
  const [switchValue, setSwitchValue] = useState<boolean>(true);

  const handleChange = useCallback(() => {
    setSwitchValue((prev) => {
      if (!prev) {
        setAttendeesState((prev) => ({
          ...prev,
          attendees: generateAttendees(0, prev.attendees[0]),
        }));
      } else {
        setAttendeesState((prev) => ({
          ...prev,
          attendees: generateAttendees(ticketQuantity - 1, prev.attendees[0]),
        }));
      }
      return !prev;
    });
  }, [generateAttendees, setAttendeesState, ticketQuantity]);

  return <Switch onChange={handleChange} checked={switchValue} />;
};
