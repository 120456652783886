import styled from "styled-components";
import { primaryColor } from "../../components/colors";
import location from "../../assets/images/guide/Location.png";

const AspettiStyle = styled.div.attrs({ className: "aspetti-block" })`
  background: ${primaryColor};
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 1rem;
  h2 {
    font-family: Lato-Light;
  }

  .inner-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    .aspetti-body {
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
      align-items: center;

      .image {
        width: 70px;
        height: 70px;
      }

      .text {
        font-family: Lato-Light;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding-inline: 3rem;
    min-height: 300px;
    .inner-block {
      height: 100%;

      .aspetti-body {
        .image {
          width: 140px;
          height: 140px;
        }

        .text {
          font-size: 25px;
        }
      }
    }
  }
`;
export const Aspetti = () => {
  return (
    <AspettiStyle>
      <div className="inner-block">
        <div className="aspetti-header">
          <h2>Non rimanere indietro…</h2>
          <h2>Porta il tuo locale al prossimo livello!</h2>
        </div>
        <div className="aspetti-body">
          <div
            className="image"
            style={{
              background: `url(${location}) no-repeat center center / contain`,
            }}
          ></div>
          <div className="text">
            +500 Locali hanno già scelto <br />
            di unirsi a noi! Tu cosa aspetti?
          </div>
        </div>
      </div>
    </AspettiStyle>
  );
};
