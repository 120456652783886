import opening from "../assets/images/mockup/Opening_m.png";
import laptop from "../assets/images/mockup/Laptop.png";
import locali from "../assets/images/mockup/Locali_m.png";
import event_pad from "../assets/images/mockup/Ipad_m.png";
import eventi from "../assets/images/mockup/Eventi_m.png";
import ticket from "../assets/images/mockup/Ticket_m.png";
import ipad from "../assets/images/mockup/Stampa.png";
import { primaryColor, secondaryColor } from "../components/colors";
import { BlockContentProps } from "../interfaces";

export const BLOCKS_CONTENT: BlockContentProps[] = [
  {
    image: { src: opening, size: "left center /contain" },
    title: ["Scopri i migliori", "locali ed eventi"],
    description: [
      "Guarda cosa c’è in",
      "programma nella tua città:",
      "scarica l'app per ottenere la tua",
      "selezione personalizzata.",
    ],
    button: "Scarica App",
    gradient:
      "linear-gradient(150deg, rgba(0,0,0,1) 0%, rgba(101,1,14,1) 100%);",
    type: "scopri",
    buttonLink: "https://onelink.to/nightcitizen",
  },
  {
    image: { src: laptop, size: "right top / 60% 90%" },
    title: ["Promuovi il tuo locale", "ed i tuoi eventi"],
    description: [
      "La miglior piattaforma all-in one",
      "per la gestione e la promozione",
      "dei tuoi eventi e del tuo locale.",
      "Vendi biglietti in modo semplice,",
      "veloce ed efficace!",
    ],
    button: "Iscrivi Locale",
    gradient:
      "linear-gradient(34deg, rgba(0,0,0,1) 0%, rgba(101,1,14,1) 100%);",
    type: "promuovi",
    revert: true,
    buttonLink: "https://nightcitizenmanager.com",
  },
  {
    image: { src: locali, size: "right center /contain" },
    title: ["I migliori locali", "a portata di mano"],
    description: [
      "Tantissimi locali che aspettano",
      "Solo di essere scoperti!",
    ],
    button: "",
    gradient: primaryColor,
    type: "locali",
    revert: true,
  },
  {
    image: { src: eventi, size: "left center /contain" },
    title: ["Eventi imperdibili"],
    description: [
      "Non perdere più i tuoi eventi",
      "preferiti! Su Night Citizen potrai",
      "trovare la serata che cercavi",
    ],
    button: "",
    gradient: secondaryColor,
    type: "eventi",
  },
  {
    image: { src: ticket, size: "right center /contain" },
    title: ["Acquista biglietti", "direttamente in-app"],
    description: [
      "Acquista comodamente i",
      "biglietti dei tuoi eventi preferiti.",
      "Evita inutili e noiose code alla",
      "cassa del locale!",
    ],
    button: "",
    gradient: primaryColor,
    type: "ticket",
    revert: true,
  },
  {
    image: { src: event_pad, size: "left center / contain" },
    title: ["Promuovi i tuoi eventi"],
    description: [
      "La nostra community non aspetta",
      "altro che te! Con Night Citizen",
      "potrai far conoscere a tutti la tua",
      "programmazione... cosa aspetti?",
    ],
    button: "",
    gradient: primaryColor,
    type: "community",
  },
  {
    image: { src: ipad, size: "right top / 100% 100%" },
    title: ["Ancora non ci conosci?"],
    description: [
      "In questi anni, abbiamo già",
      "ricevuto prestigiosi",
      "riconoscimenti, tanti giornali e",
      "riviste hanno parlato di noi ed",
      "abbiamo stipulato accordi con",
      "importantissimi player di",
      "settore!",
    ],
    button: "",
    gradient:
      "linear-gradient(308deg, rgba(0,0,0,1) 0%, rgba(101,1,14,1) 100%);",
    type: "stampa",
    revert: true,
  },
  {
    image: { src: ticket, size: "center / contain" },
    title: ["Vendi biglietti", "direttamente in-app"],
    description: [
      "Vendi comodamente i biglietti",
      "dei tuoi eventi.",
      "Evita inutili e noiose code alla",
      "cassa del locale!",
    ],
    button: "",
    gradient: primaryColor,
    type: "ticket",
    revert: true,
  },
];
