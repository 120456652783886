import { primaryColor } from "../../../components/colors";
import mobile_code from "../../../assets/images/mockup/Ticket_m.png";
import banner_phone from "../../../assets/images/mockup/AppMobile.png";
import userPic from "../../../assets/images/guide/user.png";
import checkPic from "../../../assets/images/guide/check.png";
import costPic from "../../../assets/images/guide/costi.png";
import locationPic from "../../../assets/images/guide/Location.png";
import laptop from "../../../assets/images/mockup/Laptop.png";

export interface BlockProps {
  className: string;
  title: string;
  reverse?: boolean;
  content: { key: string; className: string; body: string }[];
  image?: string;
}

export interface BannerProps {
  background: string;
  title: string[];
  description: string[];
  image: string;
  button?: string;
  largeImage?: boolean;
}

interface PageContent {
  banner: BannerProps;
  body: BlockProps[];
}

export const VenderePageContent: PageContent = {
  // VENDERE BANNER
  banner: {
    image: mobile_code,
    title: ["Vendi biglietti", "direttamente in-app"],
    description: [
      "Vendi comodamente i biglietti",
      "dei tuoi eventi.",
      "Evita inutili e noiose code alla",
      "cassa del locale!",
    ],
    background: primaryColor,
  },

  // VENDERE PAGE CONTENT
  body: [
    {
      className: "accedi-block",
      title: "Accedi o Iscriviti",
      image: userPic,
      content: [
        {
          key: "accedi1",
          className: "",
          body: "Accedi alla tua area personale, se sei nuovo, iscriviti, carica le informazioni bancarie!",
        },
        {
          key: "accedi2",
          className: "p-bold",
          body: "Completa la sezione pagamenti del tuo Account, invieremo i soldi al tuo conto entro 7 giorni dal tuo evento!",
        },
      ],
    },
    {
      className: "attiva-block",
      title: "Attiva la vendita",
      image: checkPic,
      reverse: true,
      content: [
        {
          key: "attiva1",
          className: "",
          body: "Completa il profilo del tuo locale e crea un evento nell’apposita sezione del tuo Account!",
        },
        {
          key: "attiva2",
          className: "p-bold",
          body: "In fase di creazione del tuo evento, o anche in un secondo momento, ti basterà attivare la funzione “Vendi con Night Citizen“ ed inizia subito a vendere i tuoi biglietti online!",
        },
      ],
    },
    {
      className: "costi-block",
      title: "Costi",
      image: costPic,
      content: [
        {
          key: "costi1",
          className: "",
          body: "Vendere i tuoi biglietti con Night Citizen non solo è facile, veloce ed economico!",
        },
        {
          key: "costi2",
          className: "p-bold p-list start",
          body: "0 Commissioni sui biglietti “Free Entry“",
        },
        {
          key: "costi3",
          className: "p-bold p-list",
          body: "1€ per biglietti fino a 10€",
        },
        {
          key: "costi4",
          className: "p-bold p-list end",
          body: "10% sui biglietti a partire da 10€",
        },
        {
          key: "costi5",
          className: "p-bold",
          body: "N.B. I biglietti non validati, verranno considerati no-show e riceverai il 50% del prezzo del biglietto. Per maggiori informazioni su come validare i biglietti, segui la guida “Validare Biglietti“.",
        },
      ],
    },
  ],
};

export const ValidarePageContent: PageContent = {
  // VALIDARE BANNER
  banner: {
    image: banner_phone,
    title: ["Valida i biglietti", "Con la nostra comoda app"],
    description: [
      "Scarica ed accedi con le tue",
      "credenziali e comincia subito a",
      "validare i biglietti venduti!",
    ],
    background: primaryColor,
    largeImage: false,
  },

  // VALIDARE PAGE CONTENT
  body: [
    {
      className: "accedi-block",
      title: "Accedi",
      image: userPic,
      content: [
        {
          key: "accedi1",
          className: "",
          body: "Scarica Night Citizen App, vai sul tuo profilo ed accedi in Area Manager con la stessa email e password con cui ti sei registrato.",
        },
      ],
    },
    {
      className: "valida-block",
      title: "Valida Biglietto",
      image: checkPic,
      reverse: true,
      content: [
        {
          key: "valida1",
          className: "",
          body: "Una volta effettuato l’accesso, ti basterà inquadrare i QR-Code ticket con il tuo smartphone e riceverai immediatamente un messaggio di conferma.",
        },
        {
          key: "valida2",
          className: "p-bold",
          body: "N.B. I Biglietti non validati verranno conteggiati come no-show e riceverai il 50% del prezzo del biglietto!",
        },
      ],
    },
  ],
};

export const IscriverePageContent: PageContent = {
  // ISCRIVERE BANNER
  banner: {
    image: laptop,
    title: ["Promuovi il tuo locale", "ed i tuoi eventi"],
    description: [
      "La miglior piattaforma all-in one per",
      "la gestione e la promozione dei tuoi",
      "eventi e del tuo locale.",
      "Vendi biglietti in modo semplice,",
      "veloce ed efficace!",
    ],
    background:
      "linear-gradient(34deg, rgba(0,0,0,1) 0%, rgba(101,1,14,1) 100%);",
    button: "Iscrivi Locale",
    largeImage: true,
  },

  // ISCRIVERE PAGE CONTENT
  body: [
    {
      className: "iscriviti-block",
      title: "Iscriviti",
      image: userPic,
      content: [
        {
          key: "iscriviti1",
          className: "",
          body: "Carica le tue info! Ti chiederemo solo ciò che è davvero necessario!",
        },
        {
          key: "iscriviti2",
          className: "p-bold p-list start",
          body: "Dati Personali",
        },
        {
          key: "iscriviti3",
          className: "no-margin",
          body: "Ti chiederemo solo i tuoi riferimenti, così da poterti contattare in caso di emergenza!",
        },
        {
          key: "iscriviti4",
          className: "p-bold p-list start",
          body: "Dati Bancari (Facoltativi)",
        },
        {
          key: "iscriviti5",
          className: "no-margin",
          body: "Le tue informazioni bancarie saranno necessarie solo per attivare il servizio di biglietteria digitale! Non accederemo ai tuoi conti, ma ti chiederemo di fornirci le indicazione bancarie per poterti inviare i soldi, in caso di vendita di biglietti.",
        },
      ],
    },
    {
      className: "info-block revert",
      title: "Info Locale",
      image: locationPic,
      reverse: true,
      content: [
        {
          key: "info1",
          className: "p-list p-bold",
          body: "Info Utili",
        },
        {
          key: "info2",
          className: "no-margin",
          body: "Al fine di promuovere al meglio il tuo locale, carica le info ad esso relative. Per ogni locale che creerai, potrai inoltre aggiungere un Logo, una Cover e fino a 6 immagini di galleria.",
        },
        {
          key: "info3",
          className: "p-list p-bold start",
          body: "Eventi (Facoltativi)",
        },
        {
          key: "info4",
          className: "no-margin",
          body: "Organizzi eventi? Con Night Citizen puoi mostrare a tutti i membri della nostra community la tua programmazione e promuoverli! È inoltre possibile attivare la funzione “Biglietteria Digitale“ e vendere i biglietti online!",
        },
      ],
    },
    {
      className: "costi-block",
      title: "Costi",
      image: costPic,
      content: [
        {
          key: "costi1",
          className: "no-b-margin",
          body: "Night Citizen è una piattaforma completamente Gratuita!",
        },
        {
          key: "costi2",
          className: "bold-no-margin",
          body: "Iscriviti e inizia subito a promuovere il tuo locale ed i tuoi eventi.",
        },
        {
          key: "costi3",
          className: "no-b-margin",
          body: "Il servizio di “Biglietteria Digitale“ è un servizio a pagamento.",
        },
        {
          key: "costi4",
          className: "no-t-margin",
          body: "Vendere i tuoi biglietti con Night Citizen non solo è facile e veloce, ma anche economico!",
        },
        {
          key: "costi5",
          className: "p-bold p-list",
          body: "0 Commissioni sui biglietti “Free Entry“",
        },
        {
          key: "costi6",
          className: "p-bold p-list",
          body: "1€ per biglietti fino a 10€",
        },
        {
          key: "costi7",
          className: "p-bold p-list end",
          body: "10% sui biglietti a partire da 10€",
        },
      ],
    },
  ],
};
