import { ReactNode } from "react";
import styled from "styled-components";

const GuidaPageStyle = styled.section`
  display: grid;
  grid-template-columns: 1fr;

  > div:not(.hero) {
    padding: 2rem 1rem;
  }

  @media only screen and (min-width: 768px) {
    > div:not(.hero) {
      padding: 4rem 3rem;
    }
  }
`;

export const GuidaPageContainer = ({ children }: { children: ReactNode }) => {
  return <GuidaPageStyle>{children}</GuidaPageStyle>;
};
