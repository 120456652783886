import { GuidaPageBanner } from "./components/GuidaBanner";
import { GuidaPageBody } from "./components/GuidaBody";
import { GuidaPageContainer } from "./components/GuidaContainer";
import { GuidaPageHero } from "./components/GuidaHero";
import { GuidaHints } from "./components/GuidaHints";
import { IscriverePageContent } from "./data";

export const IscrivereGuidaPage = () => {
  return (
    <GuidaPageContainer>
      <GuidaPageHero title="ISCRIVERE LOCALE" />
      <GuidaPageBanner {...IscriverePageContent.banner} />
      <GuidaPageBody content={IscriverePageContent.body} />
      <GuidaHints />
    </GuidaPageContainer>
  );
};
