import styled from "styled-components";
import privacyImg from "../../../assets/images/privacy.webp";
import { secondaryColor } from "../../../components/colors";

const HeroStyle = styled.div`
  height: 200px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${secondaryColor};
    position: relative;
    z-index: 3;
    text-align: center;
    font-size: 25px;
    font-weight: lighter;
    letter-spacing: 0.5rem;
    text-shadow: 3px 4px 10px rgba(200, 200, 200, 0.37);
  }

  img {
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  @media only screen and (min-width: 768px) {
    height: 400px;

    h1 {
      font-size: 75px;
    }
  }
`;

export const AssistenzaHero = ({ title }: { title: string }) => {
  return (
    <HeroStyle className="hero">
      <h1>{title}</h1>
      <img src={privacyImg} alt={title} />
    </HeroStyle>
  );
};
