import { EventProps, VenueProps } from "../../../interfaces";
import { dayParser, parseDaysInline, parseTime } from "../../../utils";

export const Times = ({
  pageType,
  content,
}: {
  pageType: "Event" | "Venue";
  content: VenueProps | EventProps;
}) => {
  if (pageType === "Venue") {
    const {
      opening_days,
      opening_at,
      closing_at,
      opening_date,
      closing_date,
      open_all_season,
    } = content as VenueProps;

    const [opening_year, opening_month, opening_day] =
      opening_date?.split("-") || [];
    const [closing_year, closing_month, closing_day] =
      closing_date?.split("-") || [];

    return (
      <div className="times section">
        <div className="header">
          <h2>GIORNI E ORARI</h2>
        </div>
        <div className="body">
          {!open_all_season && opening_date && closing_date && (
            <p>
              Stagione:{" "}
              <span>
                {opening_day || ""}
                {"/"}
                {opening_month || ""}
                {"/"}
                {opening_year || ""}
                &nbsp;&nbsp;{"-"}&nbsp;&nbsp;
                {closing_day || ""}
                {"/"}
                {closing_month || ""}
                {"/"}
                {closing_year || ""}
              </span>
            </p>
          )}
          <p>
            Giorni: <span>{parseDaysInline(opening_days)}</span>
          </p>
          <div className="opening-line">
            <p>
              Apertura: <span>{parseTime(opening_at)}</span>
            </p>
            <p>&nbsp;&nbsp;{"-"}&nbsp;&nbsp;</p>
            <p>
              Chiusura: <span>{parseTime(closing_at)}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  const { date, start_time, end_time } = content as EventProps;

  const [year, month, day] = date.split("-");

  const dayN = new Date(date)?.getDay() || 0;

  return (
    <div className="times section">
      <div className="header">
        <h2>DATA E ORARIO</h2>
      </div>
      <div className="body">
        <p>
          Quando:{" "}
          <span>
            {dayParser(dayN)} {day}
            {"/"}
            {month}
            {"/"}
            {year}
          </span>
        </p>
        <p>
          Inizio Evento: <span>{parseTime(start_time)}</span>
        </p>
        <p>
          Fine Evento: <span>{parseTime(end_time)}</span>
        </p>
      </div>
    </div>
  );
};
