import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../CheckoutForm";
import { stripePromise, stripeStyle } from "./BuyTickets";

export const Stage4 = ({ clientSecret }: { clientSecret: string }) => {
  return (
    <div className="buy-ticket-form">
      {clientSecret && (
        <Elements
          options={{ clientSecret, appearance: stripeStyle }}
          stripe={stripePromise}
        >
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};
