import { Dispatch, SetStateAction } from "react";
import { BuyTicketState, EventProps } from "../../../../interfaces";
import { Stage1 } from "./Stage1";
import { Stage2 } from "./Stage2";
import { Stage3 } from "./Stage3";
import { Stage4 } from "./Stage4";

const RenderStages = ({
  ticketState,
  event,
  setTicketState,
  clientSecret,
}: {
  ticketState: BuyTicketState;
  event: EventProps;
  setTicketState: Dispatch<SetStateAction<BuyTicketState>>;
  clientSecret: string;
}) => {
  const { stage, ticketQuantity, ticketToBuy } = ticketState;
  switch (stage) {
    case 1:
      return <Stage1 event={event} setTicketState={setTicketState} />;
    case 2:
      return (
        <Stage2
          setTicketState={setTicketState}
          ticketQuantity={ticketQuantity}
          ticketToBuy={ticketToBuy}
        />
      );
    case 3:
      return (
        <Stage3 ticketState={ticketState} setTicketState={setTicketState} />
      );
    case 4:
      return <Stage4 clientSecret={clientSecret} />;
    default:
      return <></>;
  }
};

export const TicketFormBody = ({
  event,
  ticketState,
  setTicketState,
  clientSecret,
}: {
  event: EventProps;
  ticketState: BuyTicketState;
  setTicketState: Dispatch<SetStateAction<BuyTicketState>>;
  clientSecret: string;
}) => {
  return (
    <div className="ticket-info">
      {!event?.ticket_types.filter((t) => !t.physical).length ? (
        <h2 className="no-tickets">Nessun Biglietto Disponibile</h2>
      ) : (
        <RenderStages
          event={event}
          ticketState={ticketState}
          setTicketState={setTicketState}
          clientSecret={clientSecret}
        />
      )}
    </div>
  );
};
