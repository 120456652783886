import styled from "styled-components";
import { primaryColor, secondaryColor } from "../../components/colors";
import dock from "../../assets/images/premi/Dock3.png";
import rsprod from "../../assets/images/premi/RSProductions.png";
import urbantech from "../../assets/images/premi/UrbanTech.png";
import fboost from "../../assets/images/premi/Foundersboost.png";
import tempo from "../../assets/images/giornali/Tempo.png";
import startup from "../../assets/images/giornali/Startup.png";
import fortune from "../../assets/images/giornali/Fortune.png";
import lazio from "../../assets/images/giornali/Lazio.png";
import newage from "../../assets/images/partners/NewAge.png";
import suoniIt from "../../assets/images/partners/SuoniItaliani.png";
import sreef from "../../assets/images/partners/Reef.png";
import vigna from "../../assets/images/partners/Vigna.png";
import pier from "../../assets/images/partners/2Pier.png";
import { BlockComponent } from "../Home/BlockComponent";
import { BlockImagesProps, SectionBlockImages } from "./SectionBlockImages";
import { BLOCKS_CONTENT } from "../../data/Blocks_Data";
import { SeoWrapper } from "../../components/SeoWrapper";

const StampaPageContainer = styled.section.attrs({
  className: "stampa-page-container",
})`
  display: grid;
  grid-template-columns: 1fr;

  .stampa-block {
    .block-text {
      text-align: start;
      align-items: flex-start;
    }
  }

  @media only screen and (min-width: 768px) {
    .stampa-block {
      min-height: 60vh;
      .block-image {
        background-position: right center !important;
        width: 100%;
        margin-left: auto;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .stampa-block {
      .inner-block {
        .block-image {
          max-width: 550px;
        }
      }
    }
    h2:not(.card-text h2) {
      font-size: 50px;
    }

    .card-text h2 {
      font-size: 35px;
    }
    .card-text p {
      font-size: 22px;
    }

    h3 {
      font-size: 29px;
    }
  }
`;

const Premi_Content: BlockImagesProps = {
  label: "Premi",
  images: [
    {
      key: "rs-productions",
      image: rsprod,
    },
    {
      key: "dock",
      image: dock,
    },
    {
      key: "urban-tech",
      image: urbantech,
    },
    {
      key: "founders-boost",
      image: fboost,
    },
  ],
  color: primaryColor,
  type: "premi",
};

const Giornali_Content: BlockImagesProps = {
  label: "Parlano di noi",
  images: [
    {
      key: "iltempo",
      image: tempo,
    },
    {
      key: "startup",
      image: startup,
    },
    {
      key: "fortune",
      image: fortune,
    },
    {
      key: "lazio",
      image: lazio,
    },
  ],
  color: secondaryColor,
  type: "giornali",
};

const Partners_Content: BlockImagesProps = {
  label: "Partners",
  images: [
    {
      key: "new-age",
      image: newage,
    },
    {
      key: "suoni-it",
      image: suoniIt,
    },
    {
      key: "s-reef",
      image: sreef,
    },
    {
      key: "vignapr",
      image: vigna,
    },
    {
      key: "2-pier",
      image: pier,
    },
  ],
  color: primaryColor,
  type: "partners",
};
export const StampaPage = () => {
  return (
    <StampaPageContainer>
      <SeoWrapper page="stampa" />
      <BlockComponent {...BLOCKS_CONTENT[6]} />
      <SectionBlockImages {...Premi_Content} />
      <SectionBlockImages {...Giornali_Content} />
      <SectionBlockImages {...Partners_Content} />
    </StampaPageContainer>
  );
};
