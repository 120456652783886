import { ReactNode } from "react";
import styled from "styled-components";
import { buttonColor, buttonHoverColor, secondaryColor } from "./colors";

const ButtonUI = styled.button`
  background-color: ${buttonColor};
  border-radius: 20px;
  padding: 0.3rem 2rem;
  width: fit-content;
  border: 0;
  cursor: pointer;
  box-shadow: 2px 4px 9px -2px rgb(0 0 0);
  transition: ease-out 0.3s;
  position: relative;
  z-index: 1;

  &:disabled {
    opacity: 0.2;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  &:not(.input-button):before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color: ${buttonHoverColor};
  }
  &:not(.input-button):hover:before {
    border-radius: 20px;
    transition: 0.3s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
  }

  span {
    color: ${secondaryColor};
    font-family: Lato-Thin;
    font-size: 18px;
    font-weight: bold;
  }
`;

interface ButtonProps {
  label: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: any;
  className?: string;
  children?: ReactNode;
  otherProps?: { [key: string]: any };
  id?: string;
}

export const Button = ({
  label,
  disabled = false,
  type = "button",
  onClick,
  className,
  children,
  id,
  ...otherProps
}: ButtonProps) => {
  const ButtonConfig: ButtonProps = {
    type,
    disabled,
    label,
    className,
    ...otherProps,
    children,
    id,
  };

  if (!!onClick) ButtonConfig.onClick = onClick;
  return (
    <ButtonUI {...ButtonConfig}>
      <span id={id === "submit" ? "button-text" : ""}>{label || children}</span>
    </ButtonUI>
  );
};
