import styled from "styled-components";
import contactBG from "../../assets/images/contact-backg.png";
import { buttonHoverColor, secondaryColor } from "../../components/colors";
import { ContactForm } from "./ContactForm";
import { Socials } from "../../components/Socials";

const ContactsContainer = styled.section.attrs({
  className: "contacts-container",
})`
  // display: grid;
  // grid-template-rows: none;
  position: relative;
  padding: 3rem 1.5rem;

  .inner-block {
    display: grid;
    grid-template-rows: none;
    position: relative;
  }

  @media only screen and (min-width: 768px) {
    padding: 5rem 3rem;
  }

  @media only screen and (min-width: 1024px) {
    .inner-block {
      grid-template-rows: 2fr 9fr;
    }
  }
`;

const ContactImageWrapper = styled.div.attrs({
  className: "contacts-img",
})`
  background: url(${contactBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const ContactHeader = styled.div.attrs({
  className: "contacts-header",
})`
  position: relative;
  z-index: 1;
  h2 {
    color: ${buttonHoverColor};
    font-family: Lato-Regular;
    font-size: 30px;
    letter-spacing: 0.25rem;
    text-shadow: 2px 3px 4px rgba(34, 34, 34, 0.7);
  }
  @media only screen and (max-width: 767px) {
    h2 {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
`;

const ContactBody = styled.div.attrs({
  className: "contacts-body",
})`
  display: grid;
  grid-template-columns: 2fr 3fr;
  position: relative;
  z-index: 1;
  .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      width: fit-content;
    }
  }

  a {
    color: ${secondaryColor};
  }
  h3 {
    margin: 0;
    font-size: 30px;
    font-family: Lato-Light;
    color: ${secondaryColor};
  }
  p {
    color: ${secondaryColor};
    font-size: 20px;
    font-family: Lato-Light;
  }
  .socials-list {
    a {
      display: contents;
    }
    margin: 0;
    padding: 0;
    margin-block: 0.5rem;
    img {
      width: 32px;
      height: 32px;
      filter: invert(85%);
      &:hover {
        filter: invert(100%);
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 6fr;
    gap: 3rem;
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    gap: 3rem;
    .contact-details {
      gap: 2rem;
      h3 {
        font-size: 20px;
        font-family: Lato-Light;
        font-weight: lighter;
      }
      p {
        margin-block: 0.5rem;
        font-size: 18px;
      }
    }
  }
`;

const ContactFormWrapper = styled.div.attrs({
  className: "contacts-form-wrapper",
})`
  display: flex;
  justify-content: center;
  form {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
    .form-name-fields {
      display: grid;
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    .contact-button-container {
      display: flex;
      justify-content: center;
    }
    button {
      width: 40%;
    }
    @media only screen and (max-width: 1023px) {
      width: 100%;
      justify-content: space-evenly;
    }
  }

  @media only screen and (min-width: 630px) {
    form {
      .form-name-fields {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    form {
      gap: 3rem;
      justify-content: unset;

      button {
        width: 70%;
        padding: 0.25rem;
        margin-top: 1rem;
      }
    }
  }
`;
export const Contacts = () => {
  return (
    <ContactsContainer>
      <ContactImageWrapper />
      <div className="inner-block">
        <ContactHeader>
          <h2>CONTATTACI</h2>
        </ContactHeader>
        <ContactBody>
          <div className="contact-details">
            <h3>Let's Work Together!</h3>
            <div>
              <p>Via della Mendola 18</p>
              <p>00135 - Roma, Italia</p>
              <p>+39 392 272 4785</p>
              <a href="mailto:info@nightcitizenapp.com">
                <p>info@nightcitizenapp.com</p>
              </a>
            </div>
            <Socials />
          </div>
          <ContactFormWrapper>
            <ContactForm />
          </ContactFormWrapper>
        </ContactBody>
      </div>
    </ContactsContainer>
  );
};
