import styled from "styled-components";
import {
  buttonColor,
  buttonHoverColor,
  primaryColor,
  secondaryColor,
} from "../colors";
import { NavList } from "./NavList";
import close from "../../assets/images/socials/Close-White.png";

const MobileNavbarStyle = styled.div.attrs({
  className: "mobile-navbar-container",
})`
  position: fixed;
  top: -200vh;
  left: 0;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.74, 0.05) all;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: ${primaryColor};

  .navbar-header {
    padding: 2rem;
    .close {
      transition: 0.2s cubic-bezier(0.6, -0.28, 0.74, 0.05) all;
      background: url(${close}) no-repeat center / contain;
      margin-left: auto;
      margin-top: auto;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
  .navbar-body {
    padding: 2rem;
    ul {
      flex-direction: column;
      margin: 0;
      padding: 0;

      li {
        position: relative;

        a {
          padding-bottom: 1rem;
          color: ${secondaryColor};
          font-size: 30px;
          border-bottom: 1px solid ${buttonColor};
          display: block;
          transition: 0.3s;

          &:after {
            content: "";
            position: absolute;
            background-color: ${buttonHoverColor};
            height: 1px;
            width: 0;
            left: 0;
            bottom: 0px;
            transition: 0.3s;
          }

          &:hover {
            color: ${buttonHoverColor};
            transform: translateX(5px);
          }

          &:hover:after {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const MobileNavbar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: any;
}) => {
  const handleClose = (e: any) => {
    setTimeout(() => setIsMenuOpen((prev: boolean) => !prev), 300);
  };

  return (
    <MobileNavbarStyle
      style={{
        top: isMenuOpen ? "0" : "-200vh",
      }}
      defaultChecked={isMenuOpen}
    >
      <div className="navbar-header">
        <div
          className="close"
          style={{ transform: isMenuOpen ? "scale(1)" : "scale(0)" }}
          onClick={handleClose}
        ></div>
      </div>
      <div className="navbar-body">
        <NavList handleClose={handleClose} />
      </div>
    </MobileNavbarStyle>
  );
};
