import styled from "styled-components";
import { Button } from "../../components/Button";
import { BlockContentProps } from "../../interfaces";

const BlockStyle = styled.div`
  background: ${(props) => props.datatype};
  display: grid;
  padding: 2rem 1rem;
  grid-template-columns: 1fr;

  .inner-block {
    max-width: 1280px;
    grid-template-columns: 3fr 5fr;

    display: grid;
    justify-content: space-between;
    align-content: center;

    .block-text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      row-gap: 1rem;
      text-align: end;
      h3 {
        font-family: Lato-Thin;
      }
    }

    .block-image {
      height: 180px;
    }

    button span {
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 3rem;

    .inner-block {
      grid-template-columns: 1fr 1fr !important;
      gap: 2rem;

      .block-image {
        height: 400px;
        background-size: contain !important;
        background-position: left center !important;
        max-width: 500px;
      }
      .block-text {
        justify-content: center;
        row-gap: 2rem;

        button span {
          font-size: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 385px) {
    .inner-block .block-text h3 {
      font-size: 12px;
    }
  }
`;

export const BlockComponent = ({
  image,
  title,
  description,
  button,
  buttonLink,
  gradient,
  type,
  revert = false,
}: BlockContentProps) => {
  return (
    <BlockStyle className={[type, "block"].join("-")} datatype={gradient}>
      {revert ? (
        <div
          className="inner-block"
          style={{ gridTemplateColumns: revert ? "5fr 3fr" : "3fr 5fr" }}
        >
          <div className="block-text">
            <div className="title">
              {title.map((t) => (
                <h2 key={t.substring(0, 4)}>{t}</h2>
              ))}
            </div>
            <div className="description">
              {description.map((d) => (
                <h3 key={d.substring(0, 4)}>{d}</h3>
              ))}
            </div>
            {button && (
              <a href={buttonLink} target="_blank" rel="noreferrer">
                <Button label={button} />
              </a>
            )}
          </div>
          <div
            className="block-image"
            style={{
              background: `url(${image.src}) no-repeat ${image.size}`,
            }}
          ></div>
        </div>
      ) : (
        <div className="inner-block">
          <div
            className="block-image"
            style={{
              background: `url(${image.src}) no-repeat ${image.size}`,
            }}
          ></div>
          <div className="block-text">
            <div className="title">
              {title.map((t) => (
                <h2 key={t.substring(0, 4)}>{t}</h2>
              ))}
            </div>
            <div className="description">
              {description.map((d) => (
                <h3 key={d.substring(0, 4)}>{d}</h3>
              ))}
            </div>
            {button && (
              <a href={buttonLink} target="_blank" rel="noreferrer">
                <Button label={button} />
              </a>
            )}
          </div>
        </div>
      )}
    </BlockStyle>
  );
};
