import { useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../api/axios";
import { primaryColor } from "../../components/colors";
import { EventProps, VenueProps } from "../../interfaces";
import { SeoDynamicPages } from "./components/SeoDynamicPages";
import { Content } from "./Content";
import { Hero } from "./Hero";
import { MessageComponent } from "./MessageComponent";

export const CONTENT_STATUS_ACTIVE = "approved=true&status=active";

const generateVenueRequestUrl = (
  params: Readonly<Params<string>>,
  id?: string
): string =>
  !!id
    ? `/venues/${id}?${CONTENT_STATUS_ACTIVE}`
    : `/venues?parsed_name=${params.parsed_name}&country=${params.country}&city=${params.city}&type=${params.type}&${CONTENT_STATUS_ACTIVE}`;

const DynamicPageStyle = styled.section.attrs({ className: "dynamic-page" })`
  background-color: ${primaryColor};
`;

export const DynamicVenuePage = ({
  pageType,
}: {
  pageType: "Event" | "Venue";
}) => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [venue, setVenue] = useState<VenueProps | null>(null);
  const [event, setEvent] = useState<EventProps | null>(null);

  useEffect(() => {
    const fetchVenue = async (id?: string) => {
      try {
        const requestUrl = generateVenueRequestUrl(params, id);

        // GET VENUE BY PARSED NAME RETURN AN ARRAY OF OBJECTS
        // GET VENUE BY ID RETURN THE VENUE OBJECT
        const response = await axios.get(requestUrl);
        const {
          data: { data },
        } = response;

        // CHECKING WHICH GET METHOD HAS BEEN REQUESTED (BY ID OR BY PARSED_NAME)
        // SETTING ERRORS OR DATA DEPENDING ON THE REQUEST RESPONSE (ARRAY OR OBJECT)
        if (!id) {
          if (!data?.length) throw new Error("Errore! Locale non trovato.");
          setVenue(data[0]);
        } else if (!!id) {
          if (!data) throw new Error("Errore! Evento non trovato.");
          setVenue(data);
        }

        setError("");
      } catch (err: any) {
        console.error(err);
        setVenue(null);
        setError(
          err?.response?.status === 404
            ? id
              ? "Errore! Evento non trovato."
              : "Errore! Locale non trovato."
            : err?.response?.data?.message || err?.message || "Errore!"
        );
      } finally {
        setLoading(false);
      }
    };

    if (pageType === "Event") {
      const fetchEvent = async () => {
        try {
          const { event_id } = params;
          const response = await axios.get(
            `/events/${event_id}?${CONTENT_STATUS_ACTIVE}`
          );
          const {
            data: { data },
          } = response;
          if (!data) throw new Error("Errore! Evento non trovato.");
          await fetchVenue(data?.venue_id);
          setEvent(data);
        } catch (err: any) {
          console.error(err);
          setEvent(null);
          setError(
            err?.response?.status === 404
              ? "Errore! Evento non trovato."
              : err?.response?.data?.message || "Errore!"
          );
        } finally {
          setLoading(false);
        }
      };

      fetchEvent();
    }
    pageType === "Venue" && fetchVenue();
  }, [pageType, params, params.parsed_name]);

  return (
    <DynamicPageStyle>
      <SeoDynamicPages {...{ pageType, venue, event }} />
      {loading || error ? (
        <MessageComponent message={loading ? "Loading..." : error} />
      ) : (
        !!venue && (
          <>
            <Hero
              venue={venue}
              {...(pageType === "Event" && !!event && { event })}
            />
            <Content
              venue={venue}
              pageType={pageType}
              {...(pageType === "Event" && !!event && { event })}
            />
          </>
        )
      )}
    </DynamicPageStyle>
  );
};
