import styled from "styled-components";
import { primaryColor } from "../../../components/colors";
import hintsImage from "../../../assets/images/guide/communication.png";

const HintsStyle = styled.div.attrs({ className: "hints-block" })`
  background: ${primaryColor};
  display: flex;
  padding: 2rem 1rem;

  .inner-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      img {
        width: 40px;
        height: 40px;
        filter: invert(100%);
      }

      h2 {
        font-size: 16px;
        text-align: center;
      }
    }

    .body {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      p {
        margin: 0;
        line-height: 1.7rem;
        font-family: Lato-Light;
        margin-top: 0.5rem;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .inner-block {
      .header {
        img {
          width: 50px;
          height: 50px;
        }
        h2 {
          font-size: 24px;
        }
      }

      .body {
        h3 {
          font-size: 21px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
`;

export const GuidaHints = () => {
  return (
    <HintsStyle>
      <div className="inner-block">
        <div className="header">
          <img src={hintsImage} alt="communication" />
          <h2>Alcuni consigli utili per promuovere al meglio i tuoi eventi!</h2>
        </div>
        <ul className="body">
          <li>
            <h3>•&emsp;&emsp;Carica un immagine accattivante!</h3>
            <p>
              Immagini in buona risoluzione e con le giuste dimensioni
              (1000x500) possono attirare fino al 35% in più di clienti!
            </p>
          </li>
          <li>
            <h3>•&emsp;&emsp;Descrivi al meglio il tuo Evento!</h3>
            <p>Descrizioni accurate aumentano l’interesse del 20%</p>
          </li>
        </ul>
      </div>
    </HintsStyle>
  );
};
