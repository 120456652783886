export const buttonColor = "#890400";
export const buttonHoverColor = "#d20700";
export const buttonDarkColor = "#610403";
export const primaryColor = "#222222";
export const secondaryColor = "white";

export const partnersColor = "#cbcbbc";
export const shadowColor = "rgba(0, 0, 0, 0.49)";

// #890400 light
// #610403 dark
// #d20700 bright
