import { useEffect } from "react";
import { axiosStripe } from "../api/axios";

export const useStripeAxios = () => {
  useEffect(() => {
    const requestIntercept = axiosStripe.interceptors.request.use(
      (config) => config,
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosStripe.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if ([500, 503].includes(error?.response?.status) && !prevRequest.sent) {
          prevRequest.sent = true;
          return setTimeout(async () => await axiosStripe(prevRequest), 5000);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosStripe.interceptors.request.eject(requestIntercept);
      axiosStripe.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return axiosStripe;
};
