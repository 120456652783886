import styled from "styled-components";
import { primaryColor } from "../../components/colors";
import alex from "../../assets/images/team/Alessandro.png";
import nigal from "../../assets/images/team/Nigal.png";
import lorenzo from "../../assets/images/team/Lorenzo.png";
import ilaria from "../../assets/images/team/Ilaria.png";
import joanna from "../../assets/images/team/Joanna.png";
import andrea from "../../assets/images/team/Andrea.png";
import salvatore from "../../assets/images/team/Salvatore.png";
import capula from "../../assets/images/team/Capula.png";

const TeamStyle = styled.div.attrs({ className: "team-block" })`
  background: ${primaryColor};
  display: flex;
  padding: 2rem 1rem;

  .inner-block {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 0.2rem;
  }

  .team-member {
    width: 115px;
    height: 180px;
    .member-image {
      width: 100px;
      height: 100px;
      margin: auto;
    }
    .member-details {
      text-align: center;
      h3 {
        margin-top: 0.5rem;
        margin-bottom: 0.15rem;
        font-weight: lighter;
      }
      p {
        margin: 0;
        font-size: 14px;
        font-family: Lato-Light;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 3rem;
    .inner-block {
      gap: 2rem;
      row-gap: 3rem;
      width: 100%;
    }
    .team-member {
      width: 185px;
      height: auto;
      .member-image {
        width: 170px;
        height: 170px;
      }
      .member-details {
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 22px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .inner-block {
      display: grid;
      grid-template-columns: 210px 210px 210px 210px;
      place-content: space-between;
    }
  }

  @media only screen and (min-width: 1280px) {
    .inner-block {
      grid-template-columns: 215px 215px 215px 215px;
    }
  }
`;

const Team_Contents = [
  {
    name: "Alessandro Angeli",
    role: "CEO",
    image: alex,
    key: "angeli",
  },
  {
    name: "Nigal Ranieri",
    role: "CTO",
    image: nigal,
    key: "ranieri",
  },
  {
    name: "Lorenzo Valente",
    role: "CIO",
    image: lorenzo,
    key: "valente",
  },
  {
    name: "Andrea Angeli",
    role: "Legal",
    image: andrea,
    key: "andrea",
  },
  {
    name: "Ilaria Eterno",
    role: "Graphic Designer",
    image: ilaria,
    key: "eterno",
  },
  {
    name: "Joanna Pietruska",
    role: "Scrum",
    image: joanna,
    key: "pietruska",
  },
  {
    name: "Andrea Capula",
    role: "Fiscality",
    image: capula,
    key: "capula",
  },
  {
    name: "Salvatore De Rosa",
    role: "Frontend Developer",
    image: salvatore,
    key: "salvatore",
  },
];

export const Team = () => {
  return (
    <TeamStyle>
      <div className="inner-block">
        {Team_Contents.map(({ name, role, key, image }) => (
          <div className={"team-member " + key} key={key}>
            <div
              className="member-image"
              style={{ background: `url(${image}) no-repeat center /contain` }}
            ></div>
            <div className="member-details">
              <h3>{role}</h3>
              <p>{name}</p>
            </div>
          </div>
        ))}
      </div>
    </TeamStyle>
  );
};
