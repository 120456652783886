import { ScrollToTop } from "./ScrollToTop";
import { Navbar } from "./navbar/Navbar";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import styled from "styled-components";
import { SeoWrapper } from "./SeoWrapper";
import { CookieBanner } from "./CookieBanner";

const AppContainer = styled.div.attrs({ className: "app-container" })`
  display: grid;
  grid-template-columns: 1fr;
`;

export const Layout = () => {
  return (
    <AppContainer>
      <SeoWrapper page="generic" />
      <CookieBanner />
      <ScrollToTop />
      <Navbar />
      <Outlet />
      <Footer />
    </AppContainer>
  );
};
