import { Helmet } from "react-helmet";
import { EventProps, VenueProps } from "../../../interfaces";
const EVENT_SEO_DESCRIPTION =
  "Cerchi il la serata giusta? Ecco l’evento perfetto per te!";
const VENUE_SEO_DESCRIPTION =
  "Cerchi il locale giusto per trascorrere la tua serata? Eccone uno, perfetto per te!";

export const SeoDynamicPages = ({
  pageType,
  venue,
  event,
}: {
  pageType: "Event" | "Venue";
  venue: VenueProps | null;
  event: EventProps | null;
}) => {
  return (
    <Helmet>
      <meta
        name="description"
        content={
          pageType === "Venue" ? VENUE_SEO_DESCRIPTION : EVENT_SEO_DESCRIPTION
        }
      />
      <meta
        name="keywords"
        content={[
          venue?.name,
          venue?.city,
          venue?.type,
          "Night",
          "Citizen",
          "App",
        ].join("; ")}
      />
      <title>
        {[event?.name || venue?.name, " - Night Citizen App"].join("")}
      </title>
    </Helmet>
  );
};
