import styled from "styled-components";
import { Button } from "../../components/Button";
import laptop from "../../assets/images/mockup/Laptop.png";

const PromuoviStyle = styled.div`
  background: ${(props) => props.datatype};
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1rem 2.5rem;
  position: relative;

  .inner-block {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    max-width: 1280px;

    .block-image {
      width: 210px;
      height: 185px;
    }

    .block-text {
      position: absolute;
      top: 0;
      left: 0;
      text-align: left;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
      h3 {
        font-family: Lato-Thin;
      }

      button span {
        font-size: 15px;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding-inline: 3rem;
    .inner-block {
      .block-text {
        top: 3rem;
        row-gap: 2rem;
        button span {
          font-size: 20px;
        }
      }

      .block-image {
        width: 400px;
        height: 400px;
        background-size: contain !important;
        background-position: right center !important;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .inner-block {
      .block-image {
        width: 600px;
      }
    }
  }
`;

const data = {
  title: ["Promuovi il tuo locale", "ed i tuoi eventi"],
  description: [
    "La miglior piattaforma all-in one",
    "per la gestione e la promozione",
    "dei tuoi eventi e del tuo locale.",
    "Vendi biglietti in modo semplice,",
    "veloce ed efficace!",
  ],
  button: "Iscrivi Locale",
};

export const Promuovi = () => {
  const { title, description, button } = data;
  return (
    <PromuoviStyle
      className={["promuovi", "block"].join("-")}
      datatype="linear-gradient(34deg, rgba(0,0,0,1) 0%, rgba(101,1,14,1) 100%);"
    >
      <div className="inner-block">
        <div
          className="block-image"
          style={{
            background: `url(${laptop}) no-repeat left center / 105% 100%`,
          }}
        ></div>
        <div className="block-text">
          <div className="title">
            {title.map((t) => (
              <h2 key={t.substring(0, 4)}>{t}</h2>
            ))}
          </div>
          <div className="description">
            {description.map((d) => (
              <h3 key={d.substring(0, 4)}>{d}</h3>
            ))}
          </div>
          <a
            href="https://nightcitizenmanager.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Button label={button} />
          </a>
        </div>
      </div>
    </PromuoviStyle>
  );
};
