import { Link } from "react-router-dom";
import styled from "styled-components";
import { buttonColor, buttonHoverColor, primaryColor } from "../colors";

const NavigationList = styled.ul.attrs({
  className: "navigation-list",
})`
  display: flex;
  gap: 1.5rem;

  a {
    color: ${primaryColor};
    font-size: 16px;
  }

  @media only screen and (min-width: 768px) {
    li {
      position: relative;
      display: flex;
      a {
        padding: 0 2px;
        // font-size: 20px;

        &:after {
          content: "";
          position: absolute;
          background-color: ${buttonHoverColor};
          height: 1px;
          width: 0;
          left: 0;
          bottom: -5px;
          transition: 0.3s;
        }

        &:hover {
          color: ${buttonColor};
          font-weight: 600;
        }

        &:hover:after {
          width: 100%;
        }
      }
    }
  }
`;

export const NavigationLinks = ({ handleClose }: { handleClose?: any }) => {
  const navListItems = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "App",
      url: "/app",
    },
    {
      label: "Locali",
      url: "/locali",
    },
    {
      label: "Stampa",
      url: "/stampa",
    },
    {
      label: "Chi Siamo",
      url: "/chisiamo",
    },
  ];

  const linkConfig = !!handleClose ? { onClick: handleClose } : {};

  return (
    <>
      {navListItems.map(({ label, url }) => (
        <li className="nav-links" key={label}>
          <Link to={url} {...linkConfig}>
            {label}
          </Link>
        </li>
      ))}
    </>
  );
};
export const NavList = ({ handleClose }: { handleClose?: any }) => {
  return (
    <NavigationList>
      <NavigationLinks handleClose={handleClose} />
    </NavigationList>
  );
};
