export const Info = ({ description }: { description: string }) => {
  return (
    <div className="info section">
      <div className="header">
        <h2>INFO</h2>
      </div>
      <div className="body">
        <p>{description}</p>
      </div>
    </div>
  );
};
