import { FormControl, InputAdornment, FormHelperText } from "@mui/material";
import Input from "@mui/material/Input";
import { SetStateAction, Dispatch, useEffect, useState } from "react";
import styled from "styled-components";
import { Attendee } from "../../interfaces";
import { Tos } from "../../pages/DynamicContents/components/buy-tickets/Stage3";
import { secondaryColor } from "../colors";

const FormControlStyle = styled(FormControl)`
  .MuiFormHelperText-root {
    color: #d32f2f;
    min-height: 20px;

    &.helper {
      color: grey;
      font-size: 12px;
    }
  }

  .MuiInput-root.Mui-disabled:before {
    border-bottom-style: solid;
  }
`;
const CustomInputStyle = styled(Input)`
  &.MuiInput-root,
  &.Mui-focused {
    color: ${secondaryColor};
    font-size: 18px;
  }
  &.MuiInput-root:after {
    border-bottom: 2px solid ${secondaryColor};
  }
  &.MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #ababab;
  }
  &.MuiInput-root:before {
    border-bottom: 2px solid #8b8b8b;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .MuiInputAdornment-root p {
    color: #8d8d8d;
  }
  .MuiInput-input {
    padding-inline: 0.5rem;
    &::placeholder {
      color: grey;
      font-size: 16px;
      opacity: 1;
    }
  }
  .MuiInput-input.Mui-disabled {
    -webkit-text-fill-color: ${secondaryColor};
    color: ${secondaryColor};
  }

  .show-password {
    width: 18px;
    filter: invert(30%);
    cursor: pointer;
  }
  .input-icon-adorment {
    width: 18px;
    filter: invert(70%);
  }
`;

const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (mail: string) =>
  !!mail && emailRegExp.test(mail.toLowerCase());

const IconAdorment = ({ icon }: { icon: string }) => (
  <InputAdornment position="end">
    <img className="input-icon-adorment" src={icon} alt="Icon" />
  </InputAdornment>
);

export const CustomInput = ({
  name,
  label,
  value,
  setValues,
  idx,
  ...otherProps
}: {
  name: "first_name" | "last_name" | "email" | "confirm_email";
  value: string;
  setValues: Dispatch<
    SetStateAction<{
      attendees: Attendee[];
      tos: Tos;
    }>
  >;
  idx: number;
  [key: string]: any;
}) => {
  const [helperText, setHelperText] = useState<string>("");
  const [isTouched, setIsTouched] = useState<boolean>(false);

  useEffect(() => {
    if (!isTouched) return;
    if (name === "confirm_email")
      setHelperText(value !== otherProps.email ? "Email non corretta" : "");
    else if (name === "email")
      setHelperText(validateEmail(value) ? "" : "Email non corretta");
    else
      setHelperText(
        value.length < 3 ? `Il ${label} deve essere almeno 3 caratteri` : ""
      );
  }, [label, name, value, isTouched, otherProps.email]);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setIsTouched(true);

    setValues((prev) => {
      let attendees = prev.attendees;
      attendees[idx] = {
        ...attendees[idx],
        [name]: value,
      };

      return {
        ...prev,
        attendees,
      };
    });
  };

  const configTextField = {
    ...otherProps,
    onChange: handleChange,
    variant: "standard",
    error: !!helperText,
    placeholder:
      name === "email" && idx === 0 ? "Email per la ricevuta" : label,
    id: name,
    "aria-describedby": "component-error-text",
    startAdornment: <IconAdorment icon={otherProps.icon} />,
  };

  return (
    <FormControlStyle variant="standard">
      <CustomInputStyle {...configTextField} />
      <FormHelperText id="component-error-text">{helperText}</FormHelperText>
    </FormControlStyle>
  );
};
