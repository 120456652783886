import styled from "styled-components";
import facebook from "../assets/images/socials/facebook.png";
import instagram from "../assets/images/socials/instagram.png";
import youtube from "../assets/images/socials/youtube.png";
import linkedin from "../assets/images/socials/linkedin.png";
import twitter from "../assets/images/socials/twitter.png";

const SocialsList = styled.ul.attrs({
  className: "socials-list",
})`
  display: flex;
  gap: 1rem;
  img {
    width: 25px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.3);
    }
  }
`;

const SOCIALS = [
  {
    name: "facebook",
    icon: facebook,
    url: "https://www.facebook.com/nightcitizenapp",
  },
  {
    name: "twitter",
    icon: twitter,
    url: "https://www.twitter.com/nightcitizenapp",
  },
  {
    name: "instagram",
    icon: instagram,
    url: "https://instagram.com/nightcitizenapp",
  },
  {
    name: "youtube",
    icon: youtube,
    url: "https://www.youtube.com/channel/UCyods4ZFXKFpz8O_ZzUjWyg",
  },
  {
    name: "linkedin",
    icon: linkedin,
    url: "https://www.linkedin.com/company/night-citizen",
  },
];

export const Socials = ({ isFooter = false }: { isFooter?: boolean }) => {
  return (
    <SocialsList style={isFooter ? { padding: "0" } : {}}>
      {SOCIALS.map(({ name, icon, url }) => (
        <li key={name}>
          <a href={url} target="_blank" rel="noreferrer">
            <img alt={name} src={icon} />
          </a>
        </li>
      ))}
    </SocialsList>
  );
};
