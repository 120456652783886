import { Link } from "react-router-dom";
import styled from "styled-components";
import { Socials } from "./Socials";
import { NavigationLinks } from "./navbar/NavList";
import playStore from "../assets/images/appstores/GooglePlay.png";
import appleStore from "../assets/images/appstores/AppStore.png";
import { buttonColor, buttonHoverColor, primaryColor } from "./colors";

const FooterContainer = styled.footer.attrs({
  className: "footer-container",
})`
  li,
  a,
  p {
    color: ${primaryColor};
    text-decoration: none;
    transition: 0.3s;
    font-size: 12px;
  }

  h3 {
    margin-block: 1em;
    font-size: 1.17em;
  }

  a:hover {
    color: ${buttonHoverColor};
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 2rem;
  text-align: start;
  column-gap: 2rem;
  .footer-col {
    display: grid;
    a {
      position: relative;
      transition: 0.3s;

      &:after {
        content: "";
        position: absolute;
        background-color: ${buttonHoverColor};
        height: 1px;
        width: 0;
        left: 0;
        bottom: -5px;
        transition: 0.3s;
      }

      &:hover {
        color: ${buttonColor};
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
  .copyright {
    font-size: 11px;
    @media only screen and (min-width: 768px) {
      margin-block: 0;
      grid-column-end: 4;
    }
    @media only screen and (min-width: 1024px) {
      margin-bottom: 1rem;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 2rem;
      font-size: 9px;
    }
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    margin-block: 0;
    font-family: "Lato-Light";
  }
  ul {
    padding: 0;
    margin-top: 0;
    li {
      &:first-child {
        text-align: center;
      }
      margin-block: 0.5rem;
    }
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2rem 0rem 1rem;
    .footer-col {
      grid-template-columns: 1fr 2fr 1fr;
      ul {
        grid-column: 2 / span 1;
        margin-top: 1rem;
        li {
          margin-block: 0.8rem;
        }
      }
    }

    li,
    a,
    p {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 0;
    ul {
      padding: initial;
    }
  }

  @media only screen and (min-width: 1440px) {
    width: 1280px;
    margin: auto;
    .footer-col {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      ul {
        grid-column: 2 / span 2;
      }
    }
  }
`;
const ScopriList = styled.ul.attrs({ className: "scopri-container" })``;
const GuideList = styled.ul.attrs({ className: "guide-container" })``;
const AssistenzaList = styled.ul.attrs({ className: "assistenza-container" })``;
const ScaricaAppList = styled.ul.attrs({
  className: "scarica-app-container",
})`
  h3 {
    margin-bottom: 0;
  }
  .appstore-button {
    width: 85px;
    height: 30px;
    cursor: pointer;
    padding: 0.5rem;
    transition: 0.5s;
    &.apple {
      background: url(${appleStore});
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      &:hover {
        transform: scale(1.15) translateX(-5px);
      }
    }
    &.google {
      background: url(${playStore});
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      &:hover {
        transform: scale(1.15) translateX(5px);
      }
    }
  }
  .app-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    @media only screen and (min-width: 768px) {
      margin-block: 0;
      height: 35px;
      margin-top: 1.5rem;
      .appstore-button {
        padding: 0;
        height: 35px;
        width: 100px;
      }
    }
  }
`;
const SocialsList = styled.ul.attrs({ className: "socials-container" })`
  @media only screen and (max-width: 500px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  div {
    display: flex;
    gap: 0.5rem;
  }

  .socials-list {
    margin: 0;
    padding: 0;
    margin-block: 0.5rem;
    justify-content: center;
    li {
      margin-block: 0.3rem;
    }
    img {
      width: 32px;
      height: 32px;
    }
  }
`;
const LinguaList = styled.ul.attrs({ className: "lingua-container" })`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1rem;
  @media only screen and (max-width: 500px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  li {
    margin-bottom: 0 !important;
  }
  h3 {
    height: 22px;
    margin-bottom: 0;
  }
  select {
    background-color: transparent;
    width: 100%;
    max-width: 250px;
    height: 35px;
    border-radius: 5px;
    border: 0.5px solid #80808040;
    margin-bottom: 1rem;
  }
  img {
    width: 18px;
  }
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <div className="footer-col">
        <ScopriList>
          <li>
            <h3>Scopri</h3>
          </li>
          <NavigationLinks />
        </ScopriList>
      </div>
      <div className="footer-col">
        <GuideList>
          <li>
            <h3>Guide</h3>
          </li>
          <li>
            <Link to="/guida-iscrivere">Iscrivere Locale</Link>
          </li>
          <li>
            <Link to="/guida-vendere">Vendere Biglietti</Link>
          </li>
          <li>
            <Link to="/guida-validare">Validare Biglietti</Link>
          </li>
        </GuideList>
      </div>
      <div className="footer-col">
        <AssistenzaList>
          <li>
            <h3>Assistenza</h3>
          </li>
          <li>
            <Link to="/terms-and-conditions">Termini e Condizioni d'Uso</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li>
          <li>
            <a href="mailto:support@nightcitizenapp.com">Supporto</a>
          </li>
        </AssistenzaList>
      </div>
      <ScaricaAppList>
        <li>
          <h3>Scarica L'app</h3>
        </li>
        <li className="app-button-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/it/app/night-citizen/id1499283216"
          >
            <div className="appstore-button apple" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.NightCitizen.NightCitizen"
          >
            <div className="appstore-button google" />
          </a>
        </li>
      </ScaricaAppList>
      <SocialsList>
        <li className="social-title">
          <h3>Social</h3>
        </li>
        <Socials />
      </SocialsList>
      <LinguaList>
        <select>
          <option value="Italian">&nbsp;&nbsp;🇮🇹&nbsp;&nbsp;Italian</option>
        </select>
      </LinguaList>
      <p className="copyright">
        © 2022: Night Citizen Srls - P.IVA 14892911000
      </p>
    </FooterContainer>
  );
};
