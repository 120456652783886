import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { AppPage } from "./pages/App";
import { CookiePolicyPage } from "./pages/Assistenza/CookiePolicy";
import { PrivacyPolicyPage } from "./pages/Assistenza/PrivacyPolicy";
import { TermsAndConditionsPage } from "./pages/Assistenza/TermsAndConditions";
import { ChiSiamoPage } from "./pages/ChiSiamo";
import { DynamicVenuePage } from "./pages/DynamicContents";
import { IscrivereGuidaPage } from "./pages/Guide/Iscrivere";
import { ValidareGuidaPage } from "./pages/Guide/Validare";
import { VendereGuidaPage } from "./pages/Guide/Vendere";
import { HomePage } from "./pages/Home";
import { LocaliPage } from "./pages/Locali";
import { StampaPage } from "./pages/Stampa";

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* PUBLIC ROUTES */}
        <Route path="/" element={<HomePage />} />
        <Route path="app" element={<AppPage />} />
        <Route path="locali" element={<LocaliPage />} />
        <Route path="stampa" element={<StampaPage />} />
        <Route path="chisiamo" element={<ChiSiamoPage />} />

        <Route
          path=":country/:city/:type/:parsed_name"
          element={<DynamicVenuePage pageType="Venue" />}
        />
        <Route
          path="event/:event_id"
          element={<DynamicVenuePage pageType="Event" />}
        />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="cookie-policy" element={<CookiePolicyPage />} />
        <Route
          path="terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="guida-vendere" element={<VendereGuidaPage />} />
        <Route path="guida-validare" element={<ValidareGuidaPage />} />
        <Route path="guida-iscrivere" element={<IscrivereGuidaPage />} />
      </Route>
    </Routes>
  );
};
