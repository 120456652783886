export const cookie = `<p style="margin-bottom: 0.21cm; line-height: 0.51cm">
<font color="white"><font face="Lato-Regular, serif"><font size="6" style="font-size: 23pt"><span lang="en-US"><b>Cookie
Policy di <span class='header-title'>www.nightcitizenapp.com</span></b></span></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questo
documento contiene informazioni in merito alle tecnologie che
consentono a questa Applicazione di raggiungere gli scopi descritti
di seguito. Tali tecnologie permettono al Titolare di raccogliere e
salvare informazioni (per esempio tramite l’utilizzo di Cookie) o
di utilizzare risorse (per esempio eseguendo uno script) sul
dispositivo dell’Utente quando quest’ultimo interagisce con
questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Per
semplicità, in questo documento tali tecnologie sono sinteticamente
definite “Strumenti di Tracciamento”, salvo vi sia ragione di
differenziare.<br/>
Per esempio, sebbene i Cookie possano essere
usati in browser sia web sia mobili, sarebbe fuori luogo parlare di
Cookie nel contesto di applicazioni per dispositivi mobili, dal
momento che si tratta di Strumenti di Tracciamento che richiedono la
presenza di un browser. Per questo motivo, all’interno di questo
documento il termine Cookie è utilizzato solo per indicare in modo
specifico quel particolare tipo di Strumento di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Alcune
delle finalità per le quali vengono impiegati Strumenti di
Tracciamento potrebbero, inoltre richiedere il consenso dell’Utente.
Se viene prestato il consenso, esso può essere revocato liberamente
in qualsiasi momento seguendo le istruzioni contenute in questo
documento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione utilizza Strumenti di Tracciamento gestiti direttamente
dal Titolare (comunemente detti Strumenti di Tracciamento “di prima
parte”) e Strumenti di Tracciamento che abilitano servizi forniti
da terzi (comunemente detti Strumenti di Tracciamento “di terza
parte”). Se non diversamente specificato all’interno di questo
documento, tali terzi hanno accesso ai rispettivi Strumenti di
Tracciamento.<br/>
Durata e scadenza dei Cookie e degli altri
Strumenti di Tracciamento simili possono variare a seconda di quanto
impostato dal Titolare o da ciascun fornitore terzo. Alcuni di essi
scadono al termine della sessione di navigazione dell’Utente.<br/>
In
aggiunta a quanto specificato nella descrizione di ciascuna delle
categorie di seguito riportate, gli Utenti possono ottenere
informazioni più dettagliate ed aggiornate sulla durata, così come
qualsiasi altra informazione rilevante - quale la presenza di altri
Strumenti di Tracciamento - nelle privacy policy dei rispettivi
fornitori terzi (tramite i link messi a disposizione) o contattando
il Titolare.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 15pt"><b>Attività
strettamente necessarie a garantire il funzionamento di questa
Applicazione e la fornitura del Servizio</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione utilizza Cookie comunemente detti “tecnici” o altri
Strumenti di Tracciamento analoghi per svolgere attività
strettamente necessarie a garantire il funzionamento o la fornitura
del Servizio.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Strumenti
di Tracciamento di terza parte</b></font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Gestione
	dei tag&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questo
tipo di servizi è funzionale alla gestione centralizzata dei tag o
script utilizzati su questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">L'uso
di tali servizi comporta il fluire dei Dati dell'Utente attraverso
gli stessi e, se del caso, la loro ritenzione.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><span lang="en-US"><b>Google
Tag Manager (Google Ireland Limited)</b></span></font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Google
Tag Manager è un servizio di gestione dei tag fornito da Google
Ireland Limited.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Gestione
	dei pagamenti&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Se
non diversamente specificato, questa Applicazione elabora tutti i
pagamenti con carta di credito, bonifico bancario o altri mezzi
tramite fornitori esterni di servizi di pagamento. In generale, e
salvo diversa indicazione, gli Utenti sono pregati di fornire i
dettagli di pagamento e le informazioni personali direttamente a tali
fornitori di servizi di pagamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white">&nbsp;<font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione non è coinvolta nella raccolta e nell'elaborazione di
tali informazioni: riceverà invece solo una notifica da parte del
fornitore di servizi di pagamento in questione circa l'avvenuto
pagamento.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><span lang="en-US"><b>Stripe
( Stripe Technology Europe Ltd)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Stripe
è un servizio di pagamento fornito da Stripe Technology Europe Ltd.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: cognome, cronologia acquisti, email, informazioni
di pagamento, nome e Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://stripe.com/ie/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Durata
di archiviazione:&nbsp;</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">__stripe_mid:
		1 anno</font></font></font></p>
		<li><p style="margin-bottom: 0cm; line-height: 100%"><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">__stripe_sid:
		30 minuti</font></font></font></p>
		<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">m:
		2 anni</font></font></font></p>
	</ul>
</ul>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 15pt"><b>Altre
attività che prevedono l’utilizzo di Strumenti di Tracciamento</b></font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Interazioni
e funzionalità semplici</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione utilizza Strumenti di Tracciamento per consentire
semplici interazioni e attivare funzionalità che permettono agli
Utenti di accedere a determinate risorse del Servizio e semplificano
la comunicazione con il Titolare.</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Registrazione
	ed autenticazione&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Con
la registrazione o l’autenticazione l’Utente consente a questa
Applicazione di identificarlo e di dargli accesso a servizi
dedicati.<br/>
A seconda di quanto indicato di seguito, i servizi di
registrazione e di autenticazione potrebbero essere forniti con
l’ausilio di terze parti. Qualora questo avvenga, questa
Applicazione potrà accedere ad alcuni Dati conservati dal servizio
terzo usato per la registrazione o l’identificazione.&nbsp;<br/>
Alcuni
dei servizi di seguito indicati potrebbero raccogliere Dati Personali
anche per fini di targeting e profilazione; per saperne di più, si
prega di fare riferimento alla descrizione di ciascun servizio.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><span lang="en-US"><b>Facebook
Authentication (Meta Platforms Ireland Limited)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Facebook
Authentication è un servizio di registrazione ed autenticazione
fornito da Meta Platforms Ireland Limited e collegato al social
network Facebook.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Strumenti di Tracciamento e varie tipologie di
Dati secondo quanto specificato dalla privacy policy del servizio.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://www.facebook.com/help/405977429438260" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Durata
di archiviazione:&nbsp;</font></font></font></p>
<ul>
	<ul>
		<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">_fbp:
		3 mesi</font></font></font></p>
	</ul>
</ul>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Miglioramento
dell’esperienza</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione utilizza Strumenti di Tracciamento per fornire una user
experience personalizzata, consentendo una migliore gestione delle
impostazioni personali e l'interazione con network e piattaforme
esterne.</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Visualizzazione
	di contenuti da piattaforme esterne&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questo
tipo di servizi permette di visualizzare contenuti ospitati su
piattaforme esterne direttamente dalle pagine di questa Applicazione
e di interagire con essi.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questo
tipo di servizio potrebbe comunque raccogliere dati sul traffico web
relativo alle pagine dove il servizio è instalLato-Regular, anche quando gli
utenti non lo utilizzano.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Google
Fonts&nbsp;</b></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Google
Fonts è un servizio di visualizzazione di stili di carattere gestito
da Google LLC oppure da Google Ireland Limited, a seconda di come il
Titolare gestisce il trattamento dei Dati, che permette a questa
Applicazione di integrare tali contenuti all’interno delle proprie
pagine.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">;
Irlanda –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Misurazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione utilizza Strumenti di Tracciamento per misurare il
traffico e analizzare il comportamento degli Utenti con l'obiettivo
di migliorare il Servizio.</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Statistica&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Thin, serif"><font size="4" style="font-size: 13pt" class="inner-text">I
servizi contenuti nella presente sezione permettono al Titolare del
Trattamento di monitorare e analizzare i dati di traffico e servono a
tener traccia del comportamento dell’Utente.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><span lang="en-US"><b>Google
Analytics (Google Ireland Limited)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Google
Analytics è un servizio di analisi web fornito da Google Ireland
Limited (“Google”). Google utilizza i Dati Personali raccolti
allo scopo di tracciare ed esaminare l’utilizzo di questa
Applicazione, compilare report e condividerli con gli altri servizi
sviluppati da Google.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Google
potrebbe utilizzare i Dati Personali per contestualizzare e
personalizzare gli annunci del proprio network pubblicitario.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;–&nbsp;</font></font></font><a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Opt
Out</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Durata
di archiviazione:&nbsp;</font></font></font></p>
<ul>
	<ul>
		<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">AMP_TOKEN:
		1 ora</font></font></font></p>
		<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">_ga:
		2 anni</font></font></font></p>
		<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">_gac*:
		3 mesi</font></font></font></p>
		<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">_gat:
		1 minuto</font></font></font></p>
		<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">_gid:
		1 giorno</font></font></font></p>
	</ul>
</ul>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Monitoraggio
conversioni di Facebook Ads (pixel di Facebook) (Meta Platforms
Ireland Limited)</b></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Il
monitoraggio conversioni di Facebook Ads (pixel di Facebook) è un
servizio di statistiche fornito da Meta Platforms Ireland Limited che
collega i dati provenienti dal network di annunci Meta con le azioni
compiute all'interno di questa Applicazione. Il pixel di Facebook
monitora le conversioni che possono essere attribuite alle inserzioni
di Facebook, Instagram e Audience Network.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://www.facebook.com/about/privacy/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Durata
di archiviazione:&nbsp;</font></font></font></p>
<ul>
	<ul>
		<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">_fbp:
		3 mesi</font></font></font></p>
		<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
		<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">fr:
		3 mesi</font></font></font></p>
	</ul>
</ul>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Targeting
e Pubblicità</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
Applicazione utilizza Strumenti di Tracciamento per fornire contenuti
commerciali personalizzati in base al comportamento dell'Utente e per
gestire, diffondere e tracciare annunci pubblicitari.</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Pubblicità&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questo
tipo di servizi consente di utilizzare i Dati dell’Utente per
finalità di comunicazione commerciale. Queste comunicazioni sono
mostrate su questa Applicazione sotto forma di banner e altre forme
pubblicitarie, anche in relazione agli interessi dell’Utente.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white">&nbsp;<font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Ciò
non significa che tutti i Dati Personali vengano utilizzati per
questa finalità. Dati e condizioni di utilizzo sono indicati di
seguito.&nbsp;<br/>
Alcuni dei servizi di seguito indicati potrebbero
utilizzare Strumenti di Tracciamento per identificare l’Utente o
utilizzare la tecnica del behavioral retargeting, ossia visualizzare
annunci pubblicitari personalizzati in base agli interessi e al
comportamento dell’Utente, rilevati anche al di fuori di questa
Applicazione. Per avere maggiori informazioni in merito, ti
suggeriamo di verificare le informative privacy dei rispettivi
servizi.&nbsp;</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Generalmente
i servizi di questo tipo offrono la possibilità di disattivare tale
tracciamento. Oltre a qualsiasi funzione di opt-out fornita da uno
qualsiasi dei servizi elencati in questo documento, l’Utente può
leggere di più su come disattivare gli annunci pubblicitari basati
sugli interessi nell'apposita sezione &quot;Come disattivare la
pubblicità basata sugli interessi&quot; in questo documento.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>JSDELIVR
(jsdelivr.com)</b></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">JSDELIVR
è un servizio di advertising fornito da jsdelivr.com.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>152
Media (152 Media LLC)</b></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">152
Media è un servizio di advertising fornito da 152 Media LLC.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://152media.com/privacy-policy/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Remarketing
	e behavioral targeting&nbsp;</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questo
tipo di servizi consente a questa Applicazione ed ai suoi partner di
comunicare, ottimizzare e servire annunci pubblicitari basati
sull'utilizzo passato di questa Applicazione da parte dell'Utente.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Questa
attività è facilitata dal tracciamento dei Dati di Utilizzo e
dall'utilizzo di Strumenti di Tracciamento per raccogliere
informazioni che vengono poi trasferite ai partner che gestiscono le
attività di remarketing e di behavioral targeting.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white">&nbsp;<font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Alcuni
servizi offrono un'opzione di remarketing basata sulle liste di
indirizzi email.&nbsp;</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Generalmente
i servizi di questo tipo offrono la possibilità di disattivare tale
tracciamento. Oltre a qualsiasi funzione di opt-out fornita da uno
qualsiasi dei servizi elencati in questo documento, l’Utente può
leggere di più su come disattivare gli annunci pubblicitari basati
sugli interessi nell'apposita sezione &quot;Come disattivare la
pubblicità basata sugli interessi&quot; in questo documento.</font></font></font></p>
<p style="margin-left: 2rem; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Pubblico
personalizzato di Outbrain (Custom Audience) (Outbrain Inc.)</b></font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Pubblico
personalizzato di Outbrain (Custom Audience) è un servizio di
remarketing e di targeting comportamentale fornito da Outbrain Inc.
che collega l'attività di questa Applicazione con la rete
pubblicitaria di Outbrain.<br/>
Gli Utenti possono scegliere di non
utilizzare i Strumenti di Tracciamento di Outbrain per la
personalizzazione degli annunci visitando questa&nbsp;</font></font></font><a href="https://my.outbrain.com/recommendations-settings/home"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>pagina
di opt-out</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dati
Personali trattati: Dati di utilizzo, Identificativo univoco
universale (UUID), informazioni sul dispositivo e Strumenti di
Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 2rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://www.outbrain.com/privacy/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Privacy
Policy</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;–&nbsp;</font></font></font><a href="https://www.outbrain.com/privacy/"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Opt
out</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">.&nbsp;</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 15pt"><b>Come
gestire le preferenze e prestare o revocare il consenso</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Esistono
vari modi per gestire le preferenze relative agli Strumenti di
Tracciamento e per prestare o revocare il consenso, ove necessario:</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Gli
Utenti possono gestire le preferenze relative agli Strumenti di
Tracciamento direttamente tramite le impostazioni dei propri
dispositivi - per esempio, possono impedire l’uso o l’archiviazione
di Strumenti di Tracciamento.&nbsp;</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">In
aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento
dipenda da consenso, l’Utente può prestare o revocare tale
consenso impostando le proprie preferenze all’interno
dell’informativa sui cookie o aggiornando tali preferenze tramite
il widget delle impostazioni di tracciamento, se presente.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Grazie
ad apposite funzioni del browser o del dispositivo è anche possibile
rimuovere Strumenti di Tracciamento precedentemente salvati.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Altri
Strumenti di Tracciamento presenti nella memoria locale del browser
possono essere rimossi cancellando la cronologia di navigazione.&nbsp;</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Per
quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti
possono gestire le preferenze e revocare il consenso visitando il
relativo link di opt out (qualora disponibile), utilizzando gli
strumenti descritti nella privacy policy della terza parte o
contattandola direttamente.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Individuare
le impostazioni relative agli Strumenti di Tracciamento</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Gli
Utenti possono, per esempio, trovare informazioni su come gestire i
Cookie in alcuni dei browser più diffusi ai seguenti indirizzi:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<a href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Google
	Chrome</u></font></font></font></a></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Mozilla
	Firefox</u></font></font></font></a></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<a href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Apple
	Safari</u></font></font></font></a></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Microsoft
	Internet Explorer</u></font></font></font></a></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<a href="https://support.microsoft.com/it-it/help/4027947" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Microsoft
	Edge</u></font></font></font></a></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<a href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Brave</u></font></font></font></a></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<a href="https://help.opera.com/latest/web-preferences/#cookies" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Opera</u></font></font></font></a></p>
</ul>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Gli
Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per
applicazioni mobili disattivandoli tramite le apposite impostazioni
del dispositivo, quali le impostazioni di pubblicità per dispositivi
mobili o le impostazioni relative al tracciamento in generale (gli
Utenti possono consultare le impostazioni del dispositivo per
individuare quella pertinente).</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Come
disattivare la pubblicità basata sugli interessi</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Fermo
restando quanto precede, si informano gli Utenti della possibilità
di avvalersi delle informazioni presenti
su&nbsp;</font></font></font><a href="http://www.youronlinechoices.eu/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>YourOnlineChoices</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;(EU),&nbsp;</font></font></font><a href="https://thenai.org/about-online-advertising/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Network
Advertising Initiative</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;(USA)
e&nbsp;</font></font></font><a href="https://www.aboutads.info/consumers/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>Digital
Advertising Alliance</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;(USA),&nbsp;</font></font></font><a href="https://youradchoices.ca/understanding-online-advertising/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>DAAC</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;(Canada),&nbsp;</font></font></font><a href="http://www.ddai.info/optout" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>DDAI</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;(Giappone)
o altri servizi analoghi. </font></font></font>
</p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Con
questi servizi è possibile gestire le preferenze di tracciamento
della maggior parte degli strumenti pubblicitari. Il Titolare,
pertanto, consiglia agli Utenti di utilizzare tali risorse in
aggiunta alle informazioni fornite nel presente documento.&nbsp;</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">La
Digital Advertising Alliance mette inoltre a disposizione
un’applicazione chiamata&nbsp;</font></font></font><a href="https://youradchoices.com/appchoices" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text"><u>AppChoices</u></font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;che
aiuta gli Utenti a controllare la pubblicità comportamentale sulle
applicazioni mobili.&nbsp;</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Conseguenze
del rifiuto del consenso</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Gli
Utenti sono liberi di decidere se prestare o meno il consenso.
Tuttavia, si noti che gli Strumenti di Tracciamento consentono a
questa Applicazione di fornire una migliore esperienza e funzionalità
avanzate agli Utenti (in linea con le finalità delineate nel
presente documento). Pertanto, in assenza del consenso dell'Utente,
il Titolare potrebbe non essere in grado di fornire le relative
funzionalità.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 15pt"><b>Titolare
del Trattamento dei Dati</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt" class="inner-text">Ticketoo
srl&nbsp;<br/>
Via Appia Nuova, 596&nbsp;<br/>
00179 Roma</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Indirizzo
email del Titolare:</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt" class="inner-text">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">support@nightcitizenapp.com</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Dal
momento che l’uso di Strumenti di Tracciamento di terza parte su
questa Applicazione non può essere completamente controllato dal
Titolare, ogni riferimento specifico a Strumenti di Tracciamento di
terza parte è da considerarsi indicativo. Per ottenere informazioni
complete, gli Utenti sono gentilmente invitati a consultare la
privacy policy dei rispettivi servizi terzi elencati in questo
documento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Data
l'oggettiva complessità di identificazione delle tecnologie di
tracciamento, gli Utenti sono invitati a contattare il Titolare
qualora volessero ricevere ulteriori informazioni in merito
all'utilizzo di tali tecnologie su questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Dati
Personali (o Dati)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Costituisce
dato personale qualunque informazione che, direttamente o
indirettamente, anche in collegamento con qualsiasi altra
informazione, ivi compreso un numero di identificazione personale,
renda identificata o identificabile una persona fisica.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Dati
di Utilizzo</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Sono
le informazioni raccolte automaticamente attraverso questa
Applicazione (anche da applicazioni di parti terze integrate in
questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio
dei computer utilizzati dall’Utente che si connette con questa
Applicazione, gli indirizzi in notazione URI (Uniform Resource
Identifier), l’orario della richiesta, il metodo utilizzato
nell’inoltrare la richiesta al server, la dimensione del file
ottenuto in risposta, il codice numerico indicante lo stato della
risposta dal server (buon fine, errore, ecc.) il paese di
provenienza, le caratteristiche del browser e del sistema operativo
utilizzati dal visitatore, le varie connotazioni temporali della
visita (ad esempio il tempo di permanenza su ciascuna pagina) e i
dettagli relativi all’itinerario seguito all’interno
dell’Applicazione, con particolare riferimento alla sequenza delle
pagine consultate, ai parametri relativi al sistema operativo e
all’ambiente informatico dell’Utente.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Utente</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">L'individuo
che utilizza questa Applicazione che, salvo ove diversamente
specificato, coincide con l'Interessato.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Interessato</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">La
persona fisica cui si riferiscono i Dati Personali.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Responsabile
del Trattamento (o Responsabile)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">La
persona fisica, giuridica, la pubblica amministrazione e qualsiasi
altro ente che tratta dati personali per conto del Titolare, secondo
quanto esposto nella presente privacy policy.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Titolare
del Trattamento (o Titolare)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">La
persona fisica o giuridica, l'autorità pubblica, il servizio o altro
organismo che, singolarmente o insieme ad altri, determina le
finalità e i mezzi del trattamento di dati personali e gli strumenti
adottati, ivi comprese le misure di sicurezza relative al
funzionamento ed alla fruizione di questa Applicazione. Il Titolare
del Trattamento, salvo quanto diversamente specificato, è il
titolare di questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Questa
Applicazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Lo
strumento hardware o software mediante il quale sono raccolti e
trattati i Dati Personali degli Utenti.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text">Servizio</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Il
Servizio fornito da questa Applicazione così come definito nei
relativi termini (se presenti) su questo sito/applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Unione
Europea (o UE)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Salvo
ove diversamente specificato, ogni riferimento all’Unione Europea
contenuto in questo documento si intende esteso a tutti gli attuali
stati membri dell’Unione Europea e dello Spazio Economico Europeo.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Cookie</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">I
Cookie sono Strumenti di Tracciamento che consistono in piccole
porzioni di dati conservate all'interno del browser dell'Utente.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Strumento
di Tracciamento</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Per
Strumento di Tracciamento s’intende qualsiasi tecnologia - es.
Cookie, identificativi univoci, web beacons, script integrati, e-tag
e fingerprinting - che consenta di tracciare gli Utenti, per esempio
raccogliendo o salvando informazioni sul dispositivo dell’Utente.</font></font></font></p>
<p style="margin-bottom: 0cm; line-height: 100%"></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt" class="inner-text"><b>Riferimenti
legali</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">La
presente informativa privacy è redatta sulla base di molteplici
ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento
(UE) 2016/679.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt" class="inner-text">Ove
non diversamente specificato, questa informativa privacy riguarda
esclusivamente questa Applicazione.</font></font></font></p>
<p style="margin-bottom: 0cm; line-height: 100%"><br/>

</p>`;
