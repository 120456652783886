import { days_schema } from "../data/Locale_Data";
import { EventProps, TicketProps } from "../interfaces";

export const dayParser = (dayN: number) =>
	dayN === 0 ? days_schema[6].label : days_schema[dayN - 1].label;

export const parseDaysInline = (opening_days: string[]) => {
	const daysLength = opening_days?.length;
	if (!daysLength) return "";
	return daysLength === 7 ? (
		<span>Da Lunedi a Domenica</span>
	) : (
		orderDays(opening_days).map((d: string, idx: number) => {
			const matchedDay = days_schema.find(({ value }) => value === d);
			if (!matchedDay) return "";
			else if (daysLength === 1) return <span key={d}>{matchedDay.label}</span>;
			else if (daysLength > 1 && idx < daysLength - 1)
				return <span key={d}>{matchedDay.label}, </span>;
			else return <span key={d}>{matchedDay.label}</span>;
		})
	);
};

export const parseTime = (time: string) => time.substring(0, 5);

export const hasFreeTickets = (tickets: TicketProps[]) =>
	tickets.some((t) => t.name.includes("FREE_ENTRY"));

export const orderDays = (days: string[]): string[] => {
	const daysToOrder = days;
	const mapDays = new Map();
	mapDays.set("MONDAY", 0);
	mapDays.set("TUESDAY", 1);
	mapDays.set("WEDNESDAY", 2);
	mapDays.set("THURSDAY", 3);
	mapDays.set("FRIDAY", 4);
	mapDays.set("SATURDAY", 5);
	mapDays.set("SUNDAY", 6);

	return daysToOrder.sort((a, b) => (mapDays.get(a) > mapDays.get(b) ? 1 : -1));
};

export const removePastEvents = (events: EventProps[]): EventProps[] => {
	const today = new Date();
	const startDate = new Date(
		`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
	);

	return events.filter((e) => new Date(e.date) >= startDate);
};

export const orderEventsByDate = (events: EventProps[]): EventProps[] =>
	events.sort((a, b) => (new Date(a.date) >= new Date(b.date) ? 1 : -1));

const splitAndConvertToNumber = (
	date: string,
	splitter: string,
	isDate: boolean = false
): number[] =>
	date.split(splitter).map((d, idx) => {
		if (isDate && idx === 1) return parseInt(d) - 1;
		return parseInt(d);
	});

const currentTimeToItalyTimeZone = (): Date => {
	const [date, time] = new Date()
		.toLocaleString(undefined, {
			timeZone: "Europe/Rome",
		})
		.split(",");

	const [day, month, year] = splitAndConvertToNumber(date, "/", true);
	const [hour, minute] = time.split(":").map((t) => parseInt(t));

	return new Date(year, month, day, hour, minute);
};

export const isEventPast = (event: EventProps): boolean => {
	const [year, month, day] = splitAndConvertToNumber(event.date, "-", true);
	const [startHour] = splitAndConvertToNumber(event.start_time, ":");
	const [endHour, endMinute] = splitAndConvertToNumber(event.end_time, ":");

	const isEventEndingTomorrow = startHour > endHour;

	const eventEndTime = new Date(
		year,
		month,
		day + +isEventEndingTomorrow,
		endHour,
		endMinute
	);

	return eventEndTime <= currentTimeToItalyTimeZone();
};

export const hasTicketSellable = (event: EventProps): boolean =>
	event.ticket_types
		?.filter((t) => !t.physical)
		?.some((t) => (t.available || 0) > 0) || false;

export const displayBuyTicketButton = (
	pageType: "Event" | "Venue",
	event: EventProps
): boolean => {
	return (
		pageType === "Event" && !isEventPast(event) && hasTicketSellable(event)
	);
};
