import styled from "styled-components";
import { primaryColor, shadowColor } from "../../components/colors";
import { BLOCKS_CONTENT } from "../../data/Blocks_Data";
import { BlockComponent } from "../Home/BlockComponent";
import { SeoWrapper } from "../../components/SeoWrapper";

const AppPageContainer = styled.section.attrs({
  className: "app-page-container",
})`
  display: grid;
  grid-template-columns: 1fr;

  .locali-block,
  .ticket-block {
    .inner-block {
      .block-text {
        text-align: start;
        align-items: flex-start;
      }
    }
  }

  .locali-block {
    position: relative;
    box-shadow: 0px 8px 5px 0px ${shadowColor};
  }
  .eventi-block {
    .block-text {
      h2,
      h3 {
        color: ${primaryColor};
      }
    }
  }

  .ticket-block {
    position: relative;
    box-shadow: 0px -8px 10px 0px ${shadowColor};
  }
  @media only screen and (max-width: 645px) {
    .ticket-block {
      .inner-block {
        .block-image {
          width: 120%;
          margin-left: -2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .inner-block {
      .block-image {
        height: 180px;
      }
    }
    .scopri-block {
      .inner-block {
        .block-image {
          height: 250px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .scopri-block {
      min-height: 65vh;
    }

    .locali-block,
    .ticket-block {
      .inner-block {
        .block-image {
          background-position: right center !important;
          width: 100%;
          margin-left: auto;
        }
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .ticket-block {
      .inner-block {
        grid-template-columns: 2fr 3fr !important;

        .block-image {
          max-width: 550px;
        }
      }
    }
    .inner-block {
      .block-image {
        height: 500px !important;
      }

      h2 {
        font-size: 50px;
      }

      h3 {
        font-size: 29px;
      }
    }
  }
`;

export const AppPage = () => {
  return (
    <AppPageContainer>
      <SeoWrapper page="app" />
      <BlockComponent {...BLOCKS_CONTENT[0]} />
      <BlockComponent {...BLOCKS_CONTENT[2]} />
      <BlockComponent {...BLOCKS_CONTENT[3]} />
      <BlockComponent {...BLOCKS_CONTENT[4]} />
    </AppPageContainer>
  );
};
