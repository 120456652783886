import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "../../../../components/Button";
import { CustomCheckbox } from "../../../../components/CustomCheckBox";
import { CustomInput, validateEmail } from "../../../../components/Input";
import { Attendee, BuyTicketState } from "../../../../interfaces";
import email from "../../../../assets/images/guide/Mail.png";
import user from "../../../../assets/images/guide/user.png";
import { CustomSwitch } from "../../../../components/Switch";
import { FormControlLabel } from "@mui/material";

export type Tos = { touched: boolean; error: boolean };

const generateAttendees = (
  ticketQuantity: number,
  firstAttendee?: Attendee
): Attendee[] => {
  const attendees = !!firstAttendee ? [firstAttendee] : [];
  for (let i: number = 0; i < ticketQuantity; i++)
    attendees.push({
      first_name: "",
      last_name: "",
      email: "",
      confirm_email: "",
    });
  return attendees;
};

const areAttendeesEmpty = (attendees: Attendee[]): boolean =>
  !attendees.every(
    (attendee) =>
      attendee.first_name.length > 2 &&
      attendee.last_name.length > 2 &&
      validateEmail(attendee.email)
  );

export const Stage3 = ({
  ticketState,
  setTicketState,
}: {
  ticketState: BuyTicketState;
  setTicketState: Dispatch<SetStateAction<BuyTicketState>>;
}) => {
  const { ticketQuantity, isLoading, errorMessage } = ticketState;

  const [attendeesState, setAttendeesState] = useState<{
    attendees: Attendee[];
    tos: Tos;
  }>({
    attendees: generateAttendees(1),
    tos: { touched: false, error: true },
  });

  const handleClick = () => {
    setTicketState((prev) => ({
      ...prev,
      attendees: attendeesState.attendees.map(
        ({ first_name, last_name, email }) => ({ first_name, last_name, email })
      ),
      isLoading: true,
      isStripeInvokable: true,
      errorMessage: "",
    }));
  };

  return (
    <div className="ticket-email">
      <h1>Inserisci i dati dei partecipanti:</h1>
      <div className="attendees-container">
        {attendeesState.attendees.map((attendee, idx) => (
          <div key={idx + 210} className="email-container">
            {attendeesState.attendees.length > 1 && (
              <h1>Biglietto {idx + 1}</h1>
            )}
            <CustomInput
              name="first_name"
              label="Nome"
              icon={user}
              value={attendee.first_name}
              setValues={setAttendeesState}
              idx={idx}
            />
            <CustomInput
              name="last_name"
              label="Cognome"
              icon={user}
              value={attendee.last_name}
              setValues={setAttendeesState}
              idx={idx}
            />
            <CustomInput
              name="email"
              label="Email"
              icon={email}
              value={attendee.email}
              setValues={setAttendeesState}
              idx={idx}
            />
            <CustomInput
              name="confirm_email"
              label="Conferma Email"
              icon={email}
              value={attendee.confirm_email || ""}
              setValues={setAttendeesState}
              idx={idx}
              email={attendee.email}
            />
            {idx === 0 && ticketQuantity > 1 && (
              <FormControlLabel
                className="tickets-toggle"
                control={
                  <CustomSwitch
                    setAttendeesState={setAttendeesState}
                    ticketQuantity={ticketQuantity}
                    generateAttendees={generateAttendees}
                  />
                }
                label="Invia tutti i biglietti a me"
                labelPlacement="end"
              />
            )}
          </div>
        ))}
      </div>
      <CustomCheckbox
        id="tos"
        inputState={attendeesState.tos}
        setInputState={setAttendeesState}
      />
      <Button
        label={isLoading ? "Caricando..." : "Acquista!"}
        disabled={
          isLoading ||
          attendeesState.tos.error ||
          areAttendeesEmpty(attendeesState.attendees) ||
          attendeesState.attendees.some(
            (att) => att.email !== att.confirm_email
          )
        }
        onClick={handleClick}
      />
      {errorMessage && <p className="ticket-payment-error">{errorMessage}</p>}
    </div>
  );
};
