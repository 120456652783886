import airConI from "../assets/images/Servizi Locali/Air.png";
import kitchenI from "../assets/images/Servizi Locali/Kitchen.png";
import outdoorI from "../assets/images/Servizi Locali/Outdoor.png";
import parkingI from "../assets/images/Servizi Locali/Parking.png";
import paymentsI from "../assets/images/Servizi Locali/Payments.png";
import petI from "../assets/images/Servizi Locali/Pet.png";
import smokingI from "../assets/images/Servizi Locali/Smoking.png";
import wifiI from "../assets/images/Servizi Locali/Wifi.png";
import asyouareI from "../assets/images/Servizi Locali/Asyouare.png";
import casualI from "../assets/images/Servizi Locali/Casual.png";
import elegantI from "../assets/images/Servizi Locali/Elegant.png";

export const venue_type_schema = [
  { key: "COCKTAIL_BAR", value: "COCKTAIL_BAR", label: "Cocktail Bar" },
  { key: "DISCO", value: "DISCO", label: "Disco" },
  { key: "LIVE_CLUB", value: "LIVE_CLUB", label: "Live Club" },
  { key: "PUB", value: "PUB", label: "Pub" },
  { key: "THEATER", value: "THEATER", label: "Teatro" },
];

export const days_schema = [
  { key: "MONDAY", value: "MONDAY", label: "Lunedi" },
  { key: "TUESDAY", value: "TUESDAY", label: "Martedi" },
  { key: "WEDNESDAY", value: "WEDNESDAY", label: "Mercoledi" },
  { key: "THURSDAY", value: "THURSDAY", label: "Giovedi" },
  { key: "FRIDAY", value: "FRIDAY", label: "Venerdi" },
  { key: "SATURDAY", value: "SATURDAY", label: "Sabato" },
  { key: "SUNDAY", value: "SUNDAY", label: "Domenica" },
];

export const activities = [
  "#Commercial",
  "#Cover Band",
  "#Craft Beer",
  "#Dance",
  "#EDM",
  "#Indie",
  "#Jazz",
  "#Karaoke",
  "#Live Music",
  "#Mixology",
  "#Outdoor",
  "#Sport",
  "#Rooftop",
  "#Whiskey",
];

export const features_schema = [
  { key: "AIR_CONDITIONED", value: "AIR_CONDITIONED", img: airConI },
  { key: "HAS_KITCHEN", value: "HAS_KITCHEN", img: kitchenI },
  { key: "SMOKING_AREA", value: "SMOKING_AREA", img: smokingI },
  { key: "ACCEPTS_CARD", value: "ACCEPTS_CARD", img: paymentsI },
  { key: "FREE_PARKING", value: "FREE_PARKING", img: parkingI },
  { key: "PET_FRIENDLY", value: "PET_FRIENDLY", img: petI },
  { key: "OUTDOOR_TABLES", value: "OUTDOOR_TABLES", img: outdoorI },
  { key: "FREE_WIFI", value: "FREE_WIFI", img: wifiI },
];

export const dress_code_schema = [
  { key: "COME_AS_YOU_ARE", value: "COME_AS_YOU_ARE", img: asyouareI },
  { key: "CASUAL", value: "CASUAL", img: casualI },
  { key: "ELEGANT", value: "ELEGANT", img: elegantI },
];

export const ticket_names = [
  { key: "FREE_ENTRY", value: "FREE_ENTRY", label: "Free Entry" },
  { key: "POSTO_UNICO", value: "POSTO_UNICO", label: "Posto Unico" },
  { key: "PISTA", value: "PISTA", label: "Pista" },
  { key: "TAVOLO", value: "TAVOLO", label: "Tavolo" },
  { key: "PRIVE", value: "PRIVE", label: "Prive" },
  { key: "GALLERIA", value: "GALLERIA", label: "Galleria" },
  { key: "PLATEA", value: "PLATEA", label: "Platea" },
  { key: "POLTRONISSIMA", value: "POLTRONISSIMA", label: "Poltronissima" },
  { key: "SOTTOPALCO", value: "SOTTOPALCO", label: "Sottopalco" },
  { key: "BACKSTAGE", value: "BACKSTAGE", label: "Backstage" },
];

export const EVENT_ATMOSPHERE = [
  { key: "APERITIF", value: "APERITIF", label: "Aperitif" },
  { key: "CHILL_NIGHT", value: "CHILL_NIGHT", label: "Chill Night" },
  { key: "COVER_BAND", value: "COVER_BAND", label: "Cover Band" },
  { key: "DANCE", value: "DANCE", label: "Dance" },
  { key: "EDM", value: "EDM", label: "EDM" },
  { key: "JAZZ", value: "JAZZ", label: "Jazz" },
  { key: "KARAOKE", value: "KARAOKE", label: "Karaoke" },
  { key: "LOUNGE", value: "LOUNGE", label: "Lounge" },
  { key: "POP", value: "POP", label: "Pop" },
  { key: "ROCK", value: "ROCK", label: "Rock" },
  { key: "SPORT_NIGHT", value: "SPORT_NIGHT", label: "Sport Night" },
  { key: "STUDENTS_PARTY", value: "STUDENTS_PARTY", label: "Students Party" },
  { key: "SWING", value: "SWING", label: "Swing" },
];
