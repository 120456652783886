import styled from "styled-components";
import { BlockComponent } from "./BlockComponent";
import { Promuovi } from "./Promuovi";
import { Locali } from "./Locali";
import { Aspetti } from "./Aspetti";
import { Hero } from "./Hero";
import { BLOCKS_CONTENT } from "../../data/Blocks_Data";
import { SeoWrapper } from "../../components/SeoWrapper";

const HomePageContainer = styled.section.attrs({
  className: "homepage-container",
})`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 1280px) {
    .scopri-block,
    .promuovi-block {
      .inner-block {
        .block-image {
          height: 500px;
        }
      }
    }

    h2 {
      font-size: 50px;
    }

    h3 {
      font-size: 29px;
    }
  }
`;

export const HomePage = () => {
  return (
    <HomePageContainer>
      <SeoWrapper page="home" />
      <Hero />
      <BlockComponent {...BLOCKS_CONTENT[0]} />
      <Locali />
      <Promuovi />
      <Aspetti />
    </HomePageContainer>
  );
};
