import { Form, Formik } from "formik";
import { Button } from "../../components/Button";
import { ContactFormSchema } from "../../components/schema";
import { CustomInputField } from "../../components/CustomInputField";

const onSubmit = async (values: any, actions: any) => {
  await new Promise((resolve: any) => setTimeout(resolve, 1500));
  console.log({ values, actions });

  // actions.resetForm()
};

export const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        message: "",
      }}
      validationSchema={ContactFormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-name-fields">
            <CustomInputField label="Nome" name="firstName" />
            <CustomInputField label="Cognome" name="lastName" />
          </div>
          <div className="form-name-fields">
            <CustomInputField label="Email" name="email" />
            <CustomInputField label="Telefono (Facoltativo)" name="mobile" />
          </div>
          <CustomInputField label="Lasciaci un messaggio!" name="message" />
          <div className="contact-button-container">
            <Button type="submit" label="Invia!" disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
};
