import Box from "@mui/material/Box";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../../../components/Button";
import { Attendee, BuyTicketState, EventProps } from "../../../../interfaces";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { StripeMessageModal } from "../StripeMessageModal";
import { TicketFormHeader } from "./TicketFormHeader";
import { TicketFormBody } from "./TicketFormBody";
import { ModalStyle } from "./styles";
import { useStripeAxios } from "../../../../hooks/useStripeAxios";
import { useParams } from "react-router-dom";

const stripeKey =
  window.location.hostname !== "localhost"
    ? "pk_live_51LqM4NBC9ZF5w90zglVL1MOOfx6oVIfFRvgfV7YQYuuYglNMx1sU1kY9o7qrGYqCHFRnubPCpLyhkNZfD2zfiUZ200tX8Pcvcr"
    : "pk_test_51LqM4NBC9ZF5w90zX7vSNZmddbXEKTidpfzy6q3K2RzGzqaWynuzGYucTzIECmiJeZMAgO4YE37IYMaefPMM2HXO00XA7FtfPo";

export const stripePromise = loadStripe(stripeKey);

export const stripeStyle: {
  theme: "stripe" | "none" | "flat" | "night" | undefined;
  variables: { [key: string]: string };
} = {
  theme: "night",
  variables: {
    colorPrimary: "#890400",
    colorBackground: "#222222",
  },
};

const TICKET_INITIAL_STATE: BuyTicketState = {
  isLoading: false,
  stage: 1,
  ticketToBuy: null,
  ticketQuantity: 0,
  attendees: [],
  isStripeInvokable: false,
  errorMessage: "",
};

const populateAttendees = (
  attendee: Attendee,
  ticketQuantity: number
): Attendee[] => {
  const attendees = [];
  for (let i: number = 0; i < ticketQuantity; i++) attendees.push(attendee);
  return attendees;
};

export const BuyTickets = ({
  event,
  venueName,
  venueAddress,
  venueCover,
}: {
  event: EventProps;
  venueName: string;
  venueAddress: string;
  venueCover: string;
}) => {
  const axiosStripe = useStripeAxios();
  const params = useParams();
  const buyTicketDivRef = useRef<HTMLDivElement | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [isRedirectPage, setIsRedirectPage] = useState<boolean>(
    !!new URLSearchParams(window.location.search)?.get(
      "payment_intent_client_secret"
    )
  );
  const [ticketState, setTicketState] =
    useState<BuyTicketState>(TICKET_INITIAL_STATE);

  const [boxStyle, setBoxStyle] = useState<{
    borderTopLeftRadius: string;
    borderTopRightRadius: string;
    overflowY: "visible" | "hidden" | "clip" | "scroll" | "auto";
  }>({
    borderTopLeftRadius: ticketState.stage === 4 ? "25px" : "0",
    borderTopRightRadius: ticketState.stage === 4 ? "25px" : "0",
    overflowY: "scroll",
  });

  const stripeOptions = useMemo(
    () => ({
      clientSecret,
      appearance: stripeStyle,
    }),
    [clientSecret]
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (ticketState.stage < 3) {
      setTicketState(TICKET_INITIAL_STATE);
      setOpen(false);
    } else {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Sei sicuro di voler terminare l'acquisto di biglietti?")) {
        setTicketState(TICKET_INITIAL_STATE);
        setOpen(false);
      }
    }
  };

  const createPaymentIntent = useCallback(async () => {
    try {
      const { attendees, ticketQuantity, ticketToBuy } = ticketState;
      const reservationResponse = await axiosStripe.post("/reservations", {
        receipt_email: attendees[0].email,
        attendees:
          attendees.length === ticketQuantity
            ? attendees
            : populateAttendees(attendees[0], ticketQuantity),
        currency: "eur",
        total_tickets: ticketQuantity,
        ticket_type: ticketToBuy?.name,
        event_id: event.id,
      });

      process.env.NODE_ENV === "development" &&
        console.log(reservationResponse);

      const reservation_id = reservationResponse?.data?.data;

      if (!reservation_id) throw new Error("Errore! Riprova");

      // FREE TICKET PURCHASE
      if (ticketToBuy?.price === 0) {
        alert(
          `Acquisto avvenuto con successo! - Controlla la tua mail, lì troverai il tuo biglietto ed un QR-Code da mostrare alla cassa del locale per accedere all’evento! - Buona Serata!`
        );
        setTicketState(TICKET_INITIAL_STATE);
        window.location.reload();
        return;
      }

      const paymentIntentResponse = await axiosStripe.post(
        `/payment-intents?reservation_id=${reservation_id}`
      );

      if (!paymentIntentResponse?.data?.data?.client_secret)
        throw new Error("Errore! Riprova");

      setClientSecret(paymentIntentResponse.data.data.client_secret);
      setTicketState((prev) => ({
        ...prev,
        stage: 4,
        isLoading: false,
        isStripeInvokable: false,
      }));

      process.env.NODE_ENV === "development" &&
        console.log(paymentIntentResponse);
    } catch (err: any) {
      console.error(err);
      setTicketState((prev) => ({
        ...prev,
        isLoading: false,
        isStripeInvokable: false,
        errorMessage:
          err?.response?.message || err?.message || "Errore! Riprova",
      }));
    }
  }, [axiosStripe, event.id, ticketState]);

  useEffect(() => {
    const clientSecretInUrl = new URLSearchParams(window.location.search)?.get(
      "payment_intent_client_secret"
    );

    if (!!clientSecretInUrl) {
      setIsRedirectPage(true);
      setClientSecret(clientSecretInUrl);
    } else {
      setIsRedirectPage(false);
    }
  }, [params]);

  useEffect(() => {
    if (!ticketState.isStripeInvokable) return;

    const invokePaymentIntentCreation = async () => await createPaymentIntent();

    invokePaymentIntentCreation();
  }, [createPaymentIntent, ticketState.isStripeInvokable]);

  useEffect(() => {
    setBoxStyle({
      borderTopLeftRadius: ticketState.stage === 4 ? "25px" : "0",
      borderTopRightRadius: ticketState.stage === 4 ? "25px" : "0",
      overflowY: "scroll",
    });
  }, [ticketState.stage]);

  useEffect(() => {
    if (!buyTicketDivRef?.current) {
      return;
    }

    const scroller = () => {
      const buyTicketElement = buyTicketDivRef.current as HTMLDivElement;
      const buyTicketElementOffsetTop = buyTicketElement.offsetTop;

      const mapElement = document.querySelector(".map") as HTMLDivElement;
      const mapElementOffsetTop = mapElement.offsetTop;
      const mapElementHeight = mapElement.clientHeight;

      const isPinned =
        buyTicketElementOffsetTop >= mapElementOffsetTop + mapElementHeight;

      buyTicketElement.classList.toggle("is-pinned", !isPinned);
    };

    window.addEventListener("scroll", scroller);

    scroller();

    return () => {
      window.removeEventListener("scroll", scroller);
    };
  }, []);

  return (
    <div ref={buyTicketDivRef} className="buy-tickets">
      <Button
        label={`Acquista Bigliett${
          event?.ticket_types.filter((t) => !t.physical).length > 1 ? "i" : "o"
        }!`}
        onClick={handleOpen}
      />
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={boxStyle}>
          <TicketFormHeader
            event={event}
            ticketState={ticketState}
            venueName={venueName}
            venueCover={venueCover}
            venueAddress={venueAddress}
          />
          <TicketFormBody
            event={event}
            ticketState={ticketState}
            setTicketState={setTicketState}
            clientSecret={clientSecret}
          />
        </Box>
      </ModalStyle>
      {!!clientSecret && !!isRedirectPage && (
        <Elements options={stripeOptions} stripe={stripePromise}>
          <StripeMessageModal clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};
