import styled from "styled-components";
import { Button } from "../../../components/Button";
import { BannerProps } from "../data";

const BannerStyle = styled.div`
  display: flex;
  background: ${(props) => props.datatype};

  .inner-block {
    max-width: 1280px;
    margin: auto;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    gap: 1rem;

    .text {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      height: 100%;
      justify-content: center;
      align-items: flex-start;

      h2 {
        font-size: 16px;
      }

      h3 {
        font-size: 0.9em;
        font-family: Lato-Thin;
      }

      button {
        padding-inline: 1rem;
        span {
          font-size: 13px;
        }
      }
    }

    .image {
      width: 50%;
      max-width: 700px;
      height: 160px;
      margin-right: ${(props) => (props.defaultChecked ? "0" : "-.5rem")};
      img {
        max-width: 100%;
        float: right;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding: ${(props) =>
      props.defaultChecked ? "3rem!important" : "2rem 3rem"};
    .inner-block {
      .text {
        h2 {
          font-size: 30px;
        }
        h3 {
          font-size: 23px;
        }
        button {
          padding-inline: 2rem;
          span {
            font-size: 20px;
          }
        }
      }

      .image {
        height: 320px;
        width: 70%;

        img {
          height: ${(props) => (props.defaultChecked ? "130%" : "100%")};
          margin-top: ${(props) => (props.defaultChecked ? "-2.5rem" : "0")};
        }
      }
    }
  }
`;

export const GuidaPageBanner = ({
  image,
  title,
  description,
  background,
  button,
  largeImage,
}: BannerProps) => {
  return (
    <BannerStyle datatype={background} defaultChecked={largeImage}>
      <div className="inner-block">
        <div className="text">
          <div className="title">
            {title.map((t) => (
              <h2 key={t.substring(0, 4)}>{t}</h2>
            ))}
          </div>
          <div className="description">
            {description.map((d) => (
              <h3 key={d.substring(0, 4)}>{d}</h3>
            ))}
          </div>
          {button && <Button label="Iscrivi Locale" />}
        </div>
        <div className="image">
          <img src={image} alt="banner" />
        </div>
      </div>
    </BannerStyle>
  );
};
