import styled from "styled-components";
import hero from "../../assets/images/hero-hp.png";
import { Button } from "../../components/Button";

const HeroStyle = styled.div.attrs({ className: "hero-block" })`
  display: flex;

  position: relative;
  padding: 6rem 1rem;
  min-height: 55vh;
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    filter: brightness(0.7);
    background: url(${hero});
    background-size: 320% 200%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .inner-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    position: relative;
    .hero-text {
      position: relative;
      z-index: 1;
      h1 {
        margin-bottom: 0.5rem;
        font-size: 33px;
      }
      p {
        margin: 0;
        font-family: Lato-Light;
        font-size: 18px;
      }
      .first-p {
        margin-top: 1rem;
      }
    }

    .hero-buttons {
      display: flex;
      gap: 1rem;
      position: relative;
      z-index: 1;

      button {
        padding-inline: 1.5rem;
        span {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding-inline: 3rem;

    .inner-block {
      .hero-text {
        h1 {
          font-size: 55px;
        }
        p {
          font-size: 22px;
        }
      }

      .hero-buttons {
        gap: 2rem;
        button {
          span {
            font-size: 22px;
          }
        }
      }
    }
    .hero-image {
      background-size: 130% 130%;
    }
  }
  @media only screen and (min-width: 1280px) {
    .inner-block {
      .hero-text {
        h1 {
          font-size: 65px;
        }
        p {
          font-size: 29px;
        }
      }
    }
  }
`;
export const Hero = () => {
  return (
    <HeroStyle>
      <div className="hero-image" />
      <div className="inner-block">
        <div className="hero-text">
          <h1>OTTIENI</h1>
          <h1>IL MASSIMO</h1>
          <h1>DALLA TUA SERATA</h1>
          <p className="first-p">
            Trova i migliori locali ed eventi intorno a te,
          </p>
          <p>Acquista biglietti in modo semplice e veloce!</p>
        </div>
        <div className="hero-buttons">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://onelink.to/nightcitizen"
          >
            <Button label="Scarica App" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nightcitizenmanager.com"
          >
            <Button label="Iscrivi Locale" />
          </a>
        </div>
      </div>
    </HeroStyle>
  );
};
