import styled from "styled-components";

const ExperienceStyle = styled.div.attrs({ className: "experience-block" })`
  padding: 2rem 1rem;
  background: linear-gradient(
    161deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(101, 1, 14, 1) 100%
  );

  p {
    text-align: justify;
    font-family: Lato-Light;
  }
  h2 {
    font-size: 21px;
  }

  @media only screen and (min-width: 768px) {
    padding: 5rem 3rem;
    h2 {
      font-size: 32px;
    }
    p {
      font-size: 25px;
      line-height: 2.5rem;
    }
  }
`;
export const Experience = () => {
  return (
    <ExperienceStyle>
      <div className="inner-block">
        <h2>Our Experience is the Key!</h2>
        <p>
          Night Citizen App è un software, in stile "TheFork", sviluppato da un
          dinamico team di giovani ragazzi che mira a cambiare le regole del
          gioco della vita notturna. Il software realizzato è il risultato di
          una lunga e accurata ricerca e del raggiungimento di diversi anni di
          esperienza del team.
        </p>
        <p>
          Il forte know-how acquisito in diversi anni di lavoro nell'ambiente
          della vita notturna, in una prospettiva internazionale, ha spinto i
          founders a realizzare uno strumento in grado di svolgere allo stesso
          tempo il ruolo di fondamentale fonte di informazioni per i “cittadini
          della notte”; consentendo agli utenti di trovare cosa fare nelle loro
          serate e fornire la loro voglia di divertimento in modo veloce, facile
          e dinamico.
        </p>
        <p>
          La app è allo stesso tempo però, una fondamentale risorsa per gli
          imprenditori della movida, un veicolo di promozione per le loro
          location ed un'intuitiva ed accurata fonte di analisi dell'esperienza
          e delle aspettative dei clienti.
        </p>
      </div>
    </ExperienceStyle>
  );
};
