import * as yup from "yup";

export const ContactFormSchema = yup.object().shape({
  given_name: yup
    .string()
    .min(3, "Il Nome deve essere almeno 3 caratteri")
    .required("Il Nome deve essere almeno 3 caratteri"),
  family_name: yup
    .string()
    .min(3, "Il Cognome deve essere almeno 3 caratteri")
    .required("Il Cognome deve essere almeno 3 caratteri"),
  email: yup
    .string()
    .email("Prego inserisci una email valida")
    .required("Prego inserisci una email valida"),
  phone_number: yup.string().min(7, "Il Numero deve essere almeno 7 cifre"),
  message: yup
    .string()
    .min(10, "Il messaggio deve essere almeno 10 caratteri")
    .required("Il messaggio deve essere almeno 10 caratteri"),
});
