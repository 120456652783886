import styled from "styled-components";
import {
  secondaryColor,
  buttonColor,
  buttonHoverColor,
} from "../../../components/colors";

const BodyStyle = styled.div`
  padding: 2rem;
  max-width: 1280px;
  margin: auto;

  ul {
    margin-inline-start: 2rem;
    padding-inline-start: 0;
    list-style: disc;
    color: white;
  }
  p,
  font,
  b,
  span {
    color: ${secondaryColor};
  }

  span.header-title {
    font-size: 23px;
  }

  a,
  u {
    color: ${buttonColor} !important;
    text-decoration: underline;
    &:hover {
      color: ${buttonHoverColor} !important;
    }
  }

  b {
    font-family: Lato-Regular;
  }
  font {
    font-family: Lato-Light;
  }

  @media only screen and (max-width: 767px) {
    .privacy-wrapper {
      p {
        padding-left: 0 !important;
        margin-left: 0 !important;
      }
      ul {
        margin-inline-start: 1rem !important;
        padding-inline-start: 0;
      }
    }

    .inner-text {
      font-size: 13px !important;
      font {
        font-size: 13px !important;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    span.header-title {
      font-size: revert;
    }
  }
`;

export const AssistenzaBody = ({ data }: { data: string }) => {
  return (
    <BodyStyle className="body" dangerouslySetInnerHTML={{ __html: data }} />
  );
};
