import { ticket_names } from "../../../../data/Locale_Data";
import { BuyTicketState, EventProps, VenueProps } from "../../../../interfaces";
import { parseTime } from "../../../../utils";
import { TimerToBuyTicket } from "./TimerToBuyTicket";

interface FormHeader {
  event: EventProps;
  venueName: VenueProps["name"];
  venueCover: VenueProps["cover"];
  venueAddress: VenueProps["address"];
  ticketState: BuyTicketState;
}

export const TicketFormHeader = ({
  event,
  venueName,
  venueCover,
  venueAddress,
  ticketState,
}: FormHeader) => {
  const { stage, ticketQuantity, ticketToBuy } = ticketState;

  return stage < 4 ? (
    // TICKET HEADER BEFORE THE STRIPE FORM
    <>
      <div className="cover">
        <img src={event.cover_location || venueCover} alt="event-cover" />
      </div>
      <div className="description">
        <div className="title">
          <h2>{event.name}</h2>
        </div>
        <div className="details">
          <h3>{venueName}</h3>
          <p>{venueAddress}</p>
          <h3>
            {"H"}
            {parseTime(event.start_time)}
          </h3>
          {stage === 2 && (
            <h2>{`${
              ticket_names.find(({ value }) => value === ticketToBuy?.name)
                ?.label || ticketToBuy?.name
            } - ${((ticketToBuy?.price || 0) / 100).toFixed(2)}€`}</h2>
          )}
          {stage > 2 && ticketToBuy && (
            <h2>{`${ticketQuantity} Bigliett${ticketQuantity > 1 ? "i" : "o"} ${
              ticket_names.find(({ value }) => value === ticketToBuy?.name)
                ?.label || ticketToBuy?.name
            } - ${(((ticketToBuy?.price || 0) * ticketQuantity) / 100).toFixed(
              2
            )}€`}</h2>
          )}
        </div>
      </div>
    </>
  ) : (
    // TICKET HEADER IN STRIPE FORM
    <div className="buy-ticket-header">
      <h2>{event.name}</h2>
      <h2>{`€ ${(((ticketToBuy?.price || 0) * ticketQuantity) / 100).toFixed(
        2
      )}`}</h2>
      <TimerToBuyTicket />
    </div>
  );
};
