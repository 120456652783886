import { Dispatch, SetStateAction, useCallback } from "react";
import { Button } from "../../../../components/Button";
import { CustomSelectField } from "../../../../components/Select";
import { BuyTicketState } from "../../../../interfaces";

const ticketQuantityModel = [
  {
    key: "a",
    value: 1,
    label: 1,
  },
  {
    key: "b",
    value: 2,
    label: 2,
  },
  {
    key: "c",
    value: 3,
    label: 3,
  },
  {
    key: "d",
    value: 4,
    label: 4,
  },
];

export const Stage2 = ({
  ticketQuantity,
  setTicketState,
  ticketToBuy,
}: {
  ticketToBuy: BuyTicketState["ticketToBuy"];
  ticketQuantity: BuyTicketState["ticketQuantity"];
  setTicketState: Dispatch<SetStateAction<BuyTicketState>>;
}) => {
  const handleClick = useCallback(() => {
    setTicketState((prev) => ({ ...prev, stage: 3 }));
  }, [setTicketState]);

  const { available } = ticketToBuy || {};

  const slicer = !available || available > 3 ? 4 : available;

  return (
    <div className="ticket-number">
      <h1>Numero Biglietti</h1>
      <CustomSelectField
        options={ticketQuantityModel.slice(0, slicer)}
        value={ticketQuantity || ""}
        setTicketState={setTicketState}
        name="ticket-quantity"
      />
      <Button label="Avanza" disabled={!ticketQuantity} onClick={handleClick} />
    </div>
  );
};
