import mapI from "../../../assets/images/Details 170x170/Mappa.png";
import mapPlaceholder from "../../../assets/images/map-placeholder.jpg";

export const Map = ({
  name,
  address,
  city,
  country,
}: {
  name: string;
  address: string;
  city: string;
  country: string;
}) => {
  return (
    <div className="map">
      <div className="image-placeholder">
        <div className="overlay"></div>
        <img src={mapPlaceholder} alt="map placeholder" />
      </div>
      <a
        href={`https://www.google.com/maps/place/${address}+${city}+${country}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className="header">
          <div className="image">
            <img src={mapI} alt="mappa-logo" />
          </div>
          <div className="description">
            <h2>{name}</h2>
            <h3>{address}</h3>
          </div>
        </div>
      </a>
    </div>
  );
};
