import { AssistenzaPageContainer } from "./components/AssistenzaPageContainer";
import { AssistenzaHero } from "./components/AssistenzaHero";
import { AssistenzaBody } from "./components/AssistenzaBody";
import { privacy } from "./data/privacyData";

export const PrivacyPolicyPage = () => {
  return (
    <AssistenzaPageContainer className="privacy-section">
      <AssistenzaHero title="PRIVACY POLICY" />
      <AssistenzaBody data={privacy} />
    </AssistenzaPageContainer>
  );
};
