import { useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

const COUNTDOWN_TIME = 1000 * 60 * 4; // 4min

export const TimerToBuyTicket = () => {
  const [countdown, setCountdown] = useState(Date.now() + COUNTDOWN_TIME);

  const renderer = ({ completed, formatted }: CountdownRenderProps) => {
    if (completed) {
      alert("Sessione terminata.");
      window.location.reload();
      return "";
    } else {
      return (
        <span className="countdown">
          Acquista entro{" "}
          <span
            className={`inner${parseInt(formatted.minutes) < 1 ? " low" : ""}`}
          >
            {formatted.minutes}:{formatted.seconds}
          </span>
        </span>
      );
    }
  };

  return <Countdown date={countdown} renderer={renderer} />;
};
