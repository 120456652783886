import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import axios from "../../../api/axios";
import { Chevron } from "../../../components/Chevron";
import { EventProps, VenueProps } from "../../../interfaces";
import { orderEventsByDate, removePastEvents } from "../../../utils";
import { EventBadge } from "../EventBadge";
import { CONTENT_STATUS_ACTIVE } from "..";

export const Events = ({
  pageType,
  venue,
  event,
}: {
  pageType: "Event" | "Venue";
  venue: VenueProps;
  event?: EventProps;
}) => {
  const [loadingEvents, setLoadingEvents] = useState<boolean>(true);
  const [errorEvents, setErrorEvents] = useState<string>("");
  const [scrollLeft, setScrollLeft] = useState(0);
  const [events, setEvents] = useState<EventProps[] | null>(null);
  const eventsContainerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const fetchEventsbyVenue = async () => {
      try {
        const response = await axios.get(
          `/events?venue_id=${venue.id}&${CONTENT_STATUS_ACTIVE}`
        );

        const {
          data: { data },
        } = response;

        process.env.NODE_ENV === "development" && console.log(data);
        if (!data) throw new Error("Errore! Eventi non caricati.");
        if (!data?.length) setErrorEvents("Nessun Evento.");
        const parsedEvents = orderEventsByDate(removePastEvents(data));
        if (!parsedEvents?.length) setErrorEvents("Nessun Evento.");

        if (pageType === "Venue") {
          parsedEvents?.length ? setEvents(parsedEvents) : setEvents(null);
          parsedEvents?.length && setErrorEvents("");
        } else {
          if (parsedEvents?.length === 1) {
            setEvents(null);
            setErrorEvents("Nessun Altro Evento.");
          } else if (parsedEvents?.length > 1) {
            const filteredEvents = parsedEvents.filter(
              (ev: EventProps) => ev.id !== event?.id
            );
            setEvents(filteredEvents);
            setErrorEvents("");
          }
        }
      } catch (err: any) {
        setErrorEvents(
          err?.response?.data?.message ||
            err?.message ||
            "Errore! Eventi non caricati."
        );
        setEvents(null);
        console.error(err);
      } finally {
        setLoadingEvents(false);
      }
    };

    fetchEventsbyVenue();
  }, [event?.id, pageType, venue.id]);

  useEffect(() => {
    !!events?.length &&
      setScrollLeft(
        (eventsContainerRef.current as HTMLDivElement)?.scrollWidth -
          (eventsContainerRef.current as HTMLDivElement)?.offsetWidth
      );
  }, [events]);

  const handleDrag = (e: any) => {
    if (eventsContainerRef.current)
      Array.from(eventsContainerRef.current?.children).forEach(
        (a) => ((a as HTMLAnchorElement).style.pointerEvents = "none")
      );
  };

  const handleDragEnd = (e: any) => {
    if (eventsContainerRef.current)
      Array.from(eventsContainerRef.current?.children).forEach(
        (a) => ((a as HTMLAnchorElement).style.pointerEvents = "unset")
      );
  };

  const handleChevron = (e: any) => {
    const { id } = e.target;
    if (eventsContainerRef.current) {
      const px = parseInt(
        eventsContainerRef.current.style.transform
          ?.split("translateX(")[1]
          ?.split("px")[0]
      );
      if (id === "left") {
        if (!eventsContainerRef.current.style.transform || px >= 48) {
          return;
        }
        eventsContainerRef.current.style.transform = `translateX(${
          px < -148 ? px + 400 : 48
        }px)`;
      } else if (id === "right") {
        if (
          (eventsContainerRef.current as HTMLDivElement).scrollWidth >
            (eventsContainerRef.current as HTMLDivElement).offsetWidth &&
          px > -scrollLeft
        ) {
          eventsContainerRef.current.style.transform = `translateX(${
            px - 100 < -scrollLeft ? -scrollLeft : px - 400
          }px)`;
        }
      }
    }
  };

  return (
    <div className="events section">
      <div className="header">
        <h2>{pageType === "Venue" ? "EVENTI" : "ALTRI EVENTI"}</h2>
      </div>
      <div className="body">
        {scrollLeft > 0 && (
          <>
            <Chevron id="left" onClick={handleChevron} />
            <Chevron id="right" onClick={handleChevron} />
          </>
        )}
        {loadingEvents || errorEvents ? (
          <div className="loading-events">
            {loadingEvents ? "Loading..." : errorEvents}
          </div>
        ) : (
          !!events?.length && (
            <motion.div
              onDrag={handleDrag}
              onDragEnd={handleDragEnd}
              drag="x"
              dragConstraints={{
                right: 48,
                left: -scrollLeft,
              }}
              className="events-badges-wrapper"
              ref={eventsContainerRef}
            >
              {events.map((event) => (
                <a
                  key={event.id}
                  className="event-href"
                  href={`/event/${event.id}`}
                >
                  <EventBadge event={event} venue={venue} />
                </a>
              ))}
            </motion.div>
          )
        )}
      </div>
    </div>
  );
};
