import styled from "styled-components";
import liveclub from "../../assets/images/categorie/LiveClub.png";
import cocktailbar from "../../assets/images/categorie/CocktailBar.png";
import disco from "../../assets/images/categorie/Disco.png";
import pub from "../../assets/images/categorie/Pub.png";
import theatre from "../../assets/images/categorie/Theatre.png";
import { primaryColor } from "../../components/colors";

const LocaliStyle = styled.div.attrs({ className: "locali-block" })`
  background: ${primaryColor};
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 1rem;
  h2 {
    font-family: Lato-Light;
  }

  .inner-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    max-width: 1280px;

    .locali-body {
      display: flex;
      justify-content: space-between;

      .locale {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        &.cocktail-bar span {
          padding-left: 0.2rem;
        }

        span {
          font-size: 12px;
        }

        .locale-image {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding-inline: 3rem;
    min-height: 300px;
    .inner-block {
      height: 100%;
      align-items: center;
      .locali-body {
        .locale {
          .locale-image {
            width: 70px;
            height: 70px;
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
  // @media only screen and (min-width: 1280px) {
  //   padding-inline: 10rem;
  // }
`;

const LOCALI_TYPE = [
  {
    label: "Live Club",
    image: liveclub,
    key: "live-club",
  },
  {
    label: "Cocktail Bar",
    image: cocktailbar,
    key: "cocktail-bar",
  },
  {
    label: "Pub",
    image: pub,
    key: "pub",
  },
  {
    label: "Disco",
    image: disco,
    key: "disco",
  },
  {
    label: "Theatre",
    image: theatre,
    key: "theatre",
  },
];

export const Locali = () => {
  return (
    <LocaliStyle>
      <div className="inner-block">
        <div className="locali-header">
          <h2>Tantissimi locali ti aspettano, scegli tra:</h2>
        </div>
        <div className="locali-body">
          {LOCALI_TYPE.map(({ label, image, key }) => (
            <div className={["locale", key].join(" ")} key={key}>
              <div
                className="locale-image"
                style={{
                  background: `url(${image}) no-repeat center center / 100% 100%`,
                }}
              ></div>
              <span>{label}</span>
            </div>
          ))}
        </div>
      </div>
    </LocaliStyle>
  );
};
