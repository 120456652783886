export const privacy = `<div class='privacy-wrapper'><p style="margin-bottom: 0.21cm; line-height: 0.51cm">
<font color="white"><font face="Lato-Regular, serif"><font size="6" style="font-size: 23pt"><span lang="en-US"><b>Privacy
Policy di <span class='header-title'>www.nightcitizenapp.com</span></b></span></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questa
Applicazione raccoglie alcuni Dati Personali dei propri Utenti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
documento può essere stampato utilizzando il comando di stampa
presente nelle impostazioni di qualsiasi browser.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Riassunto
della policy</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Dati
Personali trattati per le seguenti finalità e utilizzando i seguenti
servizi:</b></font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Accesso
		agli account su servizi terzi</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Accesso
			all'account Facebook</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Permessi:
Data di compleanno; Email</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Gestione
		dei pagamenti</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Stripe</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: cognome; cronologia acquisti; email; informazioni di
pagamento; nome; Strumenti di Tracciamento</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Gestione
		dei tag</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Google
			Tag Manager</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Dati di utilizzo; Strumenti di Tracciamento</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Pubblicità</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>JSDELIVR
			e 152 Media</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Dati di utilizzo; Strumenti di Tracciamento</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Registrazione
		ed autenticazione</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Facebook
			Authentication</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Strumenti di Tracciamento; varie tipologie di Dati secondo
quanto specificato dalla privacy policy del servizio</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Remarketing
		e behavioral targeting</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Pubblico
			personalizzato di Outbrain (Custom Audience)</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p align="justify" style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Dati di utilizzo; Identificativo univoco universale
(UUID); informazioni sul dispositivo; Strumenti di Tracciamento</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Statistica</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Google
			Analytics</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p align="justify" style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Dati di utilizzo; Strumento di Tracciamento</font></font></font></p>
<ul>
	<ul>
		<ul>
			<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
			<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Monitoraggio
			conversioni di Facebook Ads (pixel di Facebook)</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Dati di utilizzo; Strumenti di Tracciamento</font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Visualizzazione
		di contenuti da piattaforme esterne</b></font></font></font></p>
		<ul>
			<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Google
			Fonts</b></font></font></font></p>
		</ul>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali: Dati di utilizzo; Strumenti di Tracciamento</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Informazioni
su come disattivare gli annunci pubblicitari basati sugli interessi</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Oltre
a qualsiasi funzione di opt-out fornita da uno qualsiasi dei servizi
elencati in questo documento, gli Utenti possono leggere di più su
come disattivare gli annunci pubblicitari basati sugli interessi
nell'apposita sezione della Cookie Policy.&nbsp;</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Informazioni
di contatto</b></font></font></font></p>
<ul>
	<ul>
		<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
		<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Titolare
		del Trattamento dei Dati</b></font></font></font></p>
	</ul>
</ul>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Night
Citizen srls&nbsp;<br/>
Via Appia Nuova, 596&nbsp;<br/>
00179 Roma</font></font></font></p>
<p style="margin-left: 6rem; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Indirizzo
email del Titolare:</b></font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;support@nightcitizenapp.com</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Policy
completa</b></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Titolare
del Trattamento dei Dati&nbsp;</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Night
Citizen srls<br/>
Via Appia Nuova, 596&nbsp;<br/>
00179 Roma</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Indirizzo
email del Titolare:</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">support@nightcitizenapp.com</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Tipologie
di Dati raccolti&nbsp;</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Fra
i Dati Personali raccolti da questa Applicazione, in modo autonomo o
tramite terze parti, ci sono: Strumento di Tracciamento; Dati di
utilizzo; informazioni di pagamento; nome; cognome; email; cronologia
acquisti; informazioni sul dispositivo; Identificativo univoco
universale (UUID).&nbsp;</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dettagli
completi su ciascuna tipologia di dati raccolti sono forniti nelle
sezioni dedicate di questa privacy policy o mediante specifici testi
informativi visualizzati prima della raccolta dei dati stessi.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Dati Personali possono essere liberamente forniti dall'Utente o, nel
caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di
questa Applicazione.<br/>
Se non diversamente specificato, tutti i
Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente
rifiuta di comunicarli, potrebbe essere impossibile per questa
Applicazione fornire il Servizio. Nei casi in cui questa Applicazione
indichi alcuni Dati come facoltativi, gli Utenti sono liberi di
astenersi dal comunicare tali Dati, senza che ciò abbia alcuna
conseguenza sulla disponibilità del Servizio o sulla sua
operatività.<br/>
Gli Utenti che dovessero avere dubbi su quali Dati
siano obbligatori, sono incoraggiati a contattare il Titolare.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’eventuale
utilizzo di Cookie - o di altri strumenti di tracciamento - da parte
di questa Applicazione o dei titolari dei servizi terzi utilizzati da
questa Applicazione, ove non diversamente precisato, ha la finalità
di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori
finalità descritte nel presente documento e nella Cookie Policy, se
disponibile.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L'Utente
si assume la responsabilità dei Dati Personali di terzi ottenuti,
pubblicati o condivisi mediante questa Applicazione e garantisce di
avere il diritto di comunicarli o diffonderli, liberando il Titolare
da qualsiasi responsabilità verso terzi.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Modalità
e luogo del trattamento dei Dati raccolti</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Modalità
di trattamento</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare adotta le opportune misure di sicurezza volte ad impedire
l’accesso, la divulgazione, la modifica o la distruzione non
autorizzate dei Dati Personali.&nbsp;<br/>
Il trattamento viene
effettuato mediante strumenti informatici e/o telematici, con
modalità organizzative e con logiche strettamente correlate alle
finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero
avere accesso ai Dati altri soggetti coinvolti nell’organizzazione
di questa Applicazione (personale amministrativo, commerciale,
marketing, legali, amministratori di sistema) ovvero soggetti esterni
(come fornitori di servizi tecnici terzi, corrieri postali, hosting
provider, società informatiche, agenzie di comunicazione) nominati
anche, se necessario, Responsabili del Trattamento da parte del
Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere
richiesto al Titolare del Trattamento.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Base
giuridica del trattamento</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare tratta Dati Personali relativi all’Utente in caso sussista
una delle seguenti condizioni:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">l’Utente
	ha prestato il consenso per una o più finalità specifiche; Nota:
	in alcuni ordinamenti il Titolare può essere autorizzato a trattare
	Dati Personali senza che debba sussistere il consenso dell’Utente
	o un’altra delle basi giuridiche specificate di seguito, fino a
	quando l’Utente non si opponga (“opt-out”) a tale trattamento.
	Ciò non è tuttavia applicabile qualora il trattamento di Dati
	Personali sia regolato dalla legislazione europea in materia di
	protezione dei Dati Personali;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">il
	trattamento è necessario all'esecuzione di un contratto con
	l’Utente e/o all'esecuzione di misure precontrattuali;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">il
	trattamento è necessario per adempiere un obbligo legale al quale è
	soggetto il Titolare;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">il
	trattamento è necessario per l'esecuzione di un compito di
	interesse pubblico o per l'esercizio di pubblici poteri di cui è
	investito il Titolare;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">il
	trattamento è necessario per il perseguimento del legittimo
	interesse del Titolare o di terzi.</font></font></font></p>
</ul>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">È
comunque sempre possibile richiedere al Titolare di chiarire la
concreta base giuridica di ciascun trattamento ed in particolare di
specificare se il trattamento sia basato sulla legge, previsto da un
contratto o necessario per concludere un contratto.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Luogo</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Dati sono trattati presso le sedi operative del Titolare ed in ogni
altro luogo in cui le parti coinvolte nel trattamento siano
localizzate. Per ulteriori informazioni, contatta il Titolare.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Dati Personali dell’Utente potrebbero essere trasferiti in un paese
diverso da quello in cui l’Utente si trova. Per ottenere ulteriori
informazioni sul luogo del trattamento l’Utente può fare
riferimento alla sezione relativa ai dettagli sul trattamento dei
Dati Personali.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
ha diritto a ottenere informazioni in merito alla base giuridica del
trasferimento di Dati al di fuori dell’Unione Europea o ad
un’organizzazione internazionale di diritto internazionale pubblico
o costituita da due o più paesi, come ad esempio l’ONU, nonché in
merito alle misure di sicurezza adottate dal Titolare per proteggere
i Dati.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt"><br/>
L’Utente
può verificare se abbia luogo uno dei trasferimenti appena descritti
esaminando la sezione di questo documento relativa ai dettagli sul
trattamento di Dati Personali o chiedere informazioni al Titolare
contattandolo agli estremi riportati in apertura.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Periodo
di conservazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Dati sono trattati e conservati per il tempo richiesto dalle finalità
per le quali sono stati raccolti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Pertanto:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
	Dati Personali raccolti per scopi collegati all’esecuzione di un
	contratto tra il Titolare e l’Utente saranno trattenuti sino a
	quando sia completata l’esecuzione di tale contratto.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
	Dati Personali raccolti per finalità riconducibili all’interesse
	legittimo del Titolare saranno trattenuti sino al soddisfacimento di
	tale interesse. L’Utente può ottenere ulteriori informazioni in
	merito all’interesse legittimo perseguito dal Titolare nelle
	relative sezioni di questo documento o contattando il Titolare.</font></font></font></p>
</ul>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Quando
il trattamento è basato sul consenso dell’Utente, il Titolare può
conservare i Dati Personali più a lungo sino a quando detto consenso
non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a
conservare i Dati Personali per un periodo più lungo in ottemperanza
ad un obbligo di legge o per ordine di un’autorità.<br/>
<br/>
Al
termine del periodo di conservazione i Dati Personali saranno
cancellati. Pertanto, allo spirare di tale termine il diritto di
accesso, cancellazione, rettificazione ed il diritto alla portabilità
dei Dati non potranno più essere esercitati.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Finalità
del Trattamento dei Dati raccolti</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Dati dell’Utente sono raccolti per consentire al Titolare di
fornire il Servizio, adempiere agli obblighi di legge, rispondere a
richieste o azioni esecutive, tutelare i propri diritti ed interessi
(o quelli di Utenti o di terze parti), individuare eventuali attività
dolose o fraudolente, nonché per le seguenti finalità: Statistica,
Accesso agli account su servizi terzi, Gestione dei tag, Gestione dei
pagamenti, Visualizzazione di contenuti da piattaforme esterne,
Pubblicità, Remarketing e behavioral targeting e Registrazione ed
autenticazione.&nbsp;</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
ottenere informazioni dettagliate sulle finalità del trattamento e
sui Dati Personali trattati per ciascuna finalità, l’Utente può
fare riferimento alla sezione “Dettagli sul trattamento dei Dati
Personali”.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Permessi
Facebook richiesti da questa Applicazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questa
Applicazione può richiedere alcuni permessi Facebook che le
consentono di eseguire azioni con l’account Facebook dell’Utente
e di raccogliere informazioni, inclusi Dati Personali, da esso.
Questo servizio permette a questa Applicazione di connettersi con
l'account dell'Utente sul social network Facebook, fornito da
Facebook Inc.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
maggiori informazioni sui permessi che seguono, fai riferimento
alla&nbsp;</font></font></font><a href="https://developers.facebook.com/docs/facebook-login/permissions"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">documentazione
dei permessi Facebook</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;ed
alla&nbsp;</font></font></font><a href="https://www.facebook.com/about/privacy/"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">privacy
policy di Facebook</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
permessi richiesti sono i seguenti:</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Informazioni
di base</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Le
informazioni di base dell’Utente registrato su Facebook che
normalmente includono i seguenti Dati: id, nome, immagine, genere e
lingua di localizzazione ed, in alcuni casi gli “Amici” di
Facebook. Se l'Utente ha reso disponibili pubblicamente Dati
ulteriori, gli stessi saranno disponibili.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Data
di compleanno</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Thin, serif"><font size="4" style="font-size: 13pt">Fornisce
accesso alla data di compleanno.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Email</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Fornisce
accesso all'indirizzo email primario dell'Utente.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Dettagli
sul trattamento dei Dati Personali</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Thin, serif"><font size="4" style="font-size: 13pt">I
Dati Personali sono raccolti per le seguenti finalità ed utilizzando
i seguenti servizi:</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Accesso
	agli account su servizi terzi</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
tipo di servizi permettono a questa Applicazione di prelevare Dati
dai tuoi account su servizi terzi ed eseguire azioni con essi.<br/>
Questi
servizi non sono attivati automaticamente, ma richiedono l'espressa
autorizzazione dell'Utente.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Accesso
all'account Facebook (Meta Platforms, Inc.)</b></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
servizio permette a questa Applicazione di connettersi con l'account
dell'Utente sul social network Facebook, fornito da Facebook, Inc.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Permessi
richiesti: Data di compleanno; Email.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://www.facebook.com/policy.php" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Gestione
	dei pagamenti</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Se
non diversamente specificato, questa Applicazione elabora tutti i
pagamenti con carta di credito, bonifico bancario o altri mezzi
tramite fornitori esterni di servizi di pagamento. In generale, e
salvo diversa indicazione, gli Utenti sono pregati di fornire i
dettagli di pagamento e le informazioni personali direttamente a tali
fornitori di servizi di pagamento.&nbsp;<br/>
Questa Applicazione non
è coinvolta nella raccolta e nell'elaborazione di tali informazioni:
riceverà invece solo una notifica da parte del fornitore di servizi
di pagamento in questione circa l'avvenuto pagamento.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><span lang="en-US"><b>Stripe
( Stripe Technology Europe Ltd)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Stripe
è un servizio di pagamento fornito da Stripe Technology Europe Ltd.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: cognome; cronologia acquisti; email; informazioni
di pagamento; nome; Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://stripe.com/ie/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Gestione
	dei tag</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
tipo di servizi è funzionale alla gestione centralizzata dei tag o
script utilizzati su questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L'uso
di tali servizi comporta il fluire dei Dati dell'Utente attraverso
gli stessi e, se del caso, la loro ritenzione.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><span lang="en-US"><b>Google
Tag Manager (Google Ireland Limited)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Google
Tag Manager è un servizio di gestione dei tag fornito da Google
Ireland Limited.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Pubblicità</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
tipo di servizi consente di utilizzare i Dati dell’Utente per
finalità di comunicazione commerciale. Queste comunicazioni sono
mostrate su questa Applicazione sotto forma di banner e altre forme
pubblicitarie, anche in relazione agli interessi dell’Utente.&nbsp;</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ciò
non significa che tutti i Dati Personali vengano utilizzati per
questa finalità. Dati e condizioni di utilizzo sono indicati di
seguito.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Alcuni
dei servizi di seguito indicati potrebbero utilizzare Strumenti di
Tracciamento per identificare l’Utente o utilizzare la tecnica del
behavioral retargeting, ossia visualizzare annunci pubblicitari
personalizzati in base agli interessi e al comportamento dell’Utente,
rilevati anche al di fuori di questa Applicazione. Per avere maggiori
informazioni in merito, ti suggeriamo di verificare le informative
privacy dei rispettivi servizi.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Generalmente
i servizi di questo tipo offrono la possibilità di disattivare tale
tracciamento. Oltre a qualsiasi funzione di opt-out fornita da uno
qualsiasi dei servizi elencati in questo documento, l’Utente può
leggere di più su come disattivare gli annunci pubblicitari basati
sugli interessi nell'apposita sezione &quot;Come disattivare la
pubblicità basata sugli interessi&quot; in questo documento.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>JSDELIVR
(jsdelivr.com)</b></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">JSDELIVR
è un servizio di advertising fornito da jsdelivr.com.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>152
Media (152 Media LLC)</b></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">152
Media è un servizio di advertising fornito da 152 Media LLC.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://152media.com/privacy-policy/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Registrazione
	ed autenticazione</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Con
la registrazione o l’autenticazione l’Utente consente a questa
Applicazione di identificarlo e di dargli accesso a servizi
dedicati.<br/>
A seconda di quanto indicato di seguito, i servizi di
registrazione e di autenticazione potrebbero essere forniti con
l’ausilio di terze parti. Qualora questo avvenga, questa
Applicazione potrà accedere ad alcuni Dati conservati dal servizio
terzo usato per la registrazione o l’identificazione.&nbsp;<br/>
Alcuni
dei servizi di seguito indicati potrebbero raccogliere Dati Personali
anche per fini di targeting e profilazione; per saperne di più, si
prega di fare riferimento alla descrizione di ciascun servizio.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><span lang="en-US"><b>Facebook
Authentication (Meta Platforms Ireland Limited)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Facebook
Authentication è un servizio di registrazione ed autenticazione
fornito da Meta Platforms Ireland Limited e collegato al social
network Facebook.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Strumenti di Tracciamento; varie tipologie di
Dati secondo quanto specificato dalla privacy policy del servizio.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://www.facebook.com/help/405977429438260" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Remarketing
	e behavioral targeting</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
tipo di servizi consente a questa Applicazione ed ai suoi partner di
comunicare, ottimizzare e servire annunci pubblicitari basati
sull'utilizzo passato di questa Applicazione da parte dell'Utente.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questa
attività è facilitata dal tracciamento dei Dati di Utilizzo e
dall'utilizzo di Strumenti di Tracciamento per raccogliere
informazioni che vengono poi trasferite ai partner che gestiscono le
attività di remarketing e di behavioral targeting.&nbsp;<br/>
Alcuni
servizi offrono un'opzione di remarketing basata sulle liste di
indirizzi email.&nbsp;<br/>
Generalmente i servizi di questo tipo
offrono la possibilità di disattivare tale tracciamento. Oltre a
qualsiasi funzione di opt-out fornita da uno qualsiasi dei servizi
elencati in questo documento, l’Utente può leggere di più su come
disattivare gli annunci pubblicitari basati sugli interessi
nell'apposita sezione &quot;Come disattivare la pubblicità basata
sugli interessi&quot; in questo documento.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Pubblico
personalizzato di Outbrain (Custom Audience) (Outbrain Inc.)</b></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Pubblico
personalizzato di Outbrain (Custom Audience) è un servizio di
remarketing e di targeting comportamentale fornito da Outbrain Inc.
che collega l'attività di questa Applicazione con la rete
pubblicitaria di Outbrain.<br/>
Gli Utenti possono scegliere di non
utilizzare i Strumenti di Tracciamento di Outbrain per la
personalizzazione degli annunci visitando questa&nbsp;</font></font></font><a href="https://my.outbrain.com/recommendations-settings/home"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">pagina
di opt-out</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Identificativo univoco
universale (UUID); informazioni sul dispositivo; Strumenti di
Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://www.outbrain.com/privacy/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;–&nbsp;</font></font></font><a href="https://www.outbrain.com/privacy/"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Opt
out</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Statistica</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
servizi contenuti nella presente sezione permettono al Titolare del
Trattamento di monitorare e analizzare i dati di traffico e servono a
tener traccia del comportamento dell’Utente.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><span lang="en-US"><b>Google
Analytics (Google Ireland Limited)</b></span></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Google
Analytics è un servizio di analisi web fornito da Google Ireland
Limited (“Google”). Google utilizza i Dati Personali raccolti
allo scopo di tracciare ed esaminare l’utilizzo di questa
Applicazione, compilare report e condividerli con gli altri servizi
sviluppati da Google.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Google
potrebbe utilizzare i Dati Personali per contestualizzare e
personalizzare gli annunci del proprio network pubblicitario.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Strumento di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;–&nbsp;</font></font></font><a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Opt
Out</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Monitoraggio
conversioni di Facebook Ads (pixel di Facebook) (Meta Platforms
Ireland Limited)</b></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
monitoraggio conversioni di Facebook Ads (pixel di Facebook) è un
servizio di statistiche fornito da Meta Platforms Ireland Limited che
collega i dati provenienti dal network di annunci Meta con le azioni
compiute all'interno di questa Applicazione. Il pixel di Facebook
monitora le conversioni che possono essere attribuite alle inserzioni
di Facebook, Instagram e Audience Network.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Irlanda –&nbsp;</font></font></font><a href="https://www.facebook.com/about/privacy/" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Visualizzazione
	di contenuti da piattaforme esterne</b></font></font></font></p>
</ul>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
tipo di servizi permette di visualizzare contenuti ospitati su
piattaforme esterne direttamente dalle pagine di questa Applicazione
e di interagire con essi.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questo
tipo di servizio potrebbe comunque raccogliere dati sul traffico web
relativo alle pagine dove il servizio è instalLato-Regular, anche quando gli
utenti non lo utilizzano.</font></font></font></p>
<p style="margin-left: 1.27cm; margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Google
Fonts&nbsp;</b></font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Google
Fonts è un servizio di visualizzazione di stili di carattere gestito
da Google LLC oppure da Google Ireland Limited, a seconda della
posizione in cui questa Applicazione viene utilizzata, che permette a
questa Applicazione di integrare tali contenuti all’interno delle
proprie pagine.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dati
Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</font></font></font></p>
<p align="justify" style="margin-left: 1.27cm; margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Luogo
del trattamento: Stati Uniti –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">;
Irlanda –&nbsp;</font></font></font><a href="https://policies.google.com/privacy" target="_blank"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Privacy
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.&nbsp;</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Informazioni
su come disattivare gli annunci pubblicitari basati sugli interessi</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Oltre
a qualsiasi funzione di opt-out fornita da uno qualsiasi dei servizi
elencati in questo documento, gli Utenti possono leggere di più su
come disattivare gli annunci pubblicitari basati sugli interessi
nell'apposita sezione della Cookie Policy.&nbsp;</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Diritti
dell’Utente</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Gli
Utenti possono esercitare determinati diritti con riferimento ai Dati
trattati dal Titolare.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">In
particolare, l’Utente ha il diritto di:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>revocare
	il consenso in ogni momento.</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
	può revocare il consenso al trattamento dei propri Dati Personali
	precedentemente espresso.</font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>opporsi
	al trattamento dei propri Dati.</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
	può opporsi al trattamento dei propri Dati quando esso avviene su
	una base giuridica diversa dal consenso. Ulteriori dettagli sul
	diritto di opposizione sono indicati nella sezione sottostante.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>accedere
	ai propri Dati.</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
	ha diritto ad ottenere informazioni sui Dati trattati dal Titolare,
	su determinati aspetti del trattamento ed a ricevere una copia dei
	Dati trattati.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>verificare
	e chiedere la rettificazione.</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
	può verificare la correttezza dei propri Dati e richiederne
	l’aggiornamento o la correzione.</font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>ottenere
	la limitazione del trattamento.</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Quando
	ricorrono determinate condizioni, l’Utente può richiedere la
	limitazione del trattamento dei propri Dati. In tal caso il Titolare
	non tratterà i Dati per alcun altro scopo se non la loro
	conservazione.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>ottenere
	la cancellazione o rimozione dei propri Dati Personali.</b></font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;Quando
	ricorrono determinate condizioni, l’Utente può richiedere la
	cancellazione dei propri Dati da parte del Titolare.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>ricevere
	i propri Dati o farli trasferire ad altro titolare.</b></font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;L’Utente
	ha diritto di ricevere i propri Dati in formato strutturato, di uso
	comune e leggibile da dispositivo automatico e, ove tecnicamente
	fattibile, di ottenerne il trasferimento senza ostacoli ad un altro
	titolare. Questa disposizione è applicabile quando i Dati sono
	trattati con strumenti automatizzati ed il trattamento è basato sul
	consenso dell’Utente, su un contratto di cui l’Utente è parte o
	su misure contrattuali ad esso connesse.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>proporre
	reclamo.</b></font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">&nbsp;L’Utente
	può proporre un reclamo all’autorità di controllo della
	protezione dei dati personali competente o agire in sede giudiziale.</font></font></font></p>
</ul>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Dettagli
sul diritto di opposizione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Quando
i Dati Personali sono trattati nell’interesse pubblico,
nell’esercizio di pubblici poteri di cui è investito il Titolare
oppure per perseguire un interesse legittimo del Titolare, gli Utenti
hanno diritto ad opporsi al trattamento per motivi connessi alla loro
situazione particolare.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Si
fa presente agli Utenti che, ove i loro Dati fossero trattati con
finalità di marketing diretto, possono opporsi al trattamento senza
fornire alcuna motivazione. Per scoprire se il Titolare tratti dati
con finalità di marketing diretto gli Utenti possono fare
riferimento alle rispettive sezioni di questo documento.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Come
esercitare i diritti</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
esercitare i diritti dell’Utente, gli Utenti possono indirizzare
una richiesta agli estremi di contatto del Titolare indicati in
questo documento. Le richieste sono depositate a titolo gratuito e
evase dal Titolare nel più breve tempo possibile, in ogni caso entro
un mese.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Cookie
Policy</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questa
Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di
più, l’Utente può consultare la&nbsp;</font></font></font><a href="safari-reader://www.iubenda.com/private/privacy-policy/2206093/cookie-policy" target="Cookie Policy"><font color="#416ed2"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Cookie
Policy</font></font></font></a><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 19pt"><b>Ulteriori
informazioni sul trattamento</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Difesa
in giudizio</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Dati Personali dell’Utente possono essere utilizzati da parte del
Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
instaurazione per la difesa da abusi nell'utilizzo di questa
Applicazione o dei Servizi connessi da parte dell’Utente.<br/>
L’Utente
dichiara di essere consapevole che il Titolare potrebbe essere
obbligato a rivelare i Dati per ordine delle autorità pubbliche.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Informative
specifiche</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Su
richiesta dell’Utente, in aggiunta alle informazioni contenute in
questa privacy policy, questa Applicazione potrebbe fornire
all'Utente delle informative aggiuntive e contestuali riguardanti
Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Log
di sistema e manutenzione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
necessità legate al funzionamento ed alla manutenzione, questa
Applicazione e gli eventuali servizi terzi da essa utilizzati
potrebbero raccogliere log di sistema, ossia file che registrano le
interazioni e che possono contenere anche Dati Personali, quali
l’indirizzo IP Utente.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Informazioni
non contenute in questa policy</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ulteriori
informazioni in relazione al trattamento dei Dati Personali potranno
essere richieste in qualsiasi momento al Titolare del Trattamento
utilizzando gli estremi di contatto.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Risposta
alle richieste “Do Not Track”</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questa
Applicazione non supporta le richieste “Do Not Track”.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
scoprire se gli eventuali servizi di terze parti utilizzati le
supportino, l'Utente è invitato a consultare le rispettive privacy
policy.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Modifiche
a questa privacy policy</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare del Trattamento si riserva il diritto di apportare modifiche
alla presente privacy policy in qualunque momento notificandolo agli
Utenti su questa pagina e, se possibile, su questa Applicazione
nonché, qualora tecnicamente e legalmente fattibile, inviando una
notifica agli Utenti attraverso uno degli estremi di contatto di cui
è in possesso. Si prega dunque di consultare con frequenza questa
pagina, facendo riferimento alla data di ultima modifica indicata in
fondo.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt"><br/>
Qualora
le modifiche interessino trattamenti la cui base giuridica è il
consenso, il Titolare provvederà a raccogliere nuovamente il
consenso dell’Utente, se necessario.&nbsp;</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="5" style="font-size: 17pt"><b>Definizioni
e riferimenti legali&nbsp;</b></font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Dati
Personali (o Dati)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Costituisce
dato personale qualunque informazione che, direttamente o
indirettamente, anche in collegamento con qualsiasi altra
informazione, ivi compreso un numero di identificazione personale,
renda identificata o identificabile una persona fisica.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Dati
di Utilizzo</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Sono
le informazioni raccolte automaticamente attraverso questa
Applicazione (anche da applicazioni di parti terze integrate in
questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio
dei computer utilizzati dall’Utente che si connette con questa
Applicazione, gli indirizzi in notazione URI (Uniform Resource
Identifier), l’orario della richiesta, il metodo utilizzato
nell’inoltrare la richiesta al server, la dimensione del file
ottenuto in risposta, il codice numerico indicante lo stato della
risposta dal server (buon fine, errore, ecc.) il paese di
provenienza, le caratteristiche del browser e del sistema operativo
utilizzati dal visitatore, le varie connotazioni temporali della
visita (ad esempio il tempo di permanenza su ciascuna pagina) e i
dettagli relativi all’itinerario seguito all’interno
dell’Applicazione, con particolare riferimento alla sequenza delle
pagine consultate, ai parametri relativi al sistema operativo e
all’ambiente informatico dell’Utente.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Utente</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L'individuo
che utilizza questa Applicazione che, salvo ove diversamente
specificato, coincide con l'Interessato.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Interessato</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
persona fisica cui si riferiscono i Dati Personali.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Responsabile
del Trattamento (o Responsabile)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
persona fisica, giuridica, la pubblica amministrazione e qualsiasi
altro ente che tratta dati personali per conto del Titolare, secondo
quanto esposto nella presente privacy policy.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Titolare
del Trattamento (o Titolare)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
persona fisica o giuridica, l'autorità pubblica, il servizio o altro
organismo che, singolarmente o insieme ad altri, determina le
finalità e i mezzi del trattamento di dati personali e gli strumenti
adottati, ivi comprese le misure di sicurezza relative al
funzionamento ed alla fruizione di questa Applicazione. Il Titolare
del Trattamento, salvo quanto diversamente specificato, è il
titolare di questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Questa
Applicazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Lo
strumento hardware o software mediante il quale sono raccolti e
trattati i Dati Personali degli Utenti.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Servizio</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Servizio fornito da questa Applicazione così come definito nei
relativi termini (se presenti) su questo sito/applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Unione
Europea (o UE)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Salvo
ove diversamente specificato, ogni riferimento all’Unione Europea
contenuto in questo documento si intende esteso a tutti gli attuali
stati membri dell’Unione Europea e dello Spazio Economico Europeo.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Cookie</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Cookie sono Strumenti di Tracciamento che consistono in piccole
porzioni di dati conservate all'interno del browser dell'Utente.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Strumento
di Tracciamento</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
Strumento di Tracciamento s’intende qualsiasi tecnologia - es.
Cookie, identificativi univoci, web beacons, script integrati, e-tag
e fingerprinting - che consenta di tracciare gli Utenti, per esempio
raccogliendo o salvando informazioni sul dispositivo dell’Utente.</font></font></font></p>
<p style="margin-bottom: 0cm; line-height: 100%"></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular, serif"><font size="4" style="font-size: 13pt"><b>Riferimenti
legali</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
presente informativa privacy è redatta sulla base di molteplici
ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento
(UE) 2016/679.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ove
non diversamente specificato, questa informativa privacy riguarda
esclusivamente questa Applicazione.</font></font></font></p>
<p style="margin-bottom: 0cm; line-height: 100%"><br/>

</p></div>`;
