import styled from "styled-components";
import promozione from "../../assets/images/Grafiche Homepage/OK2.png";
import prenotazione from "../../assets/images/Grafiche Homepage/OK.png";
import vendita from "../../assets/images/Grafiche Homepage/OK3.png";
import { primaryColor } from "../../components/colors";

const ServicesContainer = styled.div.attrs({
  className: "services-container",
})`
  display: grid;
  padding: 2rem 1rem;

  .inner-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  @media only screen and (min-width: 768px) {
    padding: 3rem;
  }
`;
const ServicesText = styled.div.attrs({ className: "services-text" })`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  h2 {
    color: ${primaryColor};
  }
`;
const ServicesCardContainer = styled.div.attrs({
  className: "services-cards",
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 2rem;
  @media only screen and (max-width: 767px) {
    .card:nth-child(2) {
      grid-auto-flow: dense;
      direction: rtl;
    }
  }
  @media only screen and (min-width: 768px) {
    justify-content: space-evenly;
  }
  @media only screen and (min-width: 1024px) {
    justify-content: space-between;
  }
`;
const Card = styled.div.attrs({ className: "card" })`
  width: 30%;
  .card-img {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  h2 {
    font-size: 16px;
    color: ${primaryColor};
  }
  img {
    width: 100%;
  }
  p {
    line-height: 1.75rem;
    color: ${primaryColor};
  }
  .center-img {
    margin-top: -10px;
    width: 110%;
  }
  @media only screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.5rem;
    text-align: left;
    width: 100%;
    .card-img {
      width: 100%;
      height: 100%;
    }
    .card-text p {
      line-height: 1.2rem;
      font-family: Lato-Light;
    }
  }
`;

export const Services = () => {
  return (
    <ServicesContainer>
      <div className="inner-block">
        <ServicesText>
          <h2>Taking Your Business to</h2>
          <h2>the Next Level</h2>
        </ServicesText>
        <ServicesCardContainer>
          <Card>
            <div className="card-img">
              <img src={promozione} alt="Promozione" />
            </div>
            <div className="card-text">
              <h2>Promozione</h2>
              <p>
                Potrai promuovere il tuo locale ed i tuoi eventi sulla nostra
                app. La nostra community non vede l'ora di scoprirti!
              </p>
            </div>
          </Card>
          <Card>
            <div className="card-img">
              <img
                src={prenotazione}
                alt="Prenotazione"
                className="center-img"
              />
            </div>
            <div className="card-text">
              <h2>Gestione Prenotazioni</h2>
              <p>
                Permetti ai membri della nostra community di prenotare presso il
                tuo locale in modo semplice e veloce!
              </p>
            </div>
          </Card>
          <Card>
            <div className="card-img">
              <img src={vendita} alt="Vendita" />
            </div>
            <div className="card-text">
              <h2>Vendita di Biglietti</h2>
              <p>
                Hai organizzato un evento? Con il nostro innovativo sistema,
                potrai vendere i biglietti online!
              </p>
            </div>
          </Card>
        </ServicesCardContainer>
      </div>
    </ServicesContainer>
  );
};
