import { FormControl, FormHelperText } from "@mui/material";
import Input from "@mui/material/Input";
import { useField } from "formik";
import styled from "styled-components";
import { secondaryColor } from "../colors";

const CustomInput = styled(Input)`
  &.MuiInput-root,
  &.Mui-focused {
    color: ${secondaryColor};
    font-size: 18px;
  }
  &.MuiInput-root:after {
    border-bottom: 2px solid ${secondaryColor};
  }
  &.MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #ababab;
  }
  &.MuiInput-root:before {
    border-bottom: 2px solid #8b8b8b;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .MuiInputAdornment-root p {
    color: #8d8d8d;
  }
  .MuiInput-input {
    padding: 0.5rem;
    &::placeholder {
      color: ${secondaryColor};
      opacity: 1;
    }
  }
  .MuiInput-input.Mui-disabled {
    -webkit-text-fill-color: #5f5f5f;
    color: ${secondaryColor};
  }

  .show-password {
    width: 18px;
    filter: invert(30%);
    cursor: pointer;
  }
  .input-icon-adorment {
    width: 18px;
    filter: invert(70%);
  }
`;

export const CustomInputField = ({
  name,
  label,
  ...otherProps
}: {
  name: string;
  label: string;
  [key: string]: any;
}) => {
  const [field, meta] = useField(name);
  let helperText = "";

  const configTextField = {
    ...field,
    ...otherProps,
    variant: "standard",
    error: false,
    placeholder: label,
    id: `${name}-input-field`,
    "aria-describedby": "component-error-text",
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }
  return (
    <FormControl variant="standard">
      {/* <InputLabel htmlFor={`${name}-input-field`}>Password</InputLabel> */}
      <CustomInput {...configTextField} />
      <FormHelperText id="component-error-text">{helperText}</FormHelperText>
    </FormControl>
  );
};
