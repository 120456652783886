import { AssistenzaBody } from "./components/AssistenzaBody";
import { AssistenzaHero } from "./components/AssistenzaHero";
import { AssistenzaPageContainer } from "./components/AssistenzaPageContainer";
import { terms } from "./data/termsData";

export const TermsAndConditionsPage = () => {
  return (
    <AssistenzaPageContainer className="terms-section">
      <AssistenzaHero title="TERMS AND CONDITIONS" />
      <AssistenzaBody data={terms} />
    </AssistenzaPageContainer>
  );
};
