export const terms = `<p style="margin-bottom: 0.21cm; line-height: 0.51cm">
<font color="white"><font face="Lato-Regular serif"><font size="6" style="font-size: 23pt"><b>Termini
e Condizioni di <span class='header-title'>www.nightcitizenapp.com</span></b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questi
Termini disciplinano</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">l’utilizzo
	di questa Applicazione e</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">qualsiasi
	altro Accordo o rapporto giuridico con il Titolare</font></font></font></p>
</ul>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">in
maniera vincolante. Le espressioni con l’iniziale maiuscola sono
definite nella relativa sezione di questo documento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
è pregato di leggere attentamente questo documento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
soggetto responsabile di questa Applicazione è:</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Night
Citizen srls&nbsp;<br/>
Via Appia Nuova, 596&nbsp;<br/>
00179 Roma</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Indirizzo
email del Titolare:</b></font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">support@nightcitizenapp.com</font></font></font><font color="white"><font face="-apple-system-font, serif"><font size="4" style="font-size: 13pt">&nbsp;</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="5" style="font-size: 17pt"><b>Da
sapere a colpo d’occhio</b></font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Si
	fa presente che determinate disposizioni di questi Termini
	potrebbero essere solo applicabili ad alcune categorie di Utenti. In
	particolare, alcune disposizioni potrebbero applicarsi solo a
	Consumatori o solo a Utenti che non agiscono come Consumatori. Tali
	limitazioni sono sempre menzionate esplicitamente in ciascuna
	clausola interessata. In caso di mancata menzione, le clausole si
	applicano a tutti gli Utenti.</font></font></font></p>
</ul>
<p style="margin-bottom: 0cm; line-height: 100%"></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="5" style="font-size: 17pt"><b>CONDIZIONI
D'USO</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Salvo
ove diversamente specificato, le condizioni d’uso di questa
Applicazione esposte in questa sezione hanno validità generale.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ulteriori
condizioni d’uso o d’accesso applicabili in particolari
situazioni sono espressamente indicate in questo documento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Utilizzando
questa Applicazione l’Utente dichiara di soddisfare i seguenti
requisiti:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Non
	ci sono restrizioni riferite agli Utenti rispetto al fatto che essi
	siano Consumatori o Utenti Professionisti;</font></font></font></p>
</ul>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Registrazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
usufruire del Servizio l’Utente può aprire un account indicando
tutti i dati e le informazioni richieste in maniera completa e
veritiera.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Non
è possibile usufruire del Servizio senza aprire un account Utente.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">È
responsabilità degli Utenti conservare le proprie credenziali
d’accesso in modo sicuro e preservarne la confidenzialità. A tal
fine, gli Utenti devono scegliere una password che corrisponda al più
alto livello di sicurezza disponibile su questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Creando
un account l’Utente accetta di essere pienamente responsabile di
ogni attività posta in atto con le sue credenziali d’accesso. Gli
Utenti sono tenuti a informare il Titolare immediatamente e
univocamente tramite i recapiti indicati in questo documento qualora
ritengano che le proprie informazioni personali, quali ad esempio
l’account Utente, le credenziali d’accesso o dati personali,
siano state violate, illecitamente diffuse o sottratte.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Chiusura
account</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
è libero di chiudere il proprio account e cessare l’utilizzo del
Servizio in qualsiasi momento, seguendo questa procedura:</font></font></font></p>
<ul>
	<li><p style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Contattando
	il Titolare ai recapiti in questo documento.</font></font></font></p>
	<li><p style="margin-bottom: 0.49cm; line-height: 100%"><font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Attraverso
	il tasto dedicato nella sezione “Account” del proprio profilo.</font></font></font></p>
</ul>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Sospensione
e cancellazione account</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare si riserva il diritto di sospendere o cancellare l’account
di un Utente in qualsiasi momento a propria discrezione e senza
preavviso, qualora lo ritenga inopportuno, offensivo o contrario a
questi Termini.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
sospensione o cancellazione dell’account non da all’Utente alcun
diritto di risarcimento, rimborso o indennizzo.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
sospensione o cancellazione di un account per cause addebitabili
all’Utente non esonera l’Utente dal pagamento dei compensi o
prezzi eventualmente applicabili.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Contenuti
su questa Applicazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Salvo
ove diversamente specificato o chiaramente riconoscibile, tutti i
contenuti disponibili su questa Applicazione sono di proprietà di o
forniti dal Titolare o dei/dai suoi licenzianti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare adotta la massima cura affinché il contenuto disponibile su
questa Applicazione non violi la normativa applicabile o diritti di
terze parti. Tuttavia, non sempre è possibile raggiungere tale
risultato.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">In
tali casi, senza alcun pregiudizio ai diritti ed alle pretese
legalmente esercitabili, gli Utenti sono pregati di indirizzare i
relativi reclami ai recapiti specificati in questo documento.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Diritti
sui contenuti di questa Applicazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare detiene e si riserva espressamente ogni diritto di proprietà
intellettuale sui suddetti contenuti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Gli
Utenti non sono autorizzati ad usare i contenuti in alcun modo che
non sia necessario od implicito nel corretto utilizzo del Servizio.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">In
particolare, ma senza esclusioni, è fatto divieto agli Utenti di
copiare, scaricare, condividere oltre i limiti sotto specificati,
modificare, tradurre, elaborare, pubblicare, trasmettere, vendere,
concedere sottolicenze, trasformare, trasferire/alienare a terze
parti o creare opere derivate a partire dal contenuto disponibile su
questa Applicazione, di permettere a terze parti di intraprendere
tali attività tramite il proprio account Utente o dispositivo, anche
a propria insaputa.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ove
espressamente indicato su questa Applicazione, l’Utente è
autorizzato a scaricare, copiare e/o condividere determinati
contenuti disponibili su questa Applicazione esclusivamente per scopi
personali e non commerciali ed a condizione che sia osservata
l’attribuzione della paternità dell’opera nonché l’indicazione
di ogni altra circostanza rilevante richiesta dal Titolare.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Restano
ferme le limitazioni ed esclusioni previste dalla normativa sul
diritto d’autore.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Contenuti
forniti dagli Utenti</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare consente agli Utenti di caricare, condividere o offrire i
propri contenuti su questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">All’atto
di fornire contenuti a questa Applicazione l’Utente dichiara di
essere legalmente autorizzato a farlo e conferma che detti contenuti
non violano norme di legge e/o diritti di terzi.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Responsabilità
per i contenuti forniti</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
risponde in via esclusiva dei contenuti caricati, pubblicati,
condivisi o comunque forniti a questa Applicazione. L’Utente
riconosce ed accetta che&nbsp;il Titolare non filtra né modera tali
contenuti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ciononostante,
il Titolare si riserva il diritto di rimuovere, cancellare, bloccare
o rettificare detti contenuti a propria discrezione e di negare senza
preavviso all’Utente che li ha caricati l’accesso a questa
Applicazione:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">se
	ha ricevuto un reclamo in relazione a tale contenuto;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">se
	ha ricevuto una notifica di violazione dei diritti di proprietà
	intellettuale;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">per
	ordine dell’Autorità; o</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">qualora
	sia stato fatto presente al Titolare che tali contenuti, se
	accessibili tramite questa Applicazione, possono rappresentare un
	rischio per gli Utenti, per i terzi o per la disponibilità del
	Servizio.</font></font></font></p>
</ul>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
rimozione, cancellazione, il blocco o la rettifica dei contenuti non
giustificano alcuna pretesa di risarcimento, rimborso o indennizzo in
capo agli Utenti che hanno fornito tali contenuti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Gli
Utenti accettano di mantenere indenne il Titolare da e contro ogni
pretesa avanzata e/o danno sofferto a causa di contenuti da essi
forniti a o offerti tramite questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Accesso
a risorse esterne</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tramite
questa Applicazione gli Utenti potrebbero avere accesso a risorse
fornite da terzi. Gli Utenti riconoscono ed accettano che il Titolare
non ha alcun controllo su tali risorse e che pertanto non risponde
del loro contenuto e della loro disponibilità.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Le
condizioni applicabili alle risorse fornite da terzi, ivi incluse
quelle applicabili a eventuali concessioni di diritti su contenuti,
sono determinate dagli stessi terzi e regolate nei relativi termini e
condizioni o, in loro assenza, dalla legge.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Uso
ammesso</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questa
Applicazione ed il Servizio possono essere utilizzati solo per gli
scopi per i quali sono offerti, secondo questi Termini ed ai sensi
della legge applicabile.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">È
responsabilità esclusiva dell’Utente di far sì che l’uso di
questa Applicazione e/o del Servizio non violi la legge, i
regolamenti o i diritti di terzi.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Pertanto,
il Titolare si riserva il diritto di adottare ogni misura idonea a
proteggere i propri interessi legittimi, ed in particolare di negare
all’Utente l’accesso a questa Applicazione o al Servizio,
risolvere contratti, denunciare ogni attività censurabile svolta
tramite questa Applicazione o il Servizio alle autorità competenti –
p. es. l’autorità giudiziaria o amministrativa – ogniqualvolta
l’Utente ponga in essere o vi sia il sospetto che ponga in essere:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">violazioni
	di legge, regolamenti e/o dei Termini;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">lesioni
	di diritti di terzi;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">atti
	che possono pregiudicare considerevolmente i legittimi interessi del
	Titolare;</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">offese
	al Titolare o a un terzo.</font></font></font></p>
</ul>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Condizioni
d’uso dell’API&nbsp;</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Gli
Utenti possono accedere ai propri dati relativi a questa Applicazione
attraverso l’Application Program Interface (API). Ogni utilizzo
dell’API, anche attraverso prodotti o servizi di terzi che accedono
a questa Applicazione, è soggetto ai Termini e in aggiunta alle
seguenti condizioni specifiche:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">l’Utente
	riconosce ed accetta espressamente che il Titolare non risponde di
	danni o perdite conseguenti all’utilizzo da parte dell’Utente
	dell’API o di prodotti o servizi terzi che accedono a dati tramite
	l’API.</font></font></font></p>
</ul>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="5" style="font-size: 17pt"><b>TERMINI
E CONDIZIONI DI VENDITA</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Prodotti
a pagamento&nbsp;</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Alcuni
dei Prodotti offerti su questa Applicazione come parte del servizio
sono a pagamento.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Le
tariffe, la durata e le condizioni applicabili alla vendita di tali
Prodotti sono descritte di seguito e nelle rispettive sezioni di
questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
acquistare i Prodotti, l’Utente è tenuto a registrarsi o ad
effettuare l’accesso a questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Descrizione
del prodotto&nbsp;</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Prezzi,
descrizioni e disponibilità dei Prodotti sono specificati nelle
rispettive sezioni di questa Applicazione e sono soggetti a modifica
senza preavviso.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Sebbene
i Prodotti su questa Applicazione siano presentati con la massima
accuratezza tecnicamente possibile, la rappresentazione su questa
Applicazione tramite qualsiasi mezzo (inclusi, a seconda dei casi,
materiali grafici, immagini, colori, suoni) è da intendersi come
mero riferimento e non implica alcuna garanzia in merito alle
caratteristiche del Prodotto acquistato.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Le
caratteristiche del Prodotto selezionato saranno specificate durante
la procedura d’acquisto.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Procedura
d’acquisto</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Ogni
fase, dalla scelta del prodotto fino all’inoltro dell’ordine, fa
parte della procedura d’acquisto.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
procedura d’acquisto comprende i seguenti passaggi:</font></font></font></p>
<ul>
	<li><p align="justify" style="margin-top: 0.49cm; margin-bottom: 0cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Gli
	Utenti sono pregati di scegliere il Prodotto desiderato e di
	verificare la loro scelta d’acquisto.</font></font></font></p>
	<li><p align="justify" style="margin-bottom: 0.49cm; line-height: 100%">
	<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Dopo
	aver controllato le informazioni visibili nella scelta d’acquisto,
	gli Utenti possono effettuare l’ordine inoltrandolo.</font></font></font></p>
</ul>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tutte
le notifiche relative alla procedura d’acquisto sopra descritta
saranno inviate all’indirizzo di posta elettronica fornito
dall’Utente a tal fine.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Mezzi
di pagamento</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
dettagli relativi ai mezzi di pagamento accettati sono evidenziati
durante la procedura d’acquisto.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Alcuni
mezzi di pagamento sono legati ad ulteriori condizioni o comportano
costi aggiuntivi. Le informazioni dettagliate sono riportate nella
relativa sezione di questa Applicazione.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tutti
i pagamenti vengono gestiti autonomamente da servizi terzi. Pertanto,
questa Applicazione non raccoglie dati relativi al pagamento –
quali numeri di carta di credito – ma riceve una notifica una volta
che il pagamento è andato a buon fine.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Nel
caso in cui il pagamento effettuato con uno dei mezzi disponibili
fallisca o venga rifiutato dal fornitore di servizi di pagamento, il
Titolare non è obbligato a eseguire l’ordine. Eventuali costi o
commissioni derivanti dal pagamento fallito o rifiutato sono a carico
dell’Utente.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Riserva
dei diritti d’uso&nbsp;</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Fino
alla ricezione del pagamento del prezzo integrale di acquisto da
parte del Titolare, l’Utente non acquista i diritti d’uso dei
Prodotti ordinati.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Consegna</b></font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Prestazione
di servizi</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
servizio acquistato sarà eseguito o reso disponibile nei tempi
indicati su questa Applicazione o secondo le modalità comunicate
prima dell'inoltro dell'ordine.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="5" style="font-size: 17pt"><b>Limitazione
di responsabilità e manleva</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Utenti
Europei</b></font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Manleva</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
si impegna a manlevare e tenere indenne il Titolare e i suoi
sottoposti, affiliati, funzionari, agenti, contitolari del marchio,
partner e dipendenti da qualsivoglia rivendicazione o pretesa –
compresi, senza alcuna limitazione, oneri e spese legali – avanzata
da terzi a causa di o in collegamento con comportamenti colposi quali
l’utilizzo o la connessione al servizio, la violazione dei presenti
Termini, la violazione di diritti di terze parti o di leggi da parte
dell’Utente, dei suoi affiliati, funzionari, agenti, contitolari
del marchio, partner e dipendenti, nella misura di legge.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Limitazione
della responsabilità per le attività dell’Utente su questa
Applicazione</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Salvo
ove diversamente specificato e fatte salve le disposizioni di legge
applicabili in materia di responsabilità per danno da prodotto, è
esclusa ogni pretesa risarcitoria nei confronti del Titolare (o di
qualsiasi persona fisica o giuridica che agisca per suo conto).</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Quanto
precede non limita la responsabilità del Titolare per morte, danno
alla persona o all’integrità fisica o mentale, danni derivanti
dalla violazione di obblighi contrattuali essenziali, quali gli
obblighi strettamente necessari al raggiungimento della causa del
contratto, e/o ai danni provocati con dolo o colpa grave, a
condizione che l’utilizzo di questa Applicazione da parte
dell’Utente sia stato idoneo e corretto.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Salvo
che i danni siano stati causati con dolo o colpa grave o incidano
sulla vita e/o l’integrità personale, fisica o mentale, il
Titolare risponde solo nella misura del danno tipico per il tipo di
contratto e prevedibile al momento della conclusione.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="5" style="font-size: 17pt"><b>Disposizioni
comuni</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Nessuna
rinuncia implicita</b></font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
mancato esercizio di diritti di legge o pretese derivanti da questi
Termini da parte del Titolare non costituisce rinuncia agli stessi.
Nessuna rinuncia può essere considerata definitiva in relazione ad
uno specifico diritto o a qualsiasi altro diritto.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Interruzione
del Servizio</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Per
garantire il miglior livello di servizio possibile, il Titolare si
riserva di interrompere il Servizio per finalità di manutenzione,
aggiornamenti di sistema o per qualsiasi altra modifica, dandone
idonea notizia agli Utenti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Nei
limiti di legge, il Titolare si riserva di sospendere o terminare
completamente il Servizio. In caso di terminazione del Servizio, il
Titolare si adopererà affinché gli Utenti possano estrarre i propri
Dati Personali e le informazioni secondo le disposizioni di legge.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Inoltre,
il Servizio potrebbe non essere disponibile per cause che si
sottraggono al ragionevole controllo del Titolare, quali cause di
forza maggiore (p. es. scioperi, malfunzionamenti infrastrutturali,
blackout etc.).</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Rivendita
del Servizio</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Gli
Utenti non sono autorizzati a riprodurre, duplicare, copiare,
vendere, rivendere o sfruttare questa Applicazione o il Servizio in
toto o in parte senza previo consenso scritto del Titolare, espresso
direttamente o attraverso un legittimo programma di rivendite.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Privacy
policy</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Le
informazioni sul trattamento dei Dati Personali sono contenute nella
privacy policy di questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Proprietà
intellettuale</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Senza
pregiudizio ad alcuna previsione più specifica contenuta nei
Termini, i diritti di proprietà intellettuale ed industriale, quali
ad esempio diritti d’autore, marchi, brevetti e modelli relativi a
questa Applicazione sono detenuti in via esclusiva dal Titolare o dai
suoi licenzianti e sono tutelati ai sensi della normativa e dei
trattati internazionali applicabili alla proprietà intellettuale.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tutti
i marchi – denominativi o figurativi – ed ogni altro segno
distintivo, ditta, marchio di servizio, illustrazione, immagine o
logo che appaiono in collegamento con questa Applicazione sono e
restano di esclusiva proprietà del Titolare o dei suoi licenzianti e
sono tutelati ai sensi della normativa e dei trattati internazionali
applicabili alla proprietà intellettuale.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Modifiche
dei Termini</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare si riserva il diritto di modificare i Termini in ogni
momento. In tal caso, il Titolare darà opportuna notizia delle
modifiche agli Utenti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Le
modifiche avranno effetti sul rapporto con l’Utente solo per il
futuro.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’utilizzo
continuato del Servizio implica l’accettazione dell’Utente dei
Termini aggiornati. Se l’Utente non desidera accettare le
modifiche, deve cessare l’utilizzo del Servizio. La mancata
accettazione dei Termini aggiornati potrebbe comportare la facoltà
di ciascuna parte di recedere dall’Accordo.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
versione precedente applicabile continua a disciplinare il rapporto
fino all’accettazione dell’Utente. Tale versione può essere
richiesta al Titolare.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Se
richiesto dalla legge applicabile, il Titolare specificherà la data
entro cui le modifiche ai Termini entreranno in vigore.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Cessione
del contratto</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
Titolare si riserva il diritto di trasferire, cedere, disporre di,
novare o appaltare singoli o tutti i diritti e le obbligazioni
secondo questi Termini, avendo riguardo per gli interessi legittimi
degli Utenti.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Si
applicano le disposizioni relative alla modifica di questi Termini.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">L’Utente
non è autorizzato a cedere o trasferire i propri diritti e le
proprie obbligazioni secondo i Termini senza il consenso scritto del
Titolare.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Contatti</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tutte
le comunicazioni inerenti all’uso di questa Applicazione devono
essere inviate ai recapiti indicati in questo documento.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Clausola
di salvaguardia</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Qualora
alcuna delle disposizioni di questi Termini dovesse essere o divenire
nulla o inefficace ai sensi della legge applicabile, la nullità o
inefficacia di tale disposizione non provoca inefficacia delle
restanti previsioni, che permangono pertanto valide ed efficaci.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Utenti
USA</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Qualsiasi
disposizione invalida o inefficace sarà interpretata ed adattata nei
limiti necessari per renderla valida, efficace e conforme alla
finalità originaria.<br/>
Questi Termini costituiscono l’intero
accordo tra Utente e Titolare con riferimento all’oggetto regolato
e prevalgono su ogni altra comunicazione, compresi eventuali accordi
precedenti, tra le parti in merito all’oggetto regolato.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Questi
Termini saranno attuati nella più ampia misura consentita dalla
legge.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Utenti
europei</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Qualora
una disposizione di questi Termini dovesse essere o divenire nulla,
invalida o inefficace, le parti si adopereranno per individuare in
via amichevole una disposizione valida ed efficace sostitutiva di
quella nulla, invalida o inefficace.&nbsp;<br/>
In caso di mancato
accordo nei termini predetti, se permesso o previsto dalla legge
applicabile, la disposizione nulla, invalida o inefficace sarà
sostituita dalla disciplina legale applicabile.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Fermo
restando quanto sopra, la nullità, invalidità o inefficacia di una
specifica disposizione di questi Termini non comporta nullità
dell’intero Accordo, a meno che le disposizioni nulle, invalidi o
inefficaci nel quadro dell’Accordo siano essenziali o di tale
importanza, che le parti non avrebbero concluso il contratto se
avessero saputo che la disposizione sarebbe stata invalida, ovvero in
casi in cui le disposizioni residue comporterebbero un onere
eccessivo ed inaccettabile per una delle parti.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Legge
applicabile</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">I
Termini sono disciplinati dalla legge del luogo in cui è stabilito
il Titolare, così come indicato nella relativa sezione di questo
documento a prescindere dalle norme di conflitto.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Eccezione
per Consumatori Europei</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tuttavia,
a prescindere da quanto precede, se l’Utente agisce come
Consumatore Europeo ed ha residenza abituale in un paese la cui legge
prevede un livello di tutela dei consumatori superiore, prevale tale
superiore livello di tutela.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 15pt"><b>Foro
competente</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
competenza esclusiva a conoscere qualsiasi controversia derivante da
o in collegamento con i Termini spetta al giudice del luogo in cui il
Titolare è stabilito, così come indicato nella relativa sezione di
questo documento.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Eccezione
per Consumatori Europei</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Quanto
precede non si applica a Utenti che agiscono come Consumatori Europei
o Consumatori situati in Svizzera, Norvegia o Islanda.</font></font></font></p>
<p style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="5" style="font-size: 17pt"><b>Definizioni
e riferimenti legali&nbsp;</b></font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Questa
Applicazione (o questa Applicazione)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
struttura che consente la fornitura del Servizio.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Accordo</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Qualsiasi
rapporto legalmente vincolante o contrattuale tra il Titolare e
l’Utente disciplinato dai Termini.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Utente
Commerciale</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Qualsiasi
Utente che non corrisponde alla definizione di Consumatore.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Europeo
(o Europa)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Definisce
un Utente fisicamente presente o con sede legale nell’Unione
Europea, a prescindere dalla nazionalità.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Titolare
(o Noi)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Indica
la persona fisica o giuridica che fornisce questa Applicazione e/o
offre il Servizio agli Utenti.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Prodotto</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Un
bene o servizio acquistabile attraverso questa Applicazione, come ad
esempio un bene materiale, file digitali, software, servizi di
prenotazione etc.</font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">La
vendita di un Prodotto può essere parte del Servizio, così come
sopra definito.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Servizio</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Il
servizio offerto tramite questa Applicazione così come descritto nei
Termini e su questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Termini</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Tutte
le condizioni applicabili all’utilizzo di questa Applicazione e/o
alla fornitura del Servizio così come descritti in questo documento
nonché in qualsiasi altro documento o accordo ad esso collegato,
nella versione rispettivamente più aggiornata.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Utente
(o Tu)</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Indica
qualsiasi persona fisica che utilizzi questa Applicazione.</font></font></font></p>
<p style="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 100%">
<font color="white"><font face="Lato-Regular serif"><font size="4" style="font-size: 13pt"><b>Consumatore</b></font></font></font></p>
<p align="justify" style="margin-top: 0.49cm; margin-bottom: 0.49cm; line-height: 100%">
<font color="white"><font face="Lato Light, serif"><font size="4" style="font-size: 13pt">Qualsiasi
persona fisica che, in qualità di Utente, utilizza beni o servizi
per scopi personali e, in generale, agisce per scopi estranei alla
propria attività imprenditoriale, commerciale, artigianale o
professionale.</font></font></font></p>
`;
