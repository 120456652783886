import styled from "styled-components";
import { SeoWrapper } from "../../components/SeoWrapper";
import { BLOCKS_CONTENT } from "../../data/Blocks_Data";
import { BlockComponent } from "../Home/BlockComponent";
import { Services } from "./Services";

const LocaliPageContainer = styled.section.attrs({
  className: "locali-page-container",
})`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  .promuovi-block {
    .inner-block {
      grid-template-columns: 1fr !important;
      .block-image {
        height: 200px;
        margin-right: -1rem;
      }
      .block-text {
        position: absolute;
        left: 1rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .community-block {
    .inner-block {
      grid-template-columns: 4fr 5fr;
    }
  }

  .ticket-block {
    .block-text {
      text-align: start;
      align-items: flex-start;
    }
  }

  @media only screen and (min-width: 450px) {
    .promuovi-block {
      .inner-block {
        .block-image {
          background-size: contain !important;
        }
      }
    }
  }

  @media only screen and (max-width: 645px) {
    .ticket-block {
      .inner-block {
        .block-image {
          width: 120%;
          margin-left: -2rem;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .promuovi-block {
      min-height: 60vh;
      .inner-block {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        justify-content: space-between;
        position: relative;
        .block-text {
          position: relative;
        }

        .block-image {
          width: 100%;
          height: 100%;
          max-width: 800px;
          margin-left: auto;
          background-size: contain !important;
          background-position: right center !important;
        }
      }
    }

    .ticket-block {
      .block-image {
        background-position: right center !important;
        width: 100%;
        margin-left: auto;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    .ticket-block {
      .inner-block {
        grid-template-columns: 2fr 3fr !important;

        .block-image {
          max-width: 550px;
        }
      }
    }
    h2:not(.card-text h2) {
      font-size: 50px;
    }

    .card-text h2 {
      font-size: 35px;
    }
    .card-text p {
      font-size: 22px;
    }

    h3 {
      font-size: 29px;
    }
  }
`;

export const LocaliPage = () => {
  return (
    <LocaliPageContainer>
      <SeoWrapper page="locali" />
      <BlockComponent {...BLOCKS_CONTENT[1]} />
      <BlockComponent {...BLOCKS_CONTENT[5]} />
      <Services />
      <BlockComponent {...BLOCKS_CONTENT[7]} />
    </LocaliPageContainer>
  );
};
