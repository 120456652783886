import { Dispatch, SetStateAction, useCallback } from "react";
import { Button } from "../../../../components/Button";
import { ticket_names } from "../../../../data/Locale_Data";
import {
  BuyTicketState,
  EventProps,
  TicketProps,
} from "../../../../interfaces";

export const Stage1 = ({
  event,
  setTicketState,
}: {
  event: EventProps;
  setTicketState: Dispatch<SetStateAction<BuyTicketState>>;
}) => {
  const handleClick = useCallback(
    (ticket: TicketProps) => {
      setTicketState((prev) => ({
        ...prev,
        stage: 2,
        ticketToBuy: ticket,
      }));
    },
    [setTicketState]
  );
  return (
    <div className="tickets-container">
      {event.ticket_types
        .filter((t) => !t.physical)
        .map((ticket) => (
          <div key={ticket.name} className="ticket-details">
            <div className="header">
              <h1>
                {ticket_names.find(({ value }) => value === ticket.name)
                  ?.label || ticket.name}
              </h1>
              <span>Disponibili: {ticket.available}</span>
            </div>
            <Button
              label={`Acquista ${
                ticket.price === 0
                  ? "Biglietto Free"
                  : `a ${(ticket.price / 100).toFixed(2)}€`
              }`}
              onClick={() => handleClick(ticket)}
              disabled={!!ticket.available && ticket.available < 1}
            />
          </div>
        ))}
    </div>
  );
};
