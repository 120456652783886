import styled from "styled-components";
import { SeoWrapper } from "../../components/SeoWrapper";
import { Contacts } from "./Contacts";
import { Experience } from "./Experience";
import { Team } from "./Team";

const ChiSiamoStyle = styled.section.attrs({ className: "chi-siamo-setion" })`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 1280px) {
    // > div:first-child {
    //   padding-inline: 10rem !important;
    // }
  }
`;
export const ChiSiamoPage = () => {
  return (
    <ChiSiamoStyle>
      <SeoWrapper page="chisiamo" />
      <Experience />
      <Team />
      <Contacts />
    </ChiSiamoStyle>
  );
};
