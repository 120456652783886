import { Modal } from "@mui/material";
import styled from "styled-components";
import {
  primaryColor,
  secondaryColor,
  buttonColor,
  buttonHoverColor,
} from "../../../../components/colors";

export const ModalStyle = styled(Modal)`
  .MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 500px;
    background-color: ${primaryColor};
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    max-height: 95vh;

    .cover {
      height: 170px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .description {
      text-align: center;
      h2 {
        margin-top: 1.5rem;
      }
      p {
        margin: 0;
      }
      p,
      h3 {
        font-family: Lato-Light;
        font-weight: lighter;
      }
      h3 {
        font-size: 1.3em;
      }
      .details {
        margin-top: 1.5rem;
      }
    }

    .buy-ticket-header {
      padding: 2rem 1rem;
      text-align: center;
      background-color: #393838;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      position: relative;

      h2 {
        font-family: Lato-Thin;
        line-height: 38px;
      }

      .countdown {
        position: absolute;
        bottom: 4px;
        left: 16px;
      }
      .inner {
        font-weight: bold;
        &.low {
          color: ${buttonHoverColor};
        }
      }
    }

    .ticket-info {
      padding: 3rem 2rem;
      button {
        width: 100%;
        padding-block: 0.5rem;
        span {
          letter-spacing: 1px;
          font-family: Lato-Regular;
          font-weight: normal;
        }
      }
      .no-tickets {
        padding-block: 6rem;
        text-align: center;
      }

      .tickets-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        .ticket-details {
          display: grid;
          grid-template-columns: 1fr;
          gap: 0.75rem;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            h1 {
              padding-left: 0.5rem;
            }
            span {
              font-size: 14px;
              font-family: "Lato-Light";
            }
          }
        }
      }

      .ticket-number {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        .MuiFormControl-root {
          width: 90%;
          margin: auto;

          .MuiSelect-select {
            text-align: end;
            padding-right: 2rem;
          }

          .MuiFormHelperText-root {
            &:not(.Mui-error) {
              color: grey;
            }
            text-align: center;
            font-size: 10px;
          }
        }
      }

      .ticket-email {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2rem;

        .attendees-container {
          display: grid;
          grid-template-columns: 1fr;
          row-gap: 1rem;
          column-gap: 3rem;

          .tickets-toggle {
            .MuiSwitch-thumb {
              color: ${secondaryColor};
            }
            .MuiSwitch-track {
              background-color: grey;
            }
            .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
              background-color: ${buttonColor};
              opacity: 1;
            }
            .MuiTypography-root {
              color: ${secondaryColor};
              font-size: 14px;
            }
          }

          .email-container {
            h1 {
              margin-bottom: 0.5rem;
              margin-left: -0.5rem;
            }
          }
        }

        .ticket-payment-error {
          margin: 0;
          text-align: center;
          width: 100%;
          color: #d32f2f;
        }

        .checkbox {
          margin-bottom: 1rem;
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
          min-height: 60px;
          .check-top {
            padding-left: 5px;
            margin-top: 1rem;
            display: flex;
            // gap: 1rem;
          }
          .error {
            text-align: center;
          }
          span {
            color: grey;
            font-size: 12px;
            a {
              color: ${secondaryColor};
            }
          }
          input {
            margin-right: 0.5rem;
          }

          .css-checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
          }

          .css-checkbox + label {
            position: relative;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            height: 20px;
            color: grey;
          }
          .css-checkbox + label::before {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            margin-right: 13px;
            width: 18px;
            height: 18px;
            background-color: white;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(204, 204, 204);
            border-radius: 2px;
            box-shadow: none;
          }
          .css-checkbox:checked + label::after {
            content: " ";
            background-color: ${buttonColor};
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0px;
            left: 2px;
            top: 2px;
            text-align: center;
            font-size: 10px;
            height: 16px;
            width: 16px;
          }
        }
      }

      #payment-form {
        button {
          width: 90%;
          margin: auto;
          margin-top: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        #payment-message {
          text-align: center;
          margin-top: 1rem;
          color: ${buttonHoverColor};
        }

        .spinner,
        .spinner:before,
        .spinner:after {
          border-radius: 50%;
        }

        .spinner {
          color: #ffffff;
          font-size: 22px;
          text-indent: -99999px;
          margin: 0px auto;
          position: relative;
          width: 20px;
          height: 20px;
          box-shadow: inset 0 0 0 2px;
          transform: translateZ(0);
        }

        .spinner:after {
          position: absolute;
          content: "";
        }

        .spinner:after {
          background: white;
          -webkit-animation: loading 2s infinite ease;
          animation: loading 2s infinite ease;
          width: 3px;
          height: 12px;
          top: 3.5px;
          left: 8.5px;
        }

        @keyframes loading {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .MuiBox-root {
      max-width: 600px;

      .cover {
        height: 300px;
      }
      .description {
        p {
          font-size: 23px;
        }
        h3 {
          font-size: 30px;
        }
      }

      .ticket-info {
        .ticket-email {
          .attendees-container {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }
`;
