import { Modal, Typography, Box } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { primaryColor } from "../../../components/colors";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  maxWidth: "400px",
  bgcolor: primaryColor,
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const StripeMessageModal = ({
  clientSecret,
}: {
  clientSecret: string;
}) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const location = useLocation();
  const [stripeMessage, setStripeMessage] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    navigate(location.pathname);
    setOpenModal(false);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      process.env.NODE_ENV === "development" && console.log(paymentIntent);

      switch (paymentIntent?.status) {
        case "succeeded":
          setStripeMessage(
            `Pagamento avvenuto con successo! - Controlla la tua mail, lì troverai il tuo biglietto ed un QR-Code da mostrare alla cassa del locale per accedere all’evento! - Buona Serata!`
          );
          break;
        case "processing":
          setStripeMessage("Il tuo pagamento e' in corso");
          break;
        case "requires_payment_method":
          setStripeMessage("Il pagamento non e' andato a buon fine. Riprova.");
          break;
        default:
          setStripeMessage("Ops. Qualcosa e' andato storto");
          break;
      }
      setOpenModal(!!stripeMessage);
    });
  }, [clientSecret, stripe, stripeMessage]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-message-stripe"
      aria-describedby="modal-description-stripe"
    >
      <Box sx={style}>
        <Typography
          id="modal-message-stripe"
          variant="h6"
          component="h2"
          sx={{ color: "white", textAlign: "center" }}
        >
          {stripeMessage.split(" - ").map((s, idx) => (
            <p key={"a" + idx}>{s}</p>
          ))}
        </Typography>
        <div
          className="modal-buttons response-stripe"
          style={{
            display: "flex",
            gap: "2rem",
            marginTop: "1rem",
            justifyContent: "center",
          }}
        >
          <Button label="Ok" onClick={handleCloseModal} />
        </div>
      </Box>
    </Modal>
  );
};
