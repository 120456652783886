import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { Button } from "./Button";
import { buttonHoverColor, primaryColor, secondaryColor } from "./colors";

const CookieContainer = styled.div`
  h2 {
    font-size: revert;
    margin-block: revert;
  }

  button {
    padding: 0.5rem 1.1rem;
    span {
      font-size: 20px;
      font-family: Lato-Light;
      font-weight: unset;
    }
  }
  .button-div {
    margin-left: auto;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .button-scopri {
    padding-inline: 1.1rem;
    margin-top: 1rem;
    background-color: grey;
  }

  @media only screen and (min-width: 768px) {
    .button-scopri {
      position: fixed;
      bottom: 1rem;
      right: 295px;
    }
  }
`;

export const CookieBanner = () => {
  return (
    <CookieContainer>
      <CookieConsent
        buttonText="Accetta"
        declineButtonText="Rifiuta"
        enableDeclineButton
        expires={150}
        // debug={true}
        disableButtonStyles={true}
        ButtonComponent={Button}
        style={{
          background: primaryColor,
          display: "flex",
          flexDirection: "column",
        }}
        buttonWrapperClasses="button-div"
        contentStyle={{ flex: "" }}
        buttonStyle={{ margin: "0 15px 1rem 0", paddingInline: "2rem" }}
        declineButtonStyle={{
          margin: "0 15px 1rem 0",
          paddingInline: "2rem",
          backgroundColor: "grey",
        }}
      >
        <h2 style={{ color: secondaryColor }}>Informativa</h2>
        <p style={{ color: secondaryColor, textAlign: "justify" }}>
          Noi e terze parti selezionate utilizziamo cookie o tecnologie simili
          per finalita' tecniche e, con il tuo consenso, anche per "interazioni
          e funzionalita semplici", "miglioramento dell'esperienza",
          "misurazione" e "targeting e pubblicita" come specificato nella{" "}
          <a href="/cookie-policy" style={{ color: buttonHoverColor }}>
            cookie policy
          </a>
          . Il rifiuto del consenso puo rendere non disponibili le relative
          funzioni. Puoi liberamente prestare, rifiutare o revocare il tuo
          consenso, in qualsiasi momento. <br></br>
          In caso di vendita di informazioni personali, puoi fare opt-out
          utilizzando il link{" "}
          <a href="/terms-and-conditions" style={{ color: buttonHoverColor }}>
            "Non vendere le mie informazioni personali"
          </a>
          . <br></br>
          Per saperne di piu sulle categorie di informazioni personali raccolte
          e sulle finalita per le quali tali informazioni saranno utilizzate, si
          prega di fare riferimento alla nostra{" "}
          <a href="/privacy-policy" style={{ color: buttonHoverColor }}>
            privacy policy
          </a>
          . <br></br>
          Usa il pulsante "Accetta" per acconsetire all'utilizzo di tali
          tecnologie. Usa il pulsante "Rifiuta" per continuare senza accettare.
        </p>
        <div className="button-container">
          <a href="terms-and-conditions">
            <Button
              label="Scopri di piu e personalizza"
              className="button-scopri"
            />
          </a>
        </div>
      </CookieConsent>
    </CookieContainer>
  );
};
