import styled from "styled-components";
import { primaryColor } from "../../components/colors";

const MessageStyle = styled.section.attrs({ className: "message-component" })`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background: ${primaryColor};
  span {
    text-align: center;
    font-size: 2em;
  }
`;
export const MessageComponent = ({ message }: { message: string }) => {
  return (
    <MessageStyle>
      <span>{message}</span>
    </MessageStyle>
  );
};
