import { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "../../../components/Button";
import { useLocation } from "react-router-dom";

export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!!errorMessage) setErrorMessage(null);

    setIsLoading(true);

    const return_url = `${window.location.origin}${
      location.pathname.includes("test")
        ? "/event/63726d0f7894d342525ae861"
        : location.pathname
    }`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error?.message || null);
    } else {
      setErrorMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        label=""
        type="submit"
        id="submit"
        disabled={isLoading || !stripe || !elements}
        children={
          isLoading ? <div className="spinner" id="spinner"></div> : "Paga Ora"
        }
      />
      {errorMessage && <div id="payment-message">{errorMessage}</div>}
    </form>
  );
};
