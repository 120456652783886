import styled from "styled-components";
import { useEffect, useState } from "react";
import { EventProps, VenueProps } from "../../interfaces";
import { EVENT_ATMOSPHERE, venue_type_schema } from "../../data/Locale_Data";
import { buttonHoverColor } from "../../components/colors";
import { hasFreeTickets, isEventPast } from "../../utils";
import coverPH from "../../assets/images/HolderEvent.jpg";

const HeroStyle = styled.div.attrs({ className: "hero-dynamic" })`
  display: grid;
  grid-template-columns: 1fr;

  .hero-cover {
    height: 200px;
    img {
      object-fit: cover;
    }
  }

  .hero-description {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 1rem;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: contents;
      }
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .description {
      height: 98%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-family: Lato-Light;
        font-size: 13px;
        &.description-top {
          font-family: Lato-Regular;
          color: ${buttonHoverColor};
          font-weight: lighter;
        }
        &.description-bottom {
          font-family: Lato-Thin;
          color: grey;
        }
      }
      h2 {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .starting-price {
      font-size: 15px;
      font-weight: bold;
      margin-left: auto;
      min-width: 75px;
    }

    .dollar-price {
      font-family: Lato-Thin;
      color: grey;
      margin-top: auto;
      margin-left: auto;
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 768px) {
    .hero-cover {
      height: 500px;
    }
    .hero-description {
      padding-inline: 3rem;
      gap: 2rem;

      .logo {
        img {
          width: 140px;
          height: 140px;
        }
      }

      .description {
        h3 {
          font-family: Lato-Light;
          font-size: 24px;
          &.description-atmosphere {
            font-family: Lato-Regular;
            color: ${buttonHoverColor};
            font-weight: lighter;
          }
          &.description-venue-name {
            font-family: Lato-Thin;
            color: grey;
          }
        }
        h2 {
          font-size: 30px;
          font-weight: bold;
        }
      }

      .starting-price {
        font-size: 30px;
        font-weight: bold;
        margin-left: auto;
      }
      .dollar-price {
        font-size: 24px;
      }
    }
  }
`;

export const Hero = ({
  venue,
  event,
}: {
  venue: VenueProps;
  event?: EventProps;
}) => {
  const [cover, setCover] = useState<string>("");
  const { name, type, parsed_name, country, city, address, price_tag } = venue;
  const [coverHeight, setCoverHeight] = useState<number>(
    window.innerWidth > 1000 ? 500 : window.innerWidth / 2
  );

  let startingPrice = "";
  if (!!event) {
    if (
      event.ticket_types?.length &&
      event.ticket_types?.some((t) => (t?.available || 0) > 0)
    ) {
      const tickets = event.ticket_types.filter((t) => !t.physical);
      if (hasFreeTickets(tickets)) startingPrice = "Free entry";
      else if (tickets.length > 1) {
        const cheapest = tickets.reduce(
          (acc, { price }) => (acc < price ? acc : price),
          tickets[0].price
        );

        const isCheapestRounded = cheapest.toString().endsWith("00");

        const rounder = isCheapestRounded ? 0 : 2;

        startingPrice = `Da ${(cheapest / 100).toFixed(rounder)}€`;
      } else if (tickets.length === 1) {
        const isCheapestRounded = tickets[0].price.toString().endsWith("00");

        const rounder = isCheapestRounded ? 0 : 2;
        startingPrice = `Da ${(tickets[0].price / 100).toFixed(rounder)}€`;
      }
    }
  }

  useEffect(() => {
    const handleResize = (e: any) => {
      setCoverHeight(
        e.target.innerWidth > 1000 ? 500 : e.target.innerWidth / 2
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCover(!!event ? event.cover_location : venue.cover_location);
  }, [event, venue.cover_location]);

  return (
    <HeroStyle>
      <div className="hero-cover" style={{ height: coverHeight }}>
        <img
          src={cover || coverPH}
          alt={parsed_name}
          width="100%"
          height="100%"
          className="cover"
        />
      </div>
      <div className="hero-description">
        <div className="logo">
          <a
            href={
              "/" +
              [
                country.toLowerCase(),
                city.toLowerCase(),
                type.toLowerCase(),
                parsed_name,
              ].join("/")
            }
          >
            <img src={venue.logo_location} alt={parsed_name} />
          </a>
        </div>
        <div className="description">
          <h3 className="description-top">
            {!!event
              ? EVENT_ATMOSPHERE?.find((atm) => atm.value === event.atmosphere)
                  ?.label || event.atmosphere
              : venue_type_schema?.find((vt) => vt.value === type)?.label ||
                type}
          </h3>
          <h2 className="description-middle">{!!event ? event.name : name}</h2>
          <h3 className="description-bottom">
            {!!event ? name : `${address}, ${city}, ${country}`}
          </h3>
        </div>
        {!!event && !isEventPast(event) ? (
          <h2 className="starting-price">{startingPrice}</h2>
        ) : (
          !event && <RenderPriceTag priceTag={price_tag} />
        )}
      </div>
    </HeroStyle>
  );
};

const RenderPriceTag = ({
  priceTag,
}: {
  priceTag: "FIVE" | "FIVE_TO_FIFTEEN" | "FIFTEEN";
}) => {
  switch (priceTag) {
    case "FIVE":
      return <h3 className="dollar-price">$</h3>;
    case "FIVE_TO_FIFTEEN":
      return <h3 className="dollar-price">$$</h3>;
    case "FIFTEEN":
      return <h3 className="dollar-price">$$$</h3>;
    default:
      return <></>;
  }
};
