import styled from "styled-components";
import { EventProps, VenueProps } from "../../interfaces";
import { Info } from "./components/Info";
import { Times } from "./components/Times";
import { Features } from "./components/Features";
import { DressCode } from "./components/DressCode";
import { Events } from "./components/Events";
import { Map } from "./components/Map";
import { BuyTickets } from "./components/buy-tickets/BuyTickets";
import { buttonHoverColor, primaryColor } from "../../components/colors";
import { displayBuyTicketButton } from "../../utils";

const ContentStyle = styled.div.attrs({ className: "dynamic-content" })`
  background-color: grey;
  padding: 2rem 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  position: relative;

  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  .section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    .header {
      h2 {
        font-size: 16px;
      }
    }

    &.info {
      p {
        text-align: justify;
      }
    }

    &.times {
      .body {
        display: flex;
        flex-direction: column;
        gap: 0.15rem;
        p {
          font-family: Lato-Regular;
          span {
            font-family: Lato-Thin;
            font-weight: bold;
          }
        }
        .opening-line {
          display: flex;
        }
      }
    }

    &.features,
    &.dress_code {
      .body {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        img {
          width: 140px;
          height: 35px;
          border: 1px solid white;
          border-radius: 8px;
        }
      }
    }

    .body {
      p {
        margin: 0;
      }
    }

    &.events {
      .body {
        cursor: grab;
        overflow: hidden;
        position: relative;

        svg {
          display: none;
        }

        .loading-events {
          margin-top: 2rem;
          text-align: center;
          font-size: 1.1em;
        }
        .events-badges-wrapper {
          display: inline-flex;
          display: -webkit-box;
          transition: all 0.2s linear 0s;

          .event-href {
            display: contents;
          }
        }
      }
    }
  }

  .map {
    position: relative;
    margin-inline: -1.5rem;
    margin-top: 2rem;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    a {
      display: contents;
    }

    .image-placeholder {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .overlay {
        background: black;
        opacity: 0.7;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        z-index: 2;
      }

      img {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        object-fit: cover;
        z-index: 1;
      }

      img,
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .header {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 5;

      .image img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      h2,
      h3 {
        color: white;
      }

      h3 {
        font-family: Lato-Light;
      }
    }
  }

  .placeholder-ticket {
    position: absolute;
    bottom: 0;
    height: 150px;
    background-color: ${primaryColor};
    width: 100%;
  }

  .buy-tickets {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: ${primaryColor};

    &.is-pinned {
      background-color: transparent;
    }
    margin-top: -2rem;
    padding-top: 3rem;
    margin-inline: -1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
  }

  @media only screen and (min-width: 768px) {
    padding-inline: 3rem;
    gap: 3rem;

    .section {
      .header {
        h2 {
          font-size: 21px;
        }
      }
      p {
        font-size: 21px;
      }

      &.features,
      &.dress_code {
        .body {
          img {
            width: 180px;
            height: 45px;
          }
        }
      }

      &.events .body {
        margin-inline: -3rem;
        svg {
          display: block;
          position: absolute;
          top: 40%;
          left: 10px;
          z-index: 5;
          transition: all linear 0.2s;
          &:nth-child(2) {
            right: 10px;
            left: unset;
          }
          &:hover {
            fill: ${buttonHoverColor};
            transform: scale(1.2);
          }
        }
        .loading-events {
          margin-inline: 3rem;
          font-size: 1.5em;
        }
        .events-badges-wrapper {
          transform: translateX(3rem);
        }
      }
    }
    .map {
      margin-inline: -3rem;
      height: 350px;

      .header {
        .image img {
          width: 80px;
          height: 80px;
        }
      }
    }

    .buy-tickets {
      margin-top: -3rem;
      padding-top: 3rem;
      margin-inline: -3rem;
      button {
        padding: 0.5rem 4rem;
        span {
          font-size: 28px;
        }
      }
    }
  }
`;

export const Content = ({
  venue,
  pageType,
  event,
}: {
  venue: VenueProps;
  pageType: "Event" | "Venue";
  event?: EventProps;
}) => {
  const { features, dress_code, name, address, city, country, cover_location } =
    venue;

  const description =
    (!!event ? event : venue)?.descriptions?.find(
      ({ locale }) => locale === "it"
    )?.value || "";

  return (
    <ContentStyle>
      <Info description={description} />
      <Times pageType={pageType} content={!!event ? event : venue} />
      <Features features={features} />
      <DressCode dressCode={dress_code} />
      <Events
        pageType={pageType}
        venue={venue}
        {...(pageType === "Event" && !!event && { event })}
      />
      <Map name={name} address={address} city={city} country={country} />
      {!!event && displayBuyTicketButton(pageType, event) && (
        <>
          <div className="placeholder-ticket"></div>
          <BuyTickets
            event={event}
            venueAddress={address}
            venueName={name}
            venueCover={cover_location}
          />
        </>
      )}
    </ContentStyle>
  );
};
