import { Helmet } from "react-helmet";

const SEO_DATA = {
  generic: {
    title: "",
    description:
      "Night Citizen è la app per trovare cosa fare la sera! Scopri i migliori locali ed eventi, acquista biglietti in modo facile e veloce.",
  },
  home: {
    title: "HomePage",
    description:
      "Night Citizen è la app per trovare cosa fare la sera! Scopri i migliori locali ed eventi, acquista biglietti in modo facile e veloce.",
  },
  app: {
    title: "App",
    description:
      "Stanco di cercare cosa fare la sera, visitare migliaia di pagine web per trovare eventi? Night Citizen è la app per te! Semplicemente, scarica l'app per avere accesso ai migliori locali ed eventi ed ottenere esclusivi sconti.",
  },
  locali: {
    title: "Locali",
    description:
      "Vuoi ottenere le migliori performance dal tuo locale? Vuoi trovare nuovi clienti? Vuoi targhettizare il tuo pubblico? Iscriviti a 'Night Citizen', promuovi i tuoi eventi e dà un boost al tuo locale!",
  },
  stampa: {
    title: "Stampa",
    description:
      "Dà uno sguardo a cosa dicono alcuni dei più importanti giornali e riviste sul nostro lavoro.",
  },
  chisiamo: {
    title: "Chi Siamo",
    description:
      " Incontra la nostra squadra! Dà uno sguardo al nostro dinamico gruppo e conosci i ragazzi che stanno realizzando la prima vera app della vita notturna! Per noi, divertirsi, è qualcosa di molto serio…",
  },
};

export const SeoWrapper = ({
  page,
}: {
  page: "home" | "app" | "locali" | "stampa" | "chisiamo" | "generic";
}) => {
  return (
    <Helmet>
      <meta name="description" content={SEO_DATA[page]?.description} />
      <meta
        name="keywords"
        content="Night; Citizen; App; Locali; Eventi; Biglietti; Nightlife;"
      />
      <title>
        {SEO_DATA[page]?.title
          ? SEO_DATA[page].title + " - Night Citizen App"
          : "Night Citizen App"}
      </title>
    </Helmet>
  );
};
