import styled from "styled-components";
import { Socials } from "../Socials";
import logo from "../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import { NavList } from "./NavList";
import { MobileNavbar } from "./MobileNavbar";
import { useState } from "react";
import burger from "../../assets/images/socials/burger.png";

const NavContainer = styled.header.attrs({ className: "navbar-container" })`
  display: grid;
  grid-template-columns: 6fr 1fr;
  padding: 1rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem 3rem;
  }
`;
const LogoContainer = styled.div.attrs({
  className: "navbar-logo-container",
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: inherit;

  a {
    display: contents;
  }
  img {
    width: 90%;
    max-width: 400px;
    max-height: 90%;
  }
  @media only screen and (max-width: 767px) {
    img {
      max-width: 350px;
      width: 80%;
    }
  }
`;

const NavigationContainer = styled.div.attrs({
  className: "navbar-navigation-container",
})`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    ul {
      margin-top: 1.5rem;
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  @media only screen and (min-width: 1440px) {
    a {
      font-size: 22px;
    }
    img {
      width: 32px;
      height: 32px;
    }
  }
`;

const MobileMenuIcon = styled.div.attrs({ className: "mobile-menu-icon" })`
  background: url(${burger}) no-repeat center / contain;
  width: 35px;
  height: 100%;
  margin: auto;
  margin-top: 5px;
  transition: 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05) all;
  opacity: 1;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`;

export const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const handleClickMenu = () => setIsMobileMenuOpen((prev: boolean) => !prev);

  return (
    <NavContainer>
      <LogoContainer>
        <Link to="/">
          <img src={logo} alt="Logo Night Master" />
        </Link>
      </LogoContainer>
      <NavigationContainer>
        <Socials />
        <NavList />
      </NavigationContainer>
      <MobileNavbar
        isMenuOpen={isMobileMenuOpen}
        setIsMenuOpen={setIsMobileMenuOpen}
      />
      <MobileMenuIcon
        onClick={handleClickMenu}
        style={{
          opacity: !isMobileMenuOpen ? "1" : "0",
          transform: !isMobileMenuOpen ? "scale(1)" : "scale(0.5)",
        }}
      />
    </NavContainer>
  );
};
