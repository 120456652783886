import styled from "styled-components";
import { primaryColor, secondaryColor } from "../../components/colors";

const SectionBlockImagesStyle = styled.div`
  display: flex;
  padding: 2rem 1rem;
  background: ${(props) => props.datatype};
  h2 {
    color: ${(props) =>
      props.datatype === primaryColor ? secondaryColor : primaryColor};
  }
  .inner-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .images-block {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    flex-wrap: wrap;
    .image {
      width: 100px;
      height: 100px;
    }
  }

  .giornali-header {
    text-align: end;
  }

  @media only screen and (min-width: 768px) {
    padding: 3rem;

    .images-block {
      .image {
        width: 200px;
        height: 200px;
      }
    }
  }
`;

export interface BlockImagesProps {
  label: string;
  images: { key: string; image: string }[];
  color: string;
  type: string;
}

export const SectionBlockImages = ({
  label,
  images,
  color,
  type,
}: BlockImagesProps) => {
  return (
    <SectionBlockImagesStyle
      className={[type, "images-block"].join("-")}
      datatype={color}
    >
      <div className="inner-block">
        <div className={[type, "header"].join("-") + " header-block"}>
          <h2>{label}</h2>
        </div>
        <div className={[type, "images"].join("-") + " images-block"}>
          {images.map(({ key, image }) => (
            <div
              key={key}
              className={[key, "image"].join("-") + " image"}
              style={{
                background: `url(${image}) no-repeat center center / contain`,
              }}
            ></div>
          ))}
        </div>
      </div>
    </SectionBlockImagesStyle>
  );
};
