import { ReactNode } from "react";
import styled from "styled-components";
import { primaryColor } from "../../../components/colors";

const AssistenzaPageStyle = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  background-color: ${primaryColor};
`;
export const AssistenzaPageContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) => {
  return (
    <AssistenzaPageStyle {...{ className }}>{children}</AssistenzaPageStyle>
  );
};
