import axios from "axios";

const BASE_URL =
  window.location.hostname === "localhost"
    ? "https://api.nightcitizen-test.com/"
    : "https://api.nightcitizen.com/";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosStripe = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
  },
  withCredentials: false,
});
