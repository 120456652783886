import styled from "styled-components";
import { BlockProps } from "../data";
import { GuideBlock } from "./GuideBlock";

const BodyStyle = styled.div`
  display: flex;

  .inner-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media only screen and (min-width: 768px) {
    .inner-block {
      gap: 3rem;
    }
  }
`;

export const GuidaPageBody = ({ content }: { content: BlockProps[] }) => {
  return (
    <BodyStyle>
      <div className="inner-block">
        {content.map((c) => (
          <GuideBlock {...c} />
        ))}
      </div>
    </BodyStyle>
  );
};
