import { dress_code_schema } from "../../../data/Locale_Data";

export const DressCode = ({ dressCode }: { dressCode: string }) => {
  return (
    <div className="dress_code section">
      <div className="header">
        <h2>STILE</h2>
      </div>
      <div className="body">
        {dress_code_schema.map(({ value, img, key }) =>
          value === dressCode ? <img src={img} alt={value} key={key} /> : null
        )}
      </div>
    </div>
  );
};
