import styled from "styled-components";
import { primaryColor } from "../../../components/colors";
import { BlockProps } from "../data";

const BlockStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  .header {
    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
    }

    h2 {
      font-weight: 500;
    }
  }
  p:not(.p-bold) {
    font-family: Lato-Light;
  }

  .p-bold:not(.p-list) {
    line-height: 1.7rem;
  }

  .no-margin {
    margin-top: -0.5rem;
  }

  .no-b-margin {
    margin-bottom: 0;
  }

  .no-t-margin {
    margin-top: 0;
  }

  .bold-no-margin {
    margin-top: 0;
    font-weight: bold;
  }

  .start {
    margin-top: 2rem;
  }
  .end {
    margin-bottom: 2rem;
  }
  h2,
  p {
    color: ${primaryColor};
  }
  &.revert {
    img {
      filter: invert(100%);
      width: 45px;
      height: 45px;
    }
  }

  @media only screen and (min-width: 768px) {
    .header {
      img {
        width: 40px;
        height: 40px;
      }
    }
    &.revert {
      img {
        width: 50px;
        height: 50px;
      }
    }
    p {
      font-size: 21px;
    }
  }
`;

const parseBody = (body: string, className: string) => {
  let parsedBody: any = body;
  if (parsedBody.includes("Night Citizen Manager")) {
    const bodyArray = parsedBody.split("Night Citizen Manager");
    parsedBody = (
      <>
        {bodyArray[0]}{" "}
        <b style={{ fontFamily: "Lato-Regular" }}>Night Citizen Manager</b>{" "}
        {bodyArray[1]}
      </>
    );
  } else if (parsedBody.includes("Night Citizen App")) {
    const bodyArray = parsedBody.split("Night Citizen App");
    const bodyArrayFinal = bodyArray[1].split("Area Manager");
    parsedBody = (
      <>
        {bodyArray[0]}{" "}
        <b style={{ fontFamily: "Lato-Regular" }}>Night Citizen App</b>{" "}
        {bodyArrayFinal[0]}{" "}
        <b style={{ fontFamily: "Lato-Regular" }}>"Area Manager"</b>{" "}
        {bodyArrayFinal[1]}
      </>
    );
  } else if (parsedBody.includes("completamente Gratuita!")) {
    const bodyArray = parsedBody.split("completamente Gratuita!");
    parsedBody = (
      <>
        {bodyArray[0]}{" "}
        <b style={{ fontFamily: "Lato-Regular" }}>completamente Gratuita!</b>{" "}
      </>
    );
  } else if (className.includes("p-list")) {
    parsedBody = <>•&emsp; {parsedBody}</>;
  }

  return parsedBody;
};

export const GuideBlock = ({
  image,
  className,
  reverse,
  title,
  content,
}: BlockProps) => {
  return (
    <BlockStyle className={className}>
      <div
        className="header"
        {...(reverse && { style: { flexDirection: "row-reverse" } })}
      >
        <img src={image} alt={title} />
        <h2>{title}</h2>
      </div>
      <div className="content">
        {content.map(({ key, className, body }) => (
          <p key={key} className={className}>
            {parseBody(body, className)}

            {/* {className.includes("p-list") ? <>•&emsp; {body}</> : body} */}
          </p>
        ))}
      </div>
    </BlockStyle>
  );
};
