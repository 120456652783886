import { Dispatch, SetStateAction } from "react";
import { Attendee } from "../../interfaces";
import { Tos } from "../../pages/DynamicContents/components/buy-tickets/Stage3";
import { buttonHoverColor } from "../colors";

export const CustomCheckbox = ({
  inputState,
  setInputState,
  id,
}: {
  inputState: Tos;
  setInputState: Dispatch<
    SetStateAction<{
      attendees: Attendee[];
      tos: Tos;
    }>
  >;
  id: string;
}) => {
  return (
    <>
      <div className="checkbox">
        <div className="check-top">
          <input
            id={id}
            type="checkbox"
            className={
              inputState.touched && inputState.error
                ? "input-error css-checkbox"
                : "css-checkbox"
            }
            onChange={(e: any) =>
              setInputState((prev) => ({
                ...prev,
                tos: { touched: true, error: !e.target.checked },
              }))
            }
          />

          <label
            onClick={() =>
              setInputState((prev) => ({
                ...prev,
                tos: { ...prev.tos, touched: true },
              }))
            }
            htmlFor={id}
          ></label>
          <span className="terms">
            Accetta{" "}
            <a target="_blank" rel="noreferrer" href="/privacy-policy">
              Termini
            </a>{" "}
            e
            <a target="_blank" rel="noreferrer" href="/privacy-policy">
              Condizioni di Acquisto
            </a>
          </span>
        </div>
        {inputState.touched && inputState.error && (
          <span style={{ color: buttonHoverColor }} className="error">
            Prego accetta i termini
          </span>
        )}
      </div>
    </>
  );
};
