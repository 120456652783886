import styled from "styled-components";
import {
  buttonHoverColor,
  primaryColor,
  secondaryColor,
} from "../../components/colors";
import { EVENT_ATMOSPHERE } from "../../data/Locale_Data";
import { EventProps, VenueProps } from "../../interfaces";
import { hasFreeTickets, parseTime } from "../../utils";
import coverPH from "../../assets/images/HolderEvent.jpg";

enum Months {
  "GEN",
  "FEB",
  "MAR",
  "APR",
  "MAG",
  "GIU",
  "LUG",
  "AGO",
  "SET",
  "OTT",
  "NOV",
  "DIC",
}

const EventBadgeStyle = styled.div.attrs({
  className: "event-badge-container",
})`
  border-radius: 10px;
  width: 345px;
  height: 182px;
  display: grid;
  transition: 0.2s ease;
  margin-right: 4rem;
  &:hover {
    transform: scale(0.95);
  }

  .event-top {
    position: relative;
    background: red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 118px;

    .event-date {
      position: absolute;
      top: 0;
      right: 1rem;
      background: linear-gradient(
        319deg,
        rgb(188 175 175) 0%,
        rgb(64 64 64) 81%
      );
      padding: 0.25rem;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      width: 35px;
      p {
        font-weight: bold;
        color: white;
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .event-venue-logo {
    background: ${primaryColor};
    width: 70px;
    height: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${secondaryColor};
    position: absolute;
    bottom: -40px;
    left: 16px;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
  .event-bottom {
    background: ${secondaryColor};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0rem 0.25rem 0rem 95px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 64px;

    h3 {
      font-size: 12px;
      margin: 0;
    }

    .event-badge-data-top {
      font-family: Lato-Regular;
      color: ${buttonHoverColor};
      align-self: center;
      &.right {
        text-align: end;
        font-size: 10px;
      }
    }

    .event-badge-data-bottom {
      font-family: Lato-Thin;
      color: #353535;
      align-self: center;
    }

    .event-badge-data-middle {
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      align-self: center;
      color: black;
      &.right {
        text-align: end;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    width: 445px;
    height: 235px;

    .event-top {
      height: 153px;
      .event-date {
        width: 40px;
        p {
          font-size: 16px;
        }
      }
    }

    .event-bottom {
      height: 82px;
      grid-template-columns: 5fr 2fr;
      padding-right: 0.5rem;

      h3 {
        font-size: 16px;
      }

      .event-badge-data-top {
        &.right {
          font-size: 14px;
        }
      }

      .event-badge-data-middle {
        font-size: 17px;
      }
    }

    .event-venue-logo {
      width: 75px;
      height: 75px;
      bottom: -45px;
      left: 12px;
    }
  }
`;

export const EventBadge = ({
  event,
  venue,
}: {
  event: EventProps;
  venue: VenueProps;
}) => {
  const { date, name: eventName, start_time, end_time, atmosphere } = event;
  const [, month, day] = date.split("-");
  const { name: venueName } = venue;

  let startingPrice = "N/A";
  if (
    event.ticket_types?.length &&
    event.ticket_types?.some((t) => (t?.available || 0) > 0)
  ) {
    const tickets = event.ticket_types?.filter((t) => !t.physical);
    if (hasFreeTickets(tickets)) startingPrice = "Free entry";
    else if (tickets.length > 1) {
      const cheapest = tickets.reduce(
        (acc, { price }) => (acc < price ? acc : price),
        tickets[0].price
      );
      const isCheapestRounded = cheapest.toString().endsWith("00");

      const rounder = isCheapestRounded ? 0 : 2;

      startingPrice = `da ${(cheapest / 100).toFixed(rounder)} €`;
    } else if (tickets.length === 1) {
      const isCheapestRounded = tickets[0].price.toString().endsWith("00");

      const rounder = isCheapestRounded ? 0 : 2;

      startingPrice = `da ${(tickets[0].price / 100).toFixed(rounder)} €`;
    }
  }
  return (
    <EventBadgeStyle>
      <div
        className="event-top"
        style={{
          background: `url(${
            event.cover_location || coverPH
          }) center center / cover no-repeat`,
        }}
      >
        <div
          className="event-venue-logo"
          style={{
            background: `url(${venue.logo_location}) center center / cover no-repeat`,
            color: "red",
          }}
        ></div>
        <div className="event-date">
          <p>{Months[parseInt(month) - 1]}</p>
          <p>{day}</p>
        </div>
      </div>
      <div className="event-bottom">
        <h3 className="event-badge-data-top">
          {EVENT_ATMOSPHERE?.find((atm) => atm.value === atmosphere)?.label ||
            atmosphere}
        </h3>
        <h3 className="event-badge-data-top right">
          {parseTime(start_time)}
          {" - "}
          {parseTime(end_time)}
        </h3>
        <h2 className="event-badge-data-middle">{eventName}</h2>
        <h2 className="event-badge-data-middle right">{startingPrice}</h2>
        <h3 className="event-badge-data-bottom">{venueName}</h3>
      </div>
    </EventBadgeStyle>
  );
};
