import { AssistenzaBody } from "./components/AssistenzaBody";
import { AssistenzaHero } from "./components/AssistenzaHero";
import { AssistenzaPageContainer } from "./components/AssistenzaPageContainer";
import { cookie } from "./data/cookieData";

export const CookiePolicyPage = () => {
  return (
    <AssistenzaPageContainer className="cookie-section">
      <AssistenzaHero title="COOKIE POLICY" />
      <AssistenzaBody data={cookie} />
    </AssistenzaPageContainer>
  );
};
